import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {ExportToCsv} from "export-to-csv";
import {DateRangePicker} from "rsuite";
import * as dateFns from "date-fns";
import axios from "axios";
import {useDispatch} from "react-redux";
import {PropagateLoader} from "react-spinners";
import {css} from "@emotion/core";
import {useTranslation} from "react-i18next";
import {dateFormat} from "../../../shared/utils/utils";

function PlotDeviceView(props) {
  const [dateRange, setDataRange] = useState(null);
  const [sensorlist, setSensorlist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sensorCodes, setSensorCodes] = useState([]);
  const [sensorIndex, setSensorIndex] = useState(0);
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    width: fit-content;
  `;

  const style1 = {borderBottom: "solid 2.5px #2877B4"};


  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }

  useEffect(() => {
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());

    setIsLoading(true);
    // axios.get(process.env.REACT_APP_HOST + `/core/device/` +865067027394371 + '/sensor/' + sensorIndex + '?from=' + fromDate + '&to=' + toDate+`&order=DESC`)
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/core/device/` +
        props.device +
        "/sensor/" +
        sensorIndex +
        "?from=" +
        fromDate +
        "&to=" +
        toDate +
        `&order=DESC`
      )
      .then((res) => {
        setSensorlist(res.data.content);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dateRange, props, sensorIndex]);

  function exportData() {
    const data = [];
    sensorlist.forEach((device) => {
      data.push({
        "Time ": device.time,
        "Value ": device.value,
      });
    });
    const opt = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      title: "Sensor",
      useBom: true,
      noDownload: false,
      headers: ["Time ", "Value "],
      filename: "DeviceDetailsReport",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_HOST + `/core/device/` + props.device)
      .then((res) => {
        setSensorCodes(res.data.content);
      });
  }, [props]);

  return (
    <div>
      <div className={"sa-popup-bg"}>
        <div className="sa-popup">
          <div className="sa-modal-box-style">
            <div className="sa-popup-header">
              <span className={"sa-model-heading"}>{t("title.DEVICE_DETAILS")}</span>
              <div
                className="sa-popup-close-icon"
                onClick={() => props.onClose()}
              >
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>

            <div className={""}>
              {sensorCodes &&
                sensorCodes.sensorCodes &&
                sensorCodes.sensorCodes.map((property, index) => (
                  <div
                    style={(sensorIndex === index && style1) || null}
                    key={index}
                    className={"r-w-50 plot-report-tabs"}
                    onClick={() => setSensorIndex(index)}
                  >
                    <div className="plot-report-tabs-title limit-plot-report-N plot-title-size">
                      {property}
                    </div>
                    {/*<div className="plot-report-tabs-value limit-plot-report-V">{property.value}</div>*/}
                  </div>
                ))}
            </div>
            <div style={{margin: "8px"}}>
              <div className={"plot-report-filter m-b-8 m-t-8"}>
                <DateRangePicker
                  disabledDate={(date) => date > new Date()}
                  disabled={false}
                  onChange={(e) => onCheck(e)}
                  oneTap={false}
                  onOk={(e) => onCheck(e)}
                  showWeekNumbers={true}
                  appearance={"default"}
                  placeholder={"Today"}
                  ranges={[
                    {
                      label: "Today",
                      value: [new Date(), new Date()],
                    },
                    {
                      label: "Yesterday",
                      value: [
                        dateFns.addDays(new Date(), -1),
                        dateFns.addDays(new Date(), -1),
                      ],
                    },
                    {
                      label: "Last 7 days",
                      value: [dateFns.subDays(new Date(), 6), new Date()],
                    },
                    {
                      label: "Last 30 days",
                      value: [dateFns.subDays(new Date(), 30), new Date()],
                    },
                  ]}
                />
              </div>
              <button
                onClick={() => exportData()}
                className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right m-b-8 m-t-8"
              >
                <FeatherIcon
                  icon={"upload"}
                  className={"sa-table-icon-size"}
                />{" "}
                <span className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
              </button>


              <div className="sa-table-container device-detail-popup-calc-height">
                <table
                  className="table table-borderless sa-table-width"
                  hidden={isLoading}
                >
                  <thead>
                  <tr>
                    <th className={"sa-table-head-sticky"}>
                      {t("title.TIME")}
                    </th>
                    <th className={"sa-table-head-sticky"}>
                      {t("title.VALUE")}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {sensorlist.map((property, index) => (
                    <tr key={index}>
                      <td className={'sa-table-data'}>{property.time || "NA"} </td>
                      <td className={'sa-table-data'}>{property.value || "NA"} </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                {isLoading &&
                  <div className="sweet-loading">
                    <PropagateLoader
                      css={override}
                      size={20}
                      color={"#11bc4d"}
                      loading={true}
                    />
                  </div>
                }
                {sensorlist.length === 0 && (
                  <div className={"empty-results"} hidden={isLoading}>
                    <FeatherIcon icon="info"/>
                    <div className={"empty-results-text"}>
                      {t("emptyMsg.THERE_ARE_NO_DEVICE_DATA")}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlotDeviceView;
