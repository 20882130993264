import React, {useEffect, useState} from "react";
import user from "../../../images/BasicDetails.svg";
import ToggleLayout from "../../toggle-layout";
import farmImg from '../../../images/farm.jpg';
import FeatherIcon from "feather-icons-react";
import FarmItemShort from "./farm-item-short";
import * as _ from "underscore";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {formatDisplayEnumValue, isKeells} from "../../../utils/Utils";
import FarmerLocation from "./farmer-location";
import axios from "axios";
import {getUserId, isSuper} from "../../../shared/utils/SharedAuthentication";
import {toggleLoader} from "../../../shared/actions/setting";
import {toast} from "react-toastify";
import FarmImages from "../../farm/farm-images";
import {isEmpty} from "../../../shared/utils/utils";

export default function FarmerView(props) {
  const {t} = useTranslation();

  const [visibleToggleIndex, setVisibleToggleIndex] = useState(1);
  const [farmerData, setFarmerData] = useState({});
  const [cropAgronomy, setCropAgronomy] = useState([]);


  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  function isKeelsFarmer() {
    return isKeells(selectedCorporate);
  }


  const dispatch = useDispatch();


  useEffect(() => {
    if (!props?.data?.id) {
      return
    }

    dispatch(toggleLoader(true))
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate/` + selectedCorporate + '/participant/' + props.data.id)
      .then((res) => {
        setFarmerData(res.data.content)
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        toast.error(error.response.data.message);
      }
    }).finally(
      () => {
        dispatch(toggleLoader(false))
      }
    )

  }, [props.data.id])

  useEffect(() => {
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/cropAgronomy`)
      .then((res) => {
        setCropAgronomy(res.data.content);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    if (isEmpty(farmerData) || !farmerData.cropsCultivated || cropAgronomy.length === 0) {
      return;
    }

    let crops = [];
    for (let i = 0; i < farmerData.cropsCultivated.length; i++) {
      let crop = _.find(cropAgronomy, function (num) {
        return num.id === farmerData.cropsCultivated[i];
      });
      if (crop) {
        crops.push(crop.cropName);
      }
    }

    farmerData.cropsCultivatedNames = crops;

    setFarmerData({...farmerData});

  }, [cropAgronomy]);

  return (

    <ToggleLayout image={user} title={t("title.BASIC_DETAILS")} dropDown={false} visibleToggleIndex={visibleToggleIndex}
                  toggleIndex={1}
                  print={props.print}
                  onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
      <div>

        <div className={""}>
          <div className={"gap-mb-24 row justify-content-between"}>
            <div className={"col-md-auto w-180"}>
              <div className={"view-label"}>
                {t("title.FARMER_NAME")}
              </div>
              <div className={"view-value"}>
                {props.data.firstName} {props.data.lastName ? props.data.lastName : ""}
              </div>
              <div hidden={!isKeelsFarmer()}>
                <div className={"view-label mt-md-5 mt-3"}>
                  {t("title.PREVIOUS_BUYER")}
                </div>
                <div className={"view-value"}>
                  {formatDisplayEnumValue(props.data.previousBuyer ? props.data.previousBuyer : "NA")}
                </div>
                <div className={"view-label mt-md-5 mt-3"}>
                  {t("title.WATER_SOURCE_TYPE")}
                </div>
                <div className={"view-value"}>
                  {formatDisplayEnumValue(props.data.waterSourceType ? props.data.waterSourceType : "NA")}
                </div>
              </div>
              <div className={"mt-md-5 mt-3"}>
                <div hidden={isKeelsFarmer()} className={"view-label"}>
                  {t("title.MAIL_ADDRESS")}
                </div>
                <div hidden={!isKeelsFarmer()} className={"view-label"}>
                  {t("title.EMAIL_ADDRESS")}
                </div>
                <div className={"view-value"}>
                  {props.data.mailAddress ? props.data.mailAddress : "NA"}
                </div>
              </div>
              <div hidden={isKeelsFarmer()}>
                <div className={"view-label mt-3 mt-md-5"}>
                  {t("title.GS_DIVISION")}
                </div>
                <div className={"view-value"}>
                  {props.data.gsDivision ? props.data.gsDivision : "NA"}
                </div>
              </div>
              <div className={"view-label mt-md-5 mt-3"}>
                {t("title.PROFILE_IMAGE")}
              </div>
              {props.data.profileImage &&
                <div className={''}>
                  <FarmImages imgs={props.data.profileImage ? [props.data.profileImage] : [farmImg]} width={"96px"}/>
                </div>
              }
              {!props.data.profileImage &&
                <div className={"view-value"}>
                  NA
                </div>
              }
              <div className={"view-label mt-md-5 mt-3"}>
                {t("title.FARMER_NIC_BACK")}
              </div>
              {props.data.nicBackImage && (
                <div className={''}>
                  <FarmImages imgs={[props.data.nicBackImage]} width={"96px"}/>
                </div>)}
              {!props.data.nicBackImage &&
                <div className={"view-value"}>
                  NA
                </div>
              }

            </div>
            <div className={"col-md-auto w-180"}>
              <div hidden={isKeelsFarmer()}>
                <div className={"view-label"}>
                  {t("title.CONTACT_NO")}
                </div>
                <div className={"view-value"}>
                  {props.data.phoneNumber ? props.data.phoneNumber : "NA"}
                </div>
              </div>
              <div hidden={!isKeelsFarmer()}>
                <div className={"view-label"}>
                  {t("title.PHONE_NUMBER_1")}
                </div>
                <div className={"view-value"}>
                  {props.data.phoneNumber ? props.data.phoneNumber : "NA"}
                </div>
              </div>
              <div hidden={!isKeelsFarmer()}>
                <div className={"view-label mt-3 mt-md-5"}>
                  {t("title.MODE_OF_DELIVERY")}
                </div>
                <div className={"view-value"}>
                  {formatDisplayEnumValue(props.data.modeOfDelivery ? props.data.modeOfDelivery : "NA")}
                </div>
                <div className={"view-label mt-3 mt-md-5"}>
                  {t("title.FARMER_CATEGORY")}
                </div>
                <div className={"view-value"}>
                  {props.data.farmerCategory ? formatDisplayEnumValue(props.data.farmerCategory) : "NA"}
                </div>
              </div>
              <div className={"view-label mt-3 mt-md-5"}>
                {t("title.CERTIFICATION")}
              </div>
              <div className={"view-value"}>
                {props.data.certifications ? _.pluck(props.data.certifications, 'name') : "NA"}
              </div>
              <div hidden={isKeelsFarmer()}>
                <div className={"view-label mt-3 mt-md-5"}>
                  {t("title.GN_DIVISION")}
                </div>
                <div className={"view-value"}>
                  {props.data.gnDivision ? props.data.gnDivision : "NA"}
                </div>
              </div>
              <div hidden={!"61fa24702d3b876d90339d28" || !"61f9f3ef2d3b876d90337661"}>
                <div className={"view-label mt-3 mt-md-5"}>
                  {t("title.SPECIAL_CATEGORY")}
                </div>
                <div className={"view-value word-break"}>
                  {formatDisplayEnumValue(props.data.specialCategory ? props.data.specialCategory + "" : "NA")}
                </div>
              </div>
              <div>
                <div className={"view-label mt-3 mt-md-5"}>
                  {t("title.FARMER_NIC_PDF")}
                </div>
                {props.data.nicPDF ? <div className={"view-value word-break"}>
                  <a href={props.data.nicPDF} target={"_blank"}>
                    <FeatherIcon className={"view-label"} icon={"file-text"} size={"60px"}/>
                  </a>
                </div> : <div className={"view-value"}>NA</div>}
              </div>
            </div>

            <div className={"col-md-auto w-180"}>
              <div hidden={!isKeelsFarmer()}>
                <div className={"view-label"}>
                  {t("title.PHONE_NUMBER_2")}
                </div>
                <div className={"view-value"}>
                  {props.data.phoneNumber2 ? props.data.phoneNumber2 : "NA"}
                </div>
              </div>
              <div className={!isKeelsFarmer() ? "view-label" : "view-label  mt-3 mt-md-5"}>
                {t("title.USAGE_OF_CRATES")}
              </div>
              <div className={"view-value"}>
                {props.data.usageOfCrates === false ? "NO" : props.data.usageOfCrates ? "YES" : "NA"}
              </div>
              <div hidden={!isKeelsFarmer()}>
                <div className={"view-label mt-3 mt-md-5"}>
                  {t("title.DATE_JOINED_WITH_CORPORATE")}
                </div>
                <div className={"view-value"}>
                  {props.data.dateJoinedWithCorporate ? props.data.dateJoinedWithCorporate : "NA"}
                </div>
              </div>
              <div className={"view-label mt-3 mt-md-5"}>
                {t("title.FARMER_ID")}
              </div>
              <div className={"view-value"}>
                {props.data.idNumber ? props.data.idNumber : "NA"}
              </div>
              <div hidden={isKeelsFarmer()} className={"mt-md-5 mt-3"}>
                <div className={"view-label"}>
                  {t("title.DOB")}
                </div>
                <div className={"view-value"}>
                  {props.data.birthDate ? props.data.birthDate : "NA"}
                </div>
              </div>
              <div className={"view-label mt-3 mt-md-5"}>
                {t("title.FARM_AREA")}
              </div>
              <div className={"view-value"}>
                {props.data.farmArea ? props.data.farmArea + " Acre" : "NA"}
              </div>
            </div>
            <div className={"col-md-auto w-180"}>
              <div hidden={!isKeelsFarmer()}>
                <div className={"view-label"}>
                  {t("title.PHONE_NUMBER_3")}
                </div>
                <div className={"view-value"}>
                  {props.data.phoneNumber3 ? props.data.phoneNumber3 : "NA"}
                </div>
                <div className={"view-label mt-3 mt-md-5"}>
                  {t("title.FARMER_TYPE")}
                </div>
                <div className={"view-value"}>
                  {formatDisplayEnumValue(props.data.farmerType ? props.data.farmerType : "NA")}
                </div>
              </div>
              <div className={!isKeelsFarmer() ? "view-label" : "view-label mt-3 mt-md-5"}>
                {t("title.COUNTRY")}
              </div>
              <div className={"view-value"}>
                {props.data.country ? props.data.country : "Sri Lanka"}
              </div>
              <div className={"view-label mt-3 mt-md-5"}>
                {t("title.HOME_ADDRESS")}
              </div>
              <div className={"view-value"}>
                {props.data.homeAddress ? props.data.homeAddress : "NA"}
              </div>

              <div className={"mt-md-5 mt-3"}>
                <div className={"view-label"}>
                  {t("title.GENDER")}
                </div>
                <div className={"view-value"}>
                  {props.data.gender ? props.data.gender : "NA"}
                </div>
              </div>
              <div className={"view-label mt-3 mt-md-5"}>
                {t("title.CROP_CULTIVATED")}
              </div>
              <div className={"view-value"}>
                {farmerData.cropsCultivatedNames ? farmerData.cropsCultivatedNames.join(', ') : "NA"}
              </div>
            </div>
            <div className={"col-md-auto w-180"}>
              <div className={"view-label"}>
                {t("title.NIC_NO")}
              </div>
              <div className={"view-value"}>
                {props.data.nicNumber ? props.data.nicNumber : "NA"}
              </div>
              <div hidden={!isKeelsFarmer()}>
                <div className={"view-label mt-3 mt-md-5"}>
                  {t("title.DISTANCE_TO_CC")}
                </div>
                <div className={"view-value"}>
                  {props.data.distanceToCC ? props.data.distanceToCC : "NA"}
                </div>
              </div>
              <div className={"mt-md-5 mt-3"}>
                <div className={"view-label"}>
                  {t("title.DISTRICT")}
                </div>
                <div className={"view-value"}>
                  {formatDisplayEnumValue(props.data.district ? props.data.district : "NA")}
                </div>
              </div>
              <div className={"view-label mt-md-5 mt-3"}>
                {t("title.FARMER_NIC_FRONT")}
              </div>
              {props.data.nicFrontImage && (
                <div className={''}>
                  <FarmImages imgs={[props.data.nicFrontImage]} width={"96px"}/>
                </div>)}

              {!props.data.nicFrontImage &&
                <div className={"view-value"}>
                  NA
                </div>
              }
              {isSuper() && 
              (<div className={"mt-md-5 mt-3"}>
                <div className={"view-label"}>
                  {t("title.CREATED_BY")}
                </div>
                <div className={"view-value"}>
                  {props.data.createdBy ? props.data.createdBy.lastName : "NA"}
                </div>
              </div>)
              }
            </div>
          </div>
        </div>
      </div>
      {farmerData?.locationInfo?.gpscoordinates && <div className={"mt-5"}>
        <div className={"farm-view-heading"}>
          {t("title.LOCATION_DETAILS")}
        </div>
        <div className={"mt-3"}>
          <FarmerLocation location={farmerData?.locationInfo?.gpscoordinates}/>
        </div>
      </div>}

      <div className={"mt-5"}>
        <div className={"farm-view-heading"}>
          {t("title.BANK_DETAILS")}
        </div>

        <div className={"mt-3 gap-mb-24"}>
          <div className={"row justify-content-between gap-mb-24"}>
            <div className={"col-md-auto w-180"}>
              <div className={"view-label"}>
                {t("title.BANK_NAME")}
              </div>
              <div className={"view-value"}>
                {props.data.bankName ? props.data.bankName : "NA"}
              </div>
            </div>
            <div className={"col-md-auto w-180"}>
              <div className={"view-label"}>
                {t("title.BANK_BRANCH_NAME")}
              </div>
              <div className={"view-value"}>
                {props.data.branchName ? props.data.branchName : "NA"}
              </div>
            </div>
            <div className={"col-md-auto w-180"}>
              <div className={"view-label"}>
                {t("title.BENEFICIARY_NAME_1")}
              </div>
              <div className={"view-value"}>
                {props.data.bankAccountName ? props.data.bankAccountName : "NA"}
              </div>
            </div>
            <div className={"col-md-auto w-180"}>
              <div className={"view-label"}>
                {t("title.BANK_ACCOUNT_NUMBER")}
              </div>
              <div className={"view-value"}>
                {props.data.bankAccountNo ? props.data.bankAccountNo : "NA"}
              </div>
            </div>

            <div className={"col-md-auto w-180"}>
              <div className={"view-label"}>
                {t("title.FARMER_PASSBOOK")}
              </div>
              {props.data.bankPassBookImage &&
                <div className={''}>
                  <FarmImages imgs={props.data.bankPassBookImage ? [props.data.bankPassBookImage] : [farmImg]}
                              width={"96px"}/>
                </div>
              }
              {!props.data.bankPassBookImage &&
                <div className={"view-value"}>
                  NA
                </div>
              }
            </div>
            <div className={"col-md-auto w-180"}>
              <div className={"view-label"}>
                {t("title.FARMER_PASSBOOK_PDF")}
              </div>
              {props.data.bankPassBookPDF ? <div className={"view-value word-break"}>
                <a href={props.data.bankPassBookPDF} target={"_blank"}>
                  <FeatherIcon icon={"file-text"} className={"view-label"} size={"60px"}/>
                </a>
              </div> : <div className={"view-value"}>NA</div>}
            </div>

          </div>

        </div>
      </div>

      <div hidden={isKeelsFarmer()} className={"mt-5"}>
        <div className={"farm-view-heading"}>
          {t("title.FARM_DETAILS")}
        </div>
        <div className={"mt-3 d-flex flex-wrap gap-20"}>
          {!props.data.farms && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_FARMS")}</div>
            </div>
          )}
          {props.data.farms && props.data.farms.map((item, index) => (
            <FarmItemShort key={item} id={item}/>
          ))}
        </div>

      </div>

    </ToggleLayout>
  )
}
