import React, {useEffect, useState} from "react";
import {hasPermission,} from "../../../utils/Authentication";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateKitForm} from "../../../utils/FormValidationRules";
import {toast} from "react-toastify";
import {clusterSensorsByDeviceId} from "../../../utils/Utils";
import FeatherIcon from "feather-icons-react";
import {changeToggle, toggleLoader} from "../../../shared/actions/setting";
import {TOGGLE} from "../../../utils/Enum";
import {getClusters, getFindLocation, getSelectedCoordinate, getSelectedSensor} from "../../../actions/sensor";
import * as _ from "underscore";
import Form from "react-bootstrap/Form";
import {getSelectedPlot} from "../../../actions/plot";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {getFarmList, getSelectedFarm} from "../../../actions/farm";

const DeviceSetting = (props) => {
  const {t, i18n} = useTranslation();
  const [isEditValve, setIsEditValve] = useState(false);
  const [isEditKit, setIsEditKit] = useState(null);
  const [error, setError] = useState(null);
  const [kit, setKit] = useState(null);
  const [editDevice, setEditDevice] = useState(false);
  const [kitMap, setKitMap] = useState([]);
  const dispatch = useDispatch();
  const [isTable, setTable] = useState(true);
  const [refresh, setRefresh] = useState(false)

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    initForm,
    handleOnBlur,
  } = FormHandler(submitKit, validateKitForm);


  let selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  let selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  const findDeviceLocation = useSelector(state => {
    return state.sensor.findLocation;
  });

  const selectCoordinates = useSelector(state => {
    return state.sensor.selectedCoordinate;
  });
  const clustersList = useSelector(state => {
    return state.sensor.clusters;
  });

  useEffect(() => {
    if (props.type === "FARM") {
      if (!props.farmId) {
        return;
      }
      setKit(null)
      setKitMap([])
      dispatch(toggleLoader(true));
      axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farmId)
        .then(function (response) {
          dispatch(getSelectedPlot([]))
          dispatch(getFarmList([]))
          dispatch(getSelectedFarm(response.data.content));
          setRefresh(!refresh)
        })
        .catch(function (error) {
          if (error.response && error.response.status === 422) {
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
        });

    } else {
      if (!props.plotId) {
        return;
      }

      setKit(null)
      setKitMap([])
      dispatch(toggleLoader(true));
      axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId)
        .then(function (response) {
          dispatch(getSelectedPlot(response.data.content));
          setRefresh(!refresh)
        })
        .catch(function (error) {
          if (error.response && error.response.status === 422) {
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
        });

    }
  }, [props.plotId, props.farmId])


  function submitKit() {
    // dispatch(toggleLoader(true));
    // if (isEditKit) {
    //   axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId, {
    //     kitId: values.kitId,
    //     name: selectedPlot.name,
    //     farmId: selectedPlot.farmId
    //   })
    //     .then(function (response) {
    //
    //       if (!selectedPlot.kitId) {
    //         toast.success("Kit Added Successfully")
    //       } else {
    //         toast.success("Kit Successfully Updated")
    //       }
    //       selectedPlot.kitId = response.data.content.kitId;
    //       setIsEditKit(false);
    //
    //       setError(null);
    //     })
    //     .catch(function (error) {
    //       if (error.response && error.response.status === 422) {
    //         setError(error.response.data.message);
    //       } else {
    //         toast.error(" Something went wrong");
    //       }
    //
    //     })
    //     .finally(() => {
    //       dispatch(toggleLoader(false));
    //     })
    // }
  }


  useEffect(() => {
    if (props.type === "FARM") {
      axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedFarm.kitId)
        .then(res => {
          setKit({...res.data.content});
          dispatch(toggleLoader(false))
        }).catch(error => {
          // if (error.response && error.response.status === 422) {
          //   toast.error(error.response.data.message);
          // }
          dispatch(toggleLoader(false))
        }
      ).finally(
      );
    } else {
      axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedPlot.kitId)
        .then(res => {
          setKit({...res.data.content});
          dispatch(toggleLoader(false))
        }).catch(error => {
          // if (error.response && error.response.status === 422) {
          //   toast.error(error.response.data.message);
          // }
          dispatch(toggleLoader(false))
        }
      ).finally(
      );
    }
  }, [refresh]);

  function pickDeviceLocation(device) {
    dispatch(changeToggle(TOGGLE.MAP));
    dispatch(getFindLocation(true));
    errors.lat = ""
    errors.lng = ""

  }

  useEffect(() => {
    if (findDeviceLocation) {
      return
    }
    if (!findDeviceLocation && selectCoordinates.latLng) {
      dispatch(changeToggle(TOGGLE.LIST));
      values.lat = selectCoordinates.latLng.lat();
      values.lng = selectCoordinates.latLng.lng();
    }

  }, [findDeviceLocation]);

  useEffect(() => {
    if (props.type === "FARM") {
      {
        if (!kit || !kit.devices || !selectedFarm.propertyDetails) {
          return
        }
        dispatch(getClusters(clusterSensorsByDeviceId(kit, selectedFarm)));

        let deviceMappingList = [];
        if (selectedFarm.propertyDetails[0] && selectedFarm.propertyDetails[0].cluster) {
          kit.devices.forEach(device => {
            const devices = _.findWhere(selectedFarm.propertyDetails, {cluster: device});
            const deviceMapping = {
              deviceId: device,
              lat: devices ? (!devices.coordinate ? " " : devices.coordinate.lat) : "",
              lng: devices ? (!devices.coordinate ? " " : devices.coordinate.lng) : "",
              name: devices ? (devices.clusterName ? devices.clusterName : "") : ""
            };
            deviceMappingList.push(deviceMapping);
          });
          setKitMap([...deviceMappingList]);
        } else if (clustersList.length > 0) {
          kit.devices.forEach((device, index) => {
            let location = clustersList[index][0];
            const deviceMapping = {
              deviceId: device,
              lat: location.coordinate ? location.coordinate.lat : "",
              lng: location.coordinate ? location.coordinate.lng : "",
              name: values ? (values.name ? values.name : "") : ""
            };
            deviceMappingList.push(deviceMapping);
          });
          setKitMap([...deviceMappingList]);
        } else {
          kit.devices.forEach((device, index) => {
            const deviceMapping = {
              deviceId: device,
              lat: "",
              lng: "",
              name: ""
            };
            deviceMappingList.push(deviceMapping);
          });
          setKitMap([...deviceMappingList]);
        }
      }
    } else {
      if (!kit || !kit.devices || !selectedPlot.propertyDetails) {
        return
      }
      dispatch(getClusters(clusterSensorsByDeviceId(kit, selectedPlot)));

      // if (clustersList.length===0) {
      //   return
      // }
      let deviceMappingList = [];
      if (selectedPlot.propertyDetails[0] && selectedPlot.propertyDetails[0].cluster) {
        kit.devices.forEach(device => {
          const devices = _.findWhere(selectedPlot.propertyDetails, {cluster: device});
          const deviceMapping = {
            deviceId: device,
            lat: devices ? (!devices.coordinate ? " " : devices.coordinate.lat) : "",
            lng: devices ? (!devices.coordinate ? " " : devices.coordinate.lng) : "",
            name: devices ? (devices.clusterName ? devices.clusterName : "") : ""
          };
          deviceMappingList.push(deviceMapping);
        });
        setKitMap([...deviceMappingList]);
      } else if (clustersList.length > 0) {
        kit.devices.forEach((device, index) => {
          let location = clustersList[index][0];
          const deviceMapping = {
            deviceId: device,
            lat: location.coordinate ? location.coordinate.lat : "",
            lng: location.coordinate ? location.coordinate.lng : "",
            name: values ? (values.name ? values.name : "") : ""
          };
          deviceMappingList.push(deviceMapping);
        });
        setKitMap([...deviceMappingList]);
      } else {
        kit.devices.forEach((device, index) => {
          const deviceMapping = {
            deviceId: device,
            lat: "",
            lng: "",
            name: ""
          };
          deviceMappingList.push(deviceMapping);
        });
        setKitMap([...deviceMappingList]);
      }

    }
  }, [kit, clustersList.length]);


  function onClose() {
    dispatch(getFindLocation(false));
    dispatch(getSelectedCoordinate({}));
    dispatch(changeToggle(TOGGLE.LIST));
    setIsEditKit(null);
    initForm({});
    setEditDevice(false);
    dispatch(getSelectedSensor(-1));
  }

  function editDevices(device, index) {
    initForm({...device});
    setEditDevice(true);
    dispatch(getSelectedSensor(index));
  }

  function uploadDeviceCoordinatedChained() {
    if (props.type === "FARM") {
      if (!errors.lng && !errors.lat) {
        dispatch(toggleLoader(true));
        // if (values.name !== "") {
        axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/farm/' + props.farmId + '/cluster' + '/device',
          {
            name: values.name,
            deviceCoordinate: {
              [values.deviceId]: {
                'lat': parseFloat(values.lat),
                'lng': parseFloat(values.lng)
              }
            }
          }
        ).then((resp) => {
          let index = kitMap.findIndex(kit => kit.deviceId === values.deviceId);
          kitMap[index] = {
            deviceId: values.deviceId,
            lat: values.lat,
            lng: values.lng,
            name: values.name
          };
          setKitMap(kitMap);
          dispatch(getClusters(clusterSensorsByDeviceId(kit, selectedFarm)));
          toast.success(t("successMsg.DEVICE_COORDINATE_UPDATED_SUCCESSFULLY"));
          setEditDevice(false);
          dispatch(getSelectedSensor(-1));
        }).catch(error => {
          if (error.response && error.response.status === 422) {
            setError(error.response.data.message);
            setEditDevice(true);
            toast.error(error.response.data.message)
          }
        }).finally(() => {
          dispatch(getFindLocation(false));
          dispatch(getSelectedCoordinate({}));
          dispatch(toggleLoader(false));
        });
      }
    } else {
      if (!errors.lng && !errors.lat) {
        dispatch(toggleLoader(true));
        // if (values.name !== "") {
        axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/plot/' + props.plotId + '/cluster' + '/device',
          {
            name: values.name,
            deviceCoordinate: {
              [values.deviceId]: {
                'lat': parseFloat(values.lat),
                'lng': parseFloat(values.lng)
              }
            }
          }
        ).then((resp) => {
          dispatch(getSelectedPlot(resp.data.content));
          let index = kitMap.findIndex(kit => kit.deviceId === values.deviceId);
          kitMap[index] = {
            deviceId: values.deviceId,
            lat: values.lat,
            lng: values.lng,
            name: values.name
          };
          setKitMap(kitMap);
          dispatch(getClusters(clusterSensorsByDeviceId(kit, selectedPlot)));
          toast.success(t("successMsg.DEVICE_COORDINATE_UPDATED_SUCCESSFULLY"));
          setEditDevice(false);
          dispatch(getSelectedSensor(-1));
        }).catch(error => {
          if (error.response && error.response.status === 422) {
            setError(error.response.data.message);
            setEditDevice(true);
            toast.error(error.response.data.message)
          }
        }).finally(() => {
          dispatch(getFindLocation(false));
          dispatch(getSelectedCoordinate({}));
          dispatch(toggleLoader(false));
        });
      }
    }
  }

  function getClusterName(deviceId) {
    const selectedPropertyDetails = props.type === "FARM" ? selectedFarm.propertyDetails : selectedPlot.propertyDetails;
    if (selectedPropertyDetails) {
      for (const [key, value] of Object.entries(selectedPropertyDetails)) {
        if (value.cluster === deviceId) {
          return value.clusterName;

        }
      }

    }
    return "";
  }


  return (
    <div>
      <div className={"sa-table-container " + (isTable ? "" : "hide-table")}>
        <table className="table table-borderless sa-table-width">
          <thead>
          <tr>
            <th className={"sa-table-head-sticky"}>{t("title.SENSORS")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.NAME")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.LATITUDE")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.LONGITUDE")}</th>
            {hasPermission("SETTINGS.FARM.SETTINGS.DEVICES", "WRITE") &&
              <th className="sa-table-item-align sa-table-head-sticky"></th>
            }
          </tr>
          </thead>
          <tbody>
          {kit && kitMap.map((device, index) => (
            <tr key={device.deviceId}>
              <td className={'sa-table-data'}>{t("title.SENSORS")} {index + 1}</td>
              <td className={'sa-table-data'}>
                <div style={{height: '50px'}}>
                  <Form.Control disabled={values.deviceId !== device.deviceId} name="name"
                                value={(values.deviceId === device.deviceId) ? values.name : getClusterName(device.deviceId)}
                                onChange={handleChange} onBlur={handleOnBlur}/>
                  {errors.name && values.deviceId === device.deviceId && (
                    <p className="warning-input-msg">{errors.name}</p>)} </div>
              </td>
              <td className={'sa-table-data'}>
                <div style={{height: '50px'}}>
                  <Form.Control disabled={values.deviceId !== device.deviceId} name="lat"
                                value={(values.deviceId === device.deviceId) ? values.lat : device.lat}
                                onChange={handleChange} onBlur={handleOnBlur}/>
                  {errors.lat && values.deviceId === device.deviceId && (
                    <p className="warning-input-msg">{errors.lat}</p>)} </div>
              </td>
              <td className={'sa-table-data'}>
                <div style={{height: '50px'}}>
                  <Form.Control disabled={values.deviceId !== device.deviceId} name="lng"
                                value={(values.deviceId === device.deviceId) ? values.lng : device.lng}
                                onChange={handleChange} onBlur={handleOnBlur}/>
                  {errors.lng && values.deviceId === device.deviceId && (
                    <p className="warning-input-msg">{errors.lng}</p>)}</div>
              </td>
              {hasPermission("SETTINGS.FARM.SETTINGS.DEVICES", "WRITE") &&
                <td className="sa-table-item-align">
                  <div className={'sa-table-action-flex'}>
                    <div hidden={isEditKit || isEditValve || editDevice} className="table-actions"
                         data-toggle="tooltip" title="Edit"><FeatherIcon
                      onClick={() => editDevices(device, index)} className="table-action" icon={"edit"}/></div>
                    <div hidden={editDevice ? (values.deviceId !== device.deviceId) : true}
                         className="sa-table-actions sa-action-m"
                         data-toggle="tooltip" title="Update Coordinates"><FeatherIcon
                      onClick={() => pickDeviceLocation(device)} className={"table-action"}
                      icon={"map-pin"}/></div>
                    <div hidden={editDevice ? (values.deviceId !== device.deviceId) : true}
                         className="sa-table-actions sa-action-m"
                         data-toggle="tooltip" title="Cancel"><FeatherIcon onClick={() => onClose()}
                                                                           className={"table-action"}
                                                                           icon={"x-circle"}/></div>
                    <div hidden={editDevice ? (values.deviceId !== device.deviceId) : true}
                         className="sa-table-actions sa-action-m"
                         data-toggle="tooltip" title="Update"><FeatherIcon onClick={uploadDeviceCoordinatedChained}
                                                                           className={"table-action"}
                                                                           icon={"upload"}/></div>
                  </div>
                </td>
              }
            </tr>
          ))}
          </tbody>
        </table>

        {props.type === "FARM" ? (
          <>
            {!selectedFarm.kitId && kitMap.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("info.KIT_NOT_CONFIGURED")}</div>
              </div>
            )}
            {selectedFarm.kitId && !kit && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.KIT_NOT_FOUND")}</div>
              </div>
            )}
          </>
        ) : (
          <>
            {!selectedPlot.kitId && kitMap.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("info.KIT_NOT_CONFIGURED")}</div>
              </div>
            )}
            {selectedPlot.kitId && !kit && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.KIT_NOT_FOUND")}</div>
              </div>
            )}
          </>
        )}

      </div>


    </div>
  )
};

export default DeviceSetting
