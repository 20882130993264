import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {toggleLoader} from "../../../shared/actions/setting";
import {useDispatch} from "react-redux";
import {getUserId} from "../../../shared/utils/SharedAuthentication";


const PlotCultivationView = (props) => {
  const [cycleInfo, setCycleInfo] = useState([]);
  const {plotId} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId + `/cultivationCycle/` + props.cycle.id + `/log`)
      .then(res => {
        setCycleInfo(res.data.content)
      }).finally(() => {
      dispatch(toggleLoader(false))
    })
  }, [])


  return (
    <div className={"sa-popup-bg "}>
      <div className="sa-popup">
        <div className="sa-modal-box-style">
          <div className="sa-popup-header">
            <span className={"sa-model-heading"}>Cycle Details</span>
            <div className="sa-popup-close-icon" onClick={() => props.onClose()}>
              <FeatherIcon
                className={"sa-modal-close-icon"} icon={"x"}/>
            </div>
          </div>
          <div className="table-container sa-popup-content">
            <table className="table table-borderless table-width-sm w-100pe">
              <thead>
              <tr>
                <th scope="col">Stage</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
              </tr>
              </thead>
              <tbody>
              {cycleInfo.map((data, index) => (
                <tr key={index}>
                  <td>{data.stage ? data.stage : "-"}</td>
                  <td>{data.startTime ? data.startTime : "-"}</td>
                  <td>{data.endTime ? data.endTime : "-"}</td>

                </tr>

              ))}
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  )

}
export default PlotCultivationView
