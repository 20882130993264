import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../../shared/actions/setting";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {ExportToCsv} from "export-to-csv";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toCapsFirst} from "../../../utils/Utils";
import {DateRangePicker} from 'rsuite';
import * as dateFns from "date-fns";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../../shared/utils/utils";

const CcoReportGenerateGRNSummary = () => {
  let history = useHistory();
  const [grnList, setGrnList] = useState([]);
  const dispatch = useDispatch();
  const {farmId} = useParams();
  const {t, i18n} = useTranslation();
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date())
  });

  function exportData() {
    const data = [];
    grnList.forEach(item => {
      data.push({
        "farmer ": item.farmer.firstName,
        "item name ": item.product.name,
        "item code ": item.product.code,
        "total weight ": item.product.unitCrateWeight,
        "average freshness score ": item.freshnessScore
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'GRNSummery',
      useBom: true,
      noDownload: false,
      headers: ["farmer", "item name", "item code", "total weight", "average freshness score"],
      filename: "GRNSummery",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);

  }

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }


  useEffect(() => {
    if (!selectedCorporate) {
      return;
    }
    const todayDate = dateFormat(new Date());
    let fromDate = dateRange ? dateRange.fromDate : todayDate;
    let toDate = dateRange ? dateRange.toDate : todayDate
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/cco/user/` + getUserId() + '/corporate/' + selectedCorporate + `/goodReceiveNote/summary/report?endDate=` + toDate + '&startDate=' + fromDate)
      .then(function (response) {
        setGrnList(response.data.content);
      })
      .catch(function (error) {
        if (error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [dateRange, selectedCorporate]);


  function backToReport() {
    history.push("/cco/cco-report");
  }

  return (
    <div hidden={!selectedCorporate}>
      <Container>
        <div className={"my-3 pdf-hide"}>
          <FeatherIcon icon={"arrow-left"}
                       onClick={() => backToReport()}
                       className={"sa-cursor"}/>
        </div>
        <div className="single-card m-rl-m-8 p-a-16">
          <div className="single-card-items-export-data p-0">
                    <span className={"sa-table-float-left"}>
                                <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                                                 onChange={(e) => onCheck(e)}
                                                 oneTap={false} onOk={(e) => onCheck(e)}
                                                 showWeekNumbers={true} appearance={"default"}
                                                 placeholder={t("title.LAST_WEEK")}
                                                 ranges={[{
                                                   label: 'Today',
                                                   value: [new Date(), new Date()]
                                                 }, {
                                                   label: 'Yesterday',
                                                   value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                                                 }, {
                                                   label: 'Last 7 days',
                                                   value: [dateFns.subDays(new Date(), 6), new Date()]
                                                 }, {
                                                   label: 'Last 30 days',
                                                   value: [dateFns.subDays(new Date(), 30), new Date()]
                                                 }]}
                                />
                            </span>
            <div className="sa-table-report-filter-right">
              <button disabled={grnList.length === 0}
                      onClick={exportData} className="sa-table-btn-secondary sa-table-btn-mute"><FeatherIcon
                className={"sa-table-icon-size"} icon={"upload"} onClick={exportData}/> <span
                className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span></button>
            </div>
          </div>
          <div className="sa-table-container xindicate-report-calc-height m-t-1">
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.FARMER")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.ITEMS")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.DATE")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.TOTAL_QUANTITY")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.ACCEPTED_QUANTITY")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.REJECTED_QUANTITY")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.NO_OF_CRATES")}</th>
              </tr>
              </thead>
              <tbody>
              {grnList.length > 0 && grnList.map(item =>
                <tr key={item.id}>
                  <td className={'sa-table-data'}>{item.farmer.firstName}</td>
                  <td className={'sa-table-data'}>{toCapsFirst(item.product.name) + "(" + item.product.code + ")"}</td>
                  <td className={'sa-table-data'}>{item.date}</td>
                  <td className={'sa-table-data'}>{item.totalQuantity}</td>
                  <td className={'sa-table-data'}>{item.acceptedQuantity}</td>
                  <td className={'sa-table-data'}>{item.rejectedQuantity}</td>
                  <td className={'sa-table-data'}>{item.noOfCrates}</td>
                </tr>)}
              </tbody>
            </table>
            {grnList.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.GRN_SUMMARY")}</div>
              </div>)
            }
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CcoReportGenerateGRNSummary;
