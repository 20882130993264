export function validateChangePassword(values) {
  let errors = {};

  if (!values.oldPassword) {
    errors.oldPassword = 'Current password is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be 8 or more characters';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirm Password is required';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Password Should match';
  }

  return errors;

}

export function validateConfirmationDialogNoValidation(values) {
  let errors = {};

  return errors;
}

export function validateConfirmationDialog(values) {
  let errors = {};
  if (!values.reason) {
    errors.reason = 'Reason is required';
  }

  return errors;
}

export function validateSignUp(values) {
  let errors = {};
  if (!values.username) {
    errors.username = 'Username is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be 8 or more characters';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirm Password is required';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Password Should match';
  }


  return errors;
}


export function validateResetPassword(values) {
  let errors = {};
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be 8 or more characters';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirm Password is required';
  }
  if (values.password !== values.confirmPassword) {
    errors.match = 'Password Should match';
  }

  return errors;
}

export default function validateLogin(values) {
  let errors = {};
  if (!values.username || values.username === "") {
    errors.username = 'Username is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  }

  return errors;
};

export function validateForgotPassword(values) {
  let errors = {};
  if (!values.email) {
    errors.username = 'Email is required';
  }

  return errors;
}
