import React, {useEffect, useState} from "react";
import {hasPermission,} from "../../../utils/Authentication";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import FeatherIcon from "feather-icons-react";
import {resetConfirmationDialog, toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import * as XLSX from "xlsx";
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import {convertSensorCodeToSensorName} from "../../../utils/Utils";
import {getSelectedPlot} from "../../../actions/plot";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const UploadKitData = (props) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const [excelData, setExcelData] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [kit, setKit] = useState(null);

  const loader = useSelector(state => {
    return state.setting.isLoading;
  });


  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });


  let selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });


  useEffect(() => {
    if (!props.plotId) {
      return;
    }

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId)
      .then(function (response) {
        dispatch(getSelectedPlot(response.data.content));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });

  }, [props.plotId])


  useEffect(() => {
    if (!selectedPlot.kitId) {
      return;
    }
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedPlot.kitId)
      .then(res => {
        setKit({...res.data.content});
        dispatch(toggleLoader(false))
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
        dispatch(toggleLoader(false))
      }
    ).finally(
    );

  }, [selectedPlot.kitId]);

  function uploadExcelFile(file) {
    // dispatch(toggleLoader(true));
    // excelData.splice(0, excelData.length);
    setIsUpload(true);
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      type: 'request',
      confirmationHeading: "Are you sure want to import " + " \"" + file.name + "\"" + " ?"
      ,
      confirmationDescription: "The selected file named " + " \"" + file.name + "\"" + " will be uploaded, make sure the fields are appropriate to import!"
    }));
    readExcelFile(file);
  }

  function readExcelFile(file) {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;


        const wb = XLSX.read(bufferArray, {type: "buffer"});

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);

      };
    });

    promise.then((data) => {
      let optimizedData = [];

      for (let j = 0; j < data.length; j++) {
        let row = data[j];
        let property = {};

        for (const [key, value] of Object.entries(row)) {
          if (key === "date") {
            continue;
          }

          property.time = moment(row.date).format("YYYY-MM-DD HH:mm:ss");
          property.value = value;
          property.number = +key;
          property.code = kit.model.properties[+key];
          optimizedData.push(JSON.parse(JSON.stringify(property)))
        }
      }

      setExcelData(optimizedData);

    });
  }

  function resetFile(file) {
    if (file) {
      document.getElementById("file-upload").value = "";
    }
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess || !isUpload) {
      setExcelData([]);
      return;
    }


    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_HOST + `core/kit/` + kit.id + `/properties`, excelData)
      .then(res => {
        toast.success(t("successMsg.EXCEL_DATA_IMPORTED_SUCCESSFULLY"));

      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsUpload(false);
        dispatch(toggleLoader(false));
        setExcelData([]);
      });
  }, [confirmationDialog]);


  return (
    <div hidden={loader} className="">
      {/*<div className={"farm-info-heading"}>*/}
      {/*  <div className={"farm-info-heading-set"}>Upload Bulk Data</div>*/}
      {/*</div>*/}
      {hasPermission("SETTINGS.FARM.PLOTS.SETTINGS.UPLOAD_BULK_DATA", "WRITE") && kit &&
        // <div className="pull-right m-t-32 hide-768">
        //   <button hidden={isEditValve || isEditKit || editDevice} className="btn btn-sa-secondary w-140p"
        //           onClick={() => editValve("valve")}> Upload </button>
        // </div>
        <div className="float-right m-t-3">
          <label style={{padding: '9px 12px'}} className="btn btn-sa-secondary w-140p pis-add">
            <form onClick={(e) => {
              resetFile(e.target.files[0]);
            }}>
              <input accept=".xlsx" id="file-upload" type="file"
                     onChange={(e) => {
                       const file = e.target.files[0];
                       uploadExcelFile(file);
                     }}

              /></form>

            <a data-tip="React-tooltip" style={{marginLeft: -2}}>
              <FeatherIcon
                className={"icon-in-btn"} icon={"download"}/> <span
              className={"hide-375"}>{t("button.IMPORT")}</span></a>
            <ReactTooltip place="top" className="toolTipTheme" effect="solid">
              <p>{t("instructions.PLEASE_USE_THIS_ORDER_FOR_THE_COLUMNS_(INDICATE_NUMBER_IN_FIRST_ROW)")}</p>
              <ol className={"text-left"}>
                <li key={"date"}>{t("title.DATE")}</li>
                {kit.model.properties.filter(item => item !== 'B').map((item, index) => (
                  <li key={index}>{convertSensorCodeToSensorName(item)}</li>))}
              </ol>
            </ReactTooltip>
          </label>
        </div>
      }
      {!kit && <div className={"empty-results"}>
        <FeatherIcon icon="info"/>
        <div className={"empty-results-text"}>{t("emptyMsg.KIT_NOT_CONFIGURED")}</div>
      </div>}
      {/*<div className={"farm-info-set"}>*/}
      {kit && <div className={"empty-results"}>
        <FeatherIcon icon="info"/>
        <div className={"empty-results-text"}>{t("info.PLEASE_SELECT_DATA_FILE_TO_UPLOAD")}</div>
      </div>}
      {/*</div>*/}
    </div>
  );
};

export default UploadKitData
