import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {GoogleMap, Polygon, withGoogleMap, withScriptjs, Marker} from "react-google-maps";
import {useTranslation} from "react-i18next";
import FeatherIcon from 'feather-icons-react';
import {StandaloneSearchBox} from "@react-google-maps/api";
import {compose, withProps} from "recompose";

// function getPolygonCoords(e) {
//   let len = e.getPath().getLength();
//   let coordinates = []
//   let err = null;
//   if (len >= 3) {
//     for (let i = 0; i < len; i++) {
//       let latlan = {}
//       latlan.lat = e.getPath().getArray()[i].lat();
//       latlan.lng = e.getPath().getArray()[i].lng();
//       coordinates.push(latlan)
//     }
//   } else {
//     err = 'A Farm should consist at least 3 points'
//   }
//   return {coordinates: coordinates, err: err}
// }

let history = {
  past: [],
  present: [],
  future: [],
}

const MapComponent = compose(
  withProps({

    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDobm_3OLsu0cFTLt-zUAgt-_7Oi-TkGT4&v=3.exp&libraries=geometry,drawing,places,visualization",
    loadingElement: <div style={{height: `100%`}}/>,
    containerElement: <div style={{height: `332px`}}/>,
    mapElement: <div style={{height: `100%`}}/>
  }),
  withScriptjs,
  withGoogleMap
)(props => {
    const [map, setMap] = useState(null);
    const [searchBox, setSearchBox] = useState(null);
    const [path, setPath] = React.useState(null);
    const [markers, setMarkers] = useState([]);
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);
    const clickCount = useRef(0);

    const selectedFarm = useSelector(state => {
      return state.farmList.selectedFarm;
    });

    const selectedPlot = useSelector(state => {
      return state.plotList.selectedPlot;
    });

    const plotList = useSelector(state => {
      return state.plotList.plotList
    });

    const {t} = useTranslation();
    const google = window.google

    const dotIcon = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 4,
      fillColor: 'white',
      fillOpacity: 1,
      strokeColor: 'black',
      strokeWeight: 1,
    };

    const handleMapClick = (event) => {
      if(history.present.length < 3) {
        clickCount.current += 1;
        const newMarkers = [...markers, { lat: event.latLng.lat(), lng: event.latLng.lng() }];
        setMarkers(newMarkers);

        if (clickCount.current === 3) {
          // Remove markers after 3 clicks
          setMarkers([]);
          // Set path using marker coordinates
          setPath(newMarkers);

          history = {
            past: [...history.past, history.present],
            present: newMarkers,
            future: [],
          }

          clickCount.current = 0;
        }
      }
    };

    useEffect(() => {
      if (!props.coordinate) {
        return
      }
      setPath(props.coordinate)
    }, [props.coordinate]);

    const deselectedPlots = plotList.filter(plot => plot.id !== selectedPlot.id)

    useEffect(() => {
      if (!path) {
        history = {
          past: [],
          present: [],
          future: [],
        }
      }
      
      if (map && path && path.length>0) {
        const bounds = new window.google.maps.LatLngBounds();
        path.forEach((coordinate) => {
          path.forEach((point) => {
            bounds.extend(point);
          });
        });
        map.fitBounds(bounds);

        if (history.past.length === 0) {
          history = {
            past: [path],
            present: path,
            future: [],
          }
        }
      }
    }, [map, path]);

    const undo = () => {
      if (history.past.length < 2) return;
    
      const previousPath = history.past[history.past.length - 1];
      const newPast = history.past.slice(0, -1);
      history = {
        past: newPast,
        present: previousPath,
        future: [history.present, ...history.future],
      }

      setPath(previousPath)
    };
    
    const redo = () => {
      if (history.future.length === 0) return;
    
      const nextPath = history.future[0];
      const newFuture = history.future.slice(1);
      history = {
        past: [...history.past, history.present],
        present: nextPath,
        future: newFuture,
      }

      setPath(nextPath)
    };
    
    const deletePolygon = () => {
      if (history.present.length === 0) return;

      setPath([]);
      history = { past: [...history.past, history.present], present: [], future: [] }
    };

    const onEdit = useCallback((pol) => {
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map(latLng => {
            return {lat: latLng.lat(), lng: latLng.lng()};
          });

        setPath(nextPath);

        history = {
          past: [...history.past, history.present],
          present: nextPath,
          future: [],
        }
      }
    }, [setPath]);


    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
      polygon => {

        polygonRef.current = polygon;
        const path = polygon.getPath();
        listenersRef.current.push(
          path.addListener("set_at", onEdit),
          path.addListener("insert_at", onEdit),
          path.addListener("remove_at", onEdit)
        );
      },

      [onEdit]
    );

    const onUnmount = useCallback(() => {
      listenersRef.current.forEach(lis => lis.remove());
      polygonRef.current = null;
    }, []);

    useEffect(() => {
      if (!props.updatedCoordinates || !path) {
        return
      }

      let isInsideFarm = [];
      let isInsidePlot = [];
      const farmPolygen = new google.maps.Polygon({paths: selectedFarm.coordinates});
      const currentPolygen = new google.maps.Polygon({paths: path});
      const currentPolygenVertices = polygonRef.current.getPath().getArray();

      if (props.isEditPlot) {
        for (let i = 0; i < currentPolygenVertices.length; i++) {
          isInsideFarm[i] = google.maps.geometry.poly.containsLocation(currentPolygenVertices[i], farmPolygen);
          for (let j = 0; j < deselectedPlots.length; j++) {
            let plotPolygen = new google.maps.Polygon({paths: deselectedPlots[j].coordinates});
            let data = google.maps.geometry.poly.containsLocation(currentPolygenVertices[i], plotPolygen);
            isInsidePlot.push(data)
          }
        }

        if (isInsideFarm.includes(false)) {
          props.setErrMap('A Plot should be inside the farm boundary')
        } else if (isInsidePlot.includes(true)) {
          props.setErrMap('Plots can not be intersect each other')
        } else if (path.length < 3) {
          props.setErrMap('A Plot should consist at least 3 points')
        } else {
          props.setErrMap(null)
        }
      }
      else {   
        //   // validation for farm edit to check whether all plots are inside new farm polygen
        //   for (let j = 0; j < plotList.length; j++) {
        //     let plotPolygen = new google.maps.Polygon({paths: plotList[j].coordinates});
        //     let plotPolygenVertices = plotPolygen.getPath().getArray();
        //     for (let i = 0; i < plotPolygenVertices.length; i++) {
        //       let data = google.maps.geometry.poly.containsLocation(plotPolygenVertices[i], currentPolygen);
        //       isInsidePlot.push(data)
        //     }
        //   }

          if (path.length < 3) {
            props.setErrMap('A Farm should consist at least 3 points')
          } else {
            props.setErrMap(null)
          }
        }

      props.updatedCoordinates(path)
    }, [path]);

    const onPlacesChanged = () => {
      const places = searchBox.getPlaces();

      const google = window.google;
      const bounds = new google.maps.LatLngBounds();
      //
      // coordinates.map((hotel, i) => {
      //     const position = new google.maps.LatLng(hotel.lat, hotel.lng);
      //     bounds.extend(position);
      // });
      //
      places.forEach((place) => {
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      })
      map.fitBounds(bounds);

    };
    const onSBLoad = ref => {
      setSearchBox(ref);
    };

    function getCenter(coordinates) {
      if (!coordinates) {
        return {
          lat: 6.885047,
          lng: 79.855808
        };
      }
      let lat = 0;
      let lng = 0;
      for (let i = 0; i < 3; i++) {
        lat += coordinates[i].lat;
        lng += coordinates[i].lng;
      }
      return {lat: lat / 3, lng: lng / 3};

    }

    return (
      <div>
        <div className={'position-relative'}>
        <GoogleMap defaultZoom={8}
          ref={map => {
            setMap(map);
          } }
          zoom={12}
          version="weekly"
          on
          onClick={handleMapClick}
          center={getCenter((path && path.length>0) ? path : props.coordinate)}
          defaultCenter={{ lat: 6.927079, lng: 79.861244 }}
          mapTypeId={"satellite"}
        >

          {selectedFarm.coordinates && props.isEditPlot && <Polygon
            path={selectedFarm.coordinates}
            onClick={handleMapClick}
            options={{
              fillColor: "#000",
              fillOpacity: 0.4,
              strokeColor: "#000",
              strokeOpacity: 1,
              strokeWeight: 1
            }} />}

          {plotList.length > 0 && props.isEditPlot && (plotList.filter(plot => plot.coordinates && plot.id !== selectedPlot.id).map((plot, index) => <div key={'plot' + index}>

            <Polygon
              path={plot.coordinates}
              // key={index}
              editable={false}
              options={{
                geodesic: true,
                fillColor: "#A3A3A37A",
                fillOpacity: 1,
                strokeColor: "#50565FA3",
                strokeOpacity: 1,
                strokeWeight: 1,
                // zIndex: 1000,
              }} />
          </div>
          ))}

          {history.present.length < 3 && markers.map((marker, index) => (
              <Marker key={index} position={marker} icon={dotIcon}/>
          ))}

          <Polygon
            // Make the Polygon editable / draggable
            ref={polygonRef}
            editable
            draggable
            path={path}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount} />
          
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={onSBLoad}
          >
            <input
              type="text"
              style={{
                boxSizing: 'border-box',
                border: `1px solid transparent`,
                width: `270px`,
                height: `40px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.1)`,
                fontSize: `14px`,
                outline: `none`,
                margin: 'center',
                textOverflow: `ellipses`,
                position: 'absolute',
                bottom: '285px',
                marginLeft: '40%'
              }}
              placeholder={t("placeHolder.SEARCH_PLACE")} />
          </StandaloneSearchBox>
        </GoogleMap>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
        <button className="btn btn-sa-secondary edit-btn-color w-140p" onClick={undo} style={{marginRight: '12px'}}>
          <FeatherIcon className={"text-dark-gray m-r-8"} icon={"rotate-ccw"}
                                  width={'20px'}/>Undo
        </button>
        <button className="btn btn-sa-secondary edit-btn-color w-140p" onClick={redo} style={{marginRight: '12px'}}>
          <FeatherIcon className={"text-dark-gray m-r-8"} icon={"rotate-cw"}
                                  width={'20px'}/>Redo
        </button>
        <button className="btn btn-sa-secondary edit-btn-color w-140p" onClick={deletePolygon}>
          <FeatherIcon className={"text-dark-gray m-r-8"} icon={"trash"}
                                  width={'20px'}/>Delete
        </button>
      </div>
    </div>
    )
  }
);

export default MapComponent;