import React, {useEffect, useState} from 'react';
import {toggleLoader} from "../shared/actions/setting";
import axios from "axios";
import {getUserId} from "../shared/utils/SharedAuthentication";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {isEmpty} from "underscore";

function PlotStatus(props) {
  const {t} = useTranslation();

  const [plotStatus, setPlotStatus] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.kitId) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + props.kitId)
      .then(function (response) {
        if (response.data.content) {
          setPlotStatus(response.data.content);
        }
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });

  }, [props.kitId]);

  function getStatus(kitSummary) {
    if (isEmpty(kitSummary?.alerts)) {
      return true;
    }

    for (let alert in kitSummary.alerts) {
      if (kitSummary.alerts[alert]) {
        return false;
      }
    }

    return true;

  }

  return (
    <div className="card-content-status w-124 m-rl-a">
      <div style={{color: !props.kitId ? "#757575" : getStatus(plotStatus) ? '#22c687' : '#FF4343'}}>
        {!props.kitId ? "N/A" : getStatus(plotStatus) ? "good" : "bad"}
      </div>
    </div>
  );
}

export default PlotStatus;
