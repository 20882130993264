import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import PlotHeaderSettings from "./plot-header-settings";
import PlotInfo from "./plot-info";
import {TOGGLE} from "../../../utils/Enum";
import {useDispatch, useSelector} from "react-redux";
import {getBottomReached} from "../../../actions/plot";
import PlotSetting from "./plot-setting";
import PlotUserDetails from "./plot-user-details";

const PlotLayoutSettings = () => {
  const dispatch = useDispatch();
  const [showFadeEffect, setShowFadeEffect] = useState(false);

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);


  function handleScroll() {
    setShowFadeEffect(window.pageYOffset >= 5);
  }

  function bottomReached() {
    const offset = document.getElementById('listView').offsetHeight;
    const scrolledHeight = document.getElementById('listView').scrollTop;
    dispatch(getBottomReached((offset + scrolledHeight + 1) >= document.getElementById('listView').scrollHeight))


  }

  return (
    <div id="listView" className={"list-view " + (toggle === TOGGLE.LIST ? 'full-list-view' : 'half-list-view')}
         onScroll={bottomReached}>
      <div className={toggle === TOGGLE.LIST ? "" : showFadeEffect ? "empty-scroll-list bg-img-none" : ""}/>
      {/*<Container>*/}
      <PlotHeaderSettings/>
      <Switch>
        <Route path="/:farmId/settings/plot/:plotId/setting" component={PlotSetting}/>
        <Route path="/:farmId/settings/plot/:plotId/info" component={PlotInfo}/>
        <Route path="/:farmId/settings/plot/:plotId/user" component={PlotUserDetails}/>

      </Switch>
      {/*</Container>*/}
    </div>
  );
};

export default PlotLayoutSettings;
