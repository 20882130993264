import React, {useEffect, useState} from "react";
import FormHandler from "../utils/FormHandler";
import axios from "axios";
import {toast} from "react-toastify";
import {toggleLoader} from "../actions/setting";
import {useDispatch} from "react-redux";
import {validateResetPassword} from "../utils/SharedFormValidationRules";


const Reset = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showText, setShowText] = useState(false);
  const dispatch = useDispatch();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
  } = FormHandler(reset, validateResetPassword);

  function reset() {
    setIsLoading(true);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));

    const queryParams = new URLSearchParams(props.location.search);
    values.key = queryParams.get("hash");
    values.username = queryParams.get("uname");

    axios.post(process.env.REACT_APP_HOST + '/reset-password', values)
      .then(function (response) {
        setIsLoading(false);
        toast.success("Password reset successful, please login to continue...");
        dispatch(toggleLoader(false));
        props.history.push('/login');
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          errors.message = error.response.data.message;
        }
        setIsLoading(false);
        dispatch(toggleLoader(false));
      });
  }, [isLoading]);

  return (
    <div className="login-background" id="login">
      <div className="login-inner">
        <div className="login-box">
          <div className="sa-modal-header">
            Reset password
          </div>

          <form onSubmit={handleSubmit} noValidate>
            <div className="form-group m-b-6">
              <label htmlFor="pwd">Password</label>
              <input type={!showText ? "password" : "text"} value={values.password || ''} onChange={handleChange}
                     onBlur={handleOnBlur}
                     className={errors.password ? "form-control warning-input" : "form-control"} name="password"
                     placeholder="Enter your password"/>
              <span className="fa fa-fw fa-eye field-icon toggle-password" onClick={() => setShowText(!showText)}/>
              {errors.password && (<p className="warning-input-msg">{errors.password}</p>)}
            </div>
            <div className="form-group m-b-6">
              <label htmlFor="pwd">Confirm Password</label>
              <input type={!showText ? "password" : "text"} value={values.confirmPassword || ''} onChange={handleChange}
                     onBlur={handleOnBlur}
                     className={errors.confirmPassword ? "form-control warning-input" : "form-control"}
                     name="confirmPassword" placeholder="Enter your password"/>
              <span className="fa fa-fw fa-eye field-icon toggle-password" onClick={() => setShowText(!showText)}/>
              {errors.confirmPassword && (<p className="warning-input-msg">{errors.confirmPassword}</p>)}
              {!errors.confirmPassword && !errors.password && errors.match && (
                <p className="warning-input-msg">{errors.match}</p>)}
            </div>
            {errors.message && (<div className={"login-warning-msg"}>{errors.message}</div>)}
            <button className="btn btn-sa-primary w-100 m-b-14">SUBMIT</button>
          </form>
        </div>
      </div>
    </div>);
};


export default Reset;
