import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FormHandler from "../utils/FormHandler";
import axios from 'axios';
import {toast} from "react-toastify";
import {toggleLoader} from "../actions/setting";
import {useDispatch} from "react-redux";
import {validateForgotPassword} from "../utils/SharedFormValidationRules";

const ForgotPassword = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    initForm,
    handleOnBlur
  } = FormHandler(forgotPassword, validateForgotPassword);

  function forgotPassword() {
    setIsLoading(true);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));

    values.host = window.location.protocol + '//' + window.location.host + '/reset';
    axios.post(process.env.REACT_APP_HOST + '/request-reset-password', values)
      .then(function (response) {
        toast.success("Check mail to reset your password");
        dispatch(toggleLoader(false));
        initForm({});
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          errors.userError = error.response.data.message;
        }
        dispatch(toggleLoader(false));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLoading]);

  return (
    <div className="login-background" id="login">
      <div className="login-inner">
        <div className="login-box">
          <div className="sa-modal-header">
            Reset password
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-group m-b-16">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" value={values.email || ''} onChange={handleChange}
                     onBlur={handleOnBlur} className={errors.username ? "form-control warning-input" : "form-control"}
                     id="email" placeholder="Enter your Email"/>
              {errors.username && (<p className="warning-input-msg">{errors.username}</p>)}
            </div>
            {errors.userError && (<div className="login-warning-msg">{errors.userError}</div>)}
            <button className="btn btn-sa-primary w-100 m-b-14">SUBMIT</button>
            <div className="login-text-btn text-center">
              Back to <Link to={"/login"}>SIGNIN</Link>
            </div>
          </form>
        </div>
      </div>
    </div>);
};


export default ForgotPassword
