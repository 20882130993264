import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {useDispatch, useSelector} from "react-redux";
import Container from "react-bootstrap/Container";
import axios from "axios";
import {VIEW} from "../../utils/Enum";
import {
  changeView,
  resetConfirmationDialog,
  toggleConfirmationDialog,
  toggleLoader
} from "../../shared/actions/setting";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {getRefCount, getReferences, getUserId, isSuper} from "../../shared/utils/SharedAuthentication";
import BuyerItem from "./buyer-item";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddBuyer from "./buyer-add";
import {getBuyerList, getFilterBuyerList} from "../../actions/module";

const Buyer = (props) => {
  const {t, i18n} = useTranslation();

  const [buyerList, setBuyerList] = useState([]);
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [buyerId, setBuyerId] = useState("");

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  function hasAccess(id) {
    if (isSuper()) {
      return true;
    } else if (getRefCount() > 1) {
      let refArray = getReferences().split(',');
      if (refArray.includes(id)) {
        return true
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    dispatch(changeView(VIEW.BUYER_PROFILE));
  }, []);

  function handleDeleteTask(id) {
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_BUYER"),
      confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_BUYER")
    }));
    setDeleteId(id);
  }

  function onEdit(buyer) {
    setBuyerId(buyer.id)
    setIsUpdateAvailable(true);
    setIsModalVisible(true);
  }

  useEffect(() => {
    dispatch(changeView(VIEW.BUYER_PROFILE));
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/buyer')
      .then(res => {
        let tempList = []
        res.data.content.map(buyer => {
          if (hasAccess(buyer.id)) {
            tempList.push(buyer)
          }
        })
        setBuyerList(tempList)
        dispatch(getBuyerList(tempList))
        dispatch(getFilterBuyerList(tempList))
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }
    ).finally(() => {
      dispatch(toggleLoader(false))
    })
  }, [isUpdate])

  const buyerListFilter = useSelector(state => {
    return state.modules.buyerListFilter
  });

  useEffect(() => {
    setBuyerList(buyerListFilter)
  }, [buyerListFilter])


  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/buyer/` + deleteId)
      .then(res => {
        setBuyerList(buyerList.filter(item => item.id !== deleteId));
        toast.success(t("successMsg.BUYER_DELETED_SUCCESSFULLY"));
        dispatch(toggleLoader(false));
      })
  }, [confirmationDialog]);

  return (
    <div className="smart-farm">
      <div id="listView" className={'list-view full-list-view half-list-container'}>
        <Container>
          <Row id={"m-rl-m-16-2"}>
            {buyerList.map((buyer) => (
              <Col xl={4} lg={6} className={'m-b-4'} key={buyer.id}>
                <BuyerItem onEdit={onEdit} onDelete={handleDeleteTask} buyer={buyer}/>
              </Col>
            ))}
          </Row>
          {isSuper() &&
            <div className={"fixed-add-btn"}><FeatherIcon onClick={() => {
              setIsModalVisible(true);
              // setSteps(0)
              // resetForm()
            }} className={"icon-add-btn"} icon="plus-circle"/></div>
          }

          {buyerList && buyerList.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_BUYERS")}</div>
            </div>)
          }

        </Container>
      </div>

      {/**************************** buyerAddEditModelStart ********************************/}
      {isModalVisible &&
        <AddBuyer setIsModalVisible={setIsModalVisible} setIsUpdateAvailable={setIsUpdateAvailable}
                  isUpdateAvailable={isUpdateAvailable} buyerId={buyerId}
                  setIsUpdate={setIsUpdate} isUpdate={isUpdate} buyerList={buyerList} setBuyerList={setBuyerList}/>}
    </div>
  )

};
export default Buyer;
