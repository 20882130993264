import React from "react";
import FeatherIcon from 'feather-icons-react';
import {toCapsFirst} from "../../../utils/Utils";
import {Col, Row} from "react-bootstrap";
import FarmImages from "../../farm/farm-images";
import {useTranslation} from "react-i18next";

const FarmTaskView = (props) => {
  const {t, i18n} = useTranslation();

  return (
    <div className={"sa-popup-bg "}>
      <div className="sa-popup">
        <div className="sa-modal-box-style">
          <div className="sa-popup-header">
            <span className={"sa-model-heading"}>{props.task.note}</span>
            <div className="sa-popup-close-icon" onClick={() => props.onClose()}>
              <FeatherIcon
                className={"sa-modal-close-icon"} icon={"x"}/>
            </div>
          </div>
          <div className="sa-popup-content">
            <Col md={"6"}>
              <Row>
                <div className={"scout-head my-auto"}>{t("title.ASSIGNEE")}</div>
                <div className={"scout-value "}>
                  {toCapsFirst(
                    props.task.assignee ? props.task.assignee.lastName : " - "
                  )}
                </div>
              </Row>
              <Row>
                <div className={"scout-head my-auto"}>{t("title.SCOUTER")}</div>
                {props.task.createdBy && <div className={"scout-value"}>
                  {props.task.createdBy.lastName ? toCapsFirst(props.task.createdBy.lastName) : "-"}
                </div>}
              </Row>
              <Row>
                <div className={"scout-head my-auto"}>{t("title.DEADLINE")}</div>
                <div className={"scout-value"}>{props.task.endDate ? props.task.endDate : " - "}</div>
              </Row>
              <Row>
                <div className={"scout-head my-auto"}>{t("title.DESCRIPTION")}</div>
                <div className={"scout-value"}>
                  {props.task.description ? props.task.description : " - "}
                </div>
              </Row>
            </Col>
            <Col md={"6"}>
              <Row>
                <div className={"scout-head my-auto"}>{t("title.DATE_AND_TIME")}</div>
                <div className={"scout-value"}>{props.task.time}</div>
              </Row>
              <Row>
                <div className={"scout-head my-auto"}>{t("title.CATEGORY")}</div>
                <div className={"scout-value"}>{props.task.category ? props.task.category : " - "}</div>
              </Row>
              <Row>
                <div className={"scout-head my-auto"}>{t("title.STATUS")}</div>
                <div className={"scout-value status"}>{props.task.status}</div>
              </Row>
              <Row>
                <div className={"scout-head my-auto"}>{t("title.IMAGES")}</div>

                {props.task.images && (
                  <div className={props.task.images.length !== 0 && "p-2"}>
                    <FarmImages imgs={props.task.images}/>
                  </div>
                )}
                {(!props.task.images || props.task.images.length === 0) && (
                  <div className={"scout-value m-0"}>{t("emptyMsg.NO_IMAGES_AVAILABLE")}</div>
                )}

              </Row>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmTaskView;
