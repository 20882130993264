import React, {Suspense, useEffect} from 'react'
import './shared/shared.scss';
import './App.scss';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import axios from "axios";
import Login from "./shared/components/login";
import Test from "./components/test";
import Info from "./components/info";
import ForgotPassword from "./shared/components/forgot-password";
import Reset from "./shared/components/reset";
import SignUp from "./shared/components/sign-up";
import MainLayout from "./layouts/main-layout";
import Loader from "./shared/components/loader";
import {toast} from "react-toastify";
import ParticipantQRView from "./components/xindicate/participant/participant-qr-view";
import {getAccessToken} from "./shared/utils/SharedAuthentication";
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from './layouts/error-fallback';
import Register from "./components/register";
import FpoMail from "./components/ccr-profile/fpo-mail";
import {modifyUrl} from "./shared/proxy"

// const Login = lazy(()=> import("./components/login"));

function App() {

  useEffect(() => {
    toast.configure({
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <Loader/>
        <ErrorBoundary FallbackComponent={ErrorFallback}
                       onReset={() => {
                       }}>
          <Suspense fallback={<Loader load={true}/>}>
            <Switch>
              <Route path="/login" component={Login}/>
              <Route path="/register" component={Register}/>
              <Route path="/farmer/:farmerId" component={ParticipantQRView}/>
              <Route path="/test" component={Test}/>
              <Route path="/info/:message" component={Info}/>
              <Route path="/forgot-password" component={ForgotPassword}/>
              <Route path="/reset" component={Reset}/>
              <Route path="/sign-up" component={SignUp}/>
              <Route path="/fpo-mail/:orderId/requestStatus=:requestStatus" component={FpoMail}/>
              <PrivateRoute path="/*" component={MainLayout}/>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </div>
    </Router>
  );
}

const axiosUserContext = {};

axios.interceptors.request.use(
  async config => {
    if (process.env.REACT_APP_HOST.charAt(process.env.REACT_APP_HOST.length - 1) === "/" && config.url.charAt(process.env.REACT_APP_HOST.length) === "/") {
      config.url = config.url.slice(0, process.env.REACT_APP_HOST.length - 1) + config.url.slice(process.env.REACT_APP_HOST.length);
    }

    if(process.env.REACT_APP_SERVICE === "MICRO_SERVICE"){
      config.url = modifyUrl(config.url);
    }

    let accessToken = getAccessToken();
    let serverCallUrl = new URL(config.url);

    if (serverCallUrl.pathname.includes("/auth")) return config;

    if (accessToken) {
      // const { exp } = jwtDecode(accessToken);
      //
      // if (Date.now() > exp * 1000) {
      //   if (typeof axiosUserContext.refreshAccessToken !== "function") {
      //     throw new Error("Access Token cannot be refreshed");
      //   }
      //   await axiosUserContext.refreshAccessToken();
      //   accessToken = getAccessToken();
      // }

      config.headers["authorization"] = `Bearer ${accessToken}`;
      config.headers["cache-control"] = `no-cache`;
    }

    return config;
    // or throw new Error('User required')
  },
  // I don't think this function is required
  function (error) {

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    const serverCallUrl = new URL(originalRequest.url)
    const status = error.response && error.response.status


    if ((status === 401) && !originalRequest._retry && !serverCallUrl.pathname.includes('/oauth')) {
      // let token = await refreshAccessToken()
      // setAccessToken(token)

      // originalRequest._retry = true
      // originalRequest.headers.authorization = `Bearer ${token}`

      // return axios(originalRequest)
      localStorage.clear();
      window.location.href = "/login";

      return;
    }

    return Promise.reject(error)
  })

export const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      getAccessToken() ?
        <Component {...props} />
        :
        (
          <Redirect
            to={{
              pathname: "/login",
              query: props.location
            }}
          />
        )
    }
  />

);

export default App;
