import React, {useEffect, useState} from "react";
import axios from "axios";
import {toggleLoader} from "../shared/actions/setting";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {getUserId} from "../shared/utils/SharedAuthentication";

const IrrigationStatus = (props) => {
  const {t, i18n} = useTranslation();
  const [plotIrrigationStatus, setPlotIrrigationStatus] = useState([]);
  const [activeCount, setActiveCount] = useState(0);
  const [motorDetails, setMotorDetails] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/reference/` + props.plotId + `/irrigation`)
      .then(function (response) {
        if (response.data.content) {
          let list = response.data.content;

          setPlotIrrigationStatus(response.data.content);

          let count = list.filter(e => e.status === "ON").length;
          setActiveCount(count);
        }
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });

  }, []);

  // useEffect(() => {
  //     if (!props.plotId) {
  //         return
  //     }
  //     dispatch(toggleLoader(true));
  //     axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/reference/` + props.plotId + `/irrigation/summary`)
  //       .then(function (response) {
  //           if (response.data.content) {
  //               setMotorDetails(response.data.content);
  //           }
  //       })
  //       .catch(function (error) {
  //       })
  //       .finally(() => {
  //           dispatch(toggleLoader(false));
  //       });
  // }, [props.plotId]);

  useEffect(() => {
    if (!props.mqttPlotStatus) {
      return
    }
    if (props.mqttPlotStatus.topic.includes(props.plotId)) {
      setPlotIrrigationStatus(props.mqttPlotStatus);
    }
  }, [props.mqttPlotStatus]);


  return (
    <div className="text-center mr-lg-auto mx-lg-auto">
      <div className="card-content-heading">{t("title.IRRIGATION_STATUS")}</div>
      <div className="text-dark-gray w-124 text-center dis-in-bl card-content-status"
           title={plotIrrigationStatus.current}>
                   <span className="font-weight-bold">
                          {activeCount} </span><span className="outOf"> motor</span> ON
      </div>
    </div>
  )
};

export default IrrigationStatus;
