import React, {useEffect, useState} from "react";
import {plotCycleerror} from "../../../utils/FormValidationRules";
import FeatherIcon from "feather-icons-react";
import FormHandler from "../../../shared/utils/FormHandler";
import DatePicker from "react-datepicker";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {isValidDate} from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../../shared/utils/utils";

function PlotCyclePop(props) {
  const {t} = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const {plotId} = useParams();
  const [formErr, setFormErr] = useState(null);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
    deleteErrors,
  } = FormHandler(plotCycle, plotCycleerror);

  function plotCycle() {
    setIsLoading(true);
  }

  function formClick(event) {
    let names = ["suggestion", "name"];
    if (!event.target.name || !names.includes(event.target.name)) {
      suggestionList.length > 0 && setSuggestionList([]);
    }
  }

  function handleOnchangeDate(dateValue) {
    let event = {target: {}};
    event.target.name = "startTime";
    event.target.value = dateFormat(dateValue);
    handleChange(event);
  }

  function resetForm() {
    const values = {};
    values.startTime = dateFormat(new Date());
    initForm({...values});
    Object.keys(values).forEach(key => delete values[key]);
    deleteErrors(errors);
    setFormErr("");
  }


  useEffect(() => {
    if (!isLoading) {
      return;
    }

    values.startTime = (values.startTime ? values.startTime : dateFormat(new Date())) + " 00:00:00"

    axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId + `/cultivationCycle/` + props.cycle + `/log`, values)
      .then(res => {
        toast.success("Cycle Change Successfully");
        setIsModalVisible(false);
        setIsLoading(false);
        resetForm();

      })

  }, [isLoading]);


  return (
    <div>
      <FeatherIcon
        onClick={() => {
          setIsModalVisible(true);
        }}
        icon={"edit-3"}
      />

      {isModalVisible && (
        <div onClick={formClick} className="sa-popup-bg">
          <div className="sa-popup">
            <form onSubmit={handleSubmit} className={"sa-modal-box-style"}>
              <div className="sa-popup-header">
                <span className={"sa-model-heading"}>Change Cycle</span>
                <div
                  className="sa-popup-close-icon"
                  onClick={() => {
                    setIsModalVisible(false);
                  }}
                >
                  <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
                </div>
              </div>
              <div className="sa-popup-content">
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label htmlFor="Stage" className="text-left">Stage</label>
                    <select
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      value={values.stage || ""}
                      className={`form-control ${errors.stage && "warning-input"}`}
                      name="stage"
                    >
                      <option value={""} hidden>
                        Select stage
                      </option>
                      <option value={"GERMINATION"}>
                        Germination ( sowing seed)
                      </option>
                      <option value={"SEEDLING_STAGE"}>Seedling stage</option>
                      <option value={"GROWTH_STAGE"}>
                        Growth stage/ Vegetative stage
                      </option>
                      <option value={"FLOWERING"}>
                        Flowering stage
                      </option>
                      <option value={"FRUITING"}>
                        Fruiting stage
                      </option>
                      <option value={"HARVESTING"}>
                        Harvesting/ripening
                      </option>
                    </select>
                    {errors.stage && (
                      <p className="warning-input-msg ">{errors.stage}</p>)}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>Start Time</label>
                    <DatePicker
                      className={"form-control"}
                      onChange={startTime => handleOnchangeDate(isValidDate(startTime) ? startTime : new Date())}
                      selected={values.startTime ? new Date(values.startTime) : new Date()}
                      minDate={new Date()}
                      name={"startTime"}
                      fixedHeight
                      dateFormat={"dd/MM/yyyy"}
                    />
                  </div>
                </div>
              </div>

              <div className="sa-popup-btn ml-auto">
                <button id="btnCancel" type={"button"}
                        className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style" onClick={() => {
                  setIsModalVisible(false);

                }}>{t("button.CANCEL")}
                </button>
                <button id="btnAdd" className="sa-popup-secondary-btn-style" onClick={() => props.onClose()}>
                  {t("button.ADD_CYCLE")} Add Cycle
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default PlotCyclePop;
