import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleChangePassword, toggleLoader} from "../actions/setting";
import axios from "axios";
import FormHandler from "../utils/FormHandler";
import FeatherIcon from "feather-icons-react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {validateChangePassword} from "../utils/SharedFormValidationRules";
import {getUsername} from "../utils/SharedAuthentication";

const ChangePassword = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formErr, setFormErr] = useState("");
  const [showText, setShowText] = useState(false);

  const isChangePasswordVisible = useSelector(state => {
    return state.setting.isChangePasswordVisible
  });


  function hideChangePassword() {
    dispatch(toggleChangePassword(false));
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleOnBlur,
    initForm,
  } = FormHandler(changePassword, validateChangePassword);

  function changePassword() {
    setIsLoading(true);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));
    values.username = getUsername();
    axios.post(process.env.REACT_APP_HOST + '/change-password', values)
      .then(res => {
        if (res.status === 200) {
          resetForm();
          toast.success("Password changed successfully");
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          setFormErr(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(toggleLoader(false));
      })
  }, [isLoading]);

  function resetForm() {
    hideChangePassword();
    initForm({});
    setFormErr("");
    setShowText(false);
  }

  return (
    <div className={"sa-popup-bg " + (!isChangePasswordVisible && 'hide')}>
      <div className="sa-popup">
        <form onSubmit={handleSubmit} className={"sa-modal-box-style"}>
          <div className="sa-modal-header">
            <span className={"sa-model-heading"}>Change Password</span>
            <div className="sa-popup-close-icon" onClick={resetForm}><FeatherIcon className={"sa-modal-close-icon"}
                                                                                  icon={"x"}/></div>
          </div>
          <div className="sa-popup-content">
            <div className="col-md-6">
              <div className="form-group m-b-16">
                <label htmlFor="oldPassword">Current Password</label>
                <input type={!showText ? "password" : "text"}
                       className={`form-control ${errors.oldPassword && "warning-input"}`}
                       onBlur={handleOnBlur} onChange={handleChange}
                       value={values.oldPassword || ''} name="oldPassword" id="oldPassword"
                       placeholder="Enter your current password"/>
                <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                  className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>
                {errors.oldPassword && (
                  <p className="warning-input-msg ">{errors.oldPassword}</p>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group m-b-16">
                <label htmlFor="password">New Password</label>
                <input type={!showText ? "password" : "text"}
                       className={`form-control ${errors.password && "warning-input"}`}
                       onBlur={handleOnBlur} onChange={handleChange}
                       value={values.password || ''} name="password" id="password"
                       placeholder="Enter your new password"/>
                <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                  className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>
                {errors.password && (<p className="warning-input-msg ">{errors.password}</p>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group m-b-16">
                <label htmlFor="confirmPassword">Confirm New Password</label>
                <input type={!showText ? "password" : "text"}
                       className={`form-control ${errors.confirmPassword && "warning-input"}`}
                       onBlur={handleOnBlur} onChange={handleChange}
                       value={values.confirmPassword || ''} name="confirmPassword" id="confirmPassword"
                       placeholder="Retype your new password"/>
                <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                  className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>
                {errors.confirmPassword && (
                  <p className="warning-input-msg ">{errors.confirmPassword}</p>)}
              </div>
            </div>
          </div>
          {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}
          <div className="sa-popup-btn">
            <button className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style" type="button"
                    onClick={resetForm}>{t("button.CANCEL")}
            </button>
            <button className="sa-popup-secondary-btn-style">{t("button.CHANGE")}</button>
          </div>
        </form>
      </div>
    </div>
  )
};

export default ChangePassword
