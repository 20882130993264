import React from 'react';
import {GoogleMap, Marker, withGoogleMap, withScriptjs,} from "react-google-maps";

const MapWithAMarker = withScriptjs(withGoogleMap(props =>

  <GoogleMap
    defaultZoom={15}
    defaultCenter={{lat: props.location.lat, lng: props.location.lng}}
  >
    <Marker
      position={{lat: props.location.lat, lng: props.location.lng}}
    />
  </GoogleMap>
));

export default function FarmerLocation({location}) {


  return (
    <div>
      <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDobm_3OLsu0cFTLt-zUAgt-_7Oi-TkGT4&v=3.exp&libraries=geometry,drawing,places,visualization"
        loadingElement={<div style={{height: `100%`}}/>}
        containerElement={<div style={{height: `400px`}}/>}
        mapElement={<div style={{height: `100%`}}/>}
        location={location}
      />
    </div>
  );
}

