import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {timeDurationToReadableForm} from "../../../utils/Utils";
import * as dateFns from "date-fns";
import {DateRangePicker} from 'rsuite';
import {ExportToCsv} from "export-to-csv";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {dateFormat, toBrowserTimeZone} from "../../../shared/utils/utils";
import {toggleLoader} from "../../../shared/actions/setting";
import {useDispatch} from "react-redux";

const PlotIrrigationHistory = (props) => {
  const {t, i18n} = useTranslation();
  const [history, setHistory] = useState([]);
  const [isTable, setTable] = useState(false);
  const [dateRange, setDataRange] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setTable(props.showHistory);
  }, []);

  function exportData() {
    const data = [];
    history.forEach(irrigation => {
      data.push({
        "duration": timeDurationToReadableForm(irrigation.startTime, irrigation.stopTime),
        "startTime ": irrigation.startTime,
        "stopTime ": irrigation.stopTime,
        "mode ": irrigation.mode
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'history',
      useBom: true,
      noDownload: false,
      headers: ["Duration", "Start Time", "End Time", "Mode"],
      filename: "IrrigationHistory",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);
  }


  useEffect(() => {
    if (!props.plotId) {
      return;
    }

    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date(Date.now() - 604800000));
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` +
      getUserId() + `/plot/` + props.plotId + '/irrigation/history' + '?from=' + fromDate + '&to=' + toDate + '&order=' + 'DESC')
      .then(function (response) {
        setHistory(response.data.content);
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      })
  }, [dateRange, props.plotId]);

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);

  }

  useEffect(() => {
    if (!isTable) {
      return
    }
    let elem = document.getElementById("scroll");
    if (elem) {
      elem.scrollIntoView();
    }
  }, [isTable]);


  return (
    <div className="">
      <div className={'sa-table-flex sa-table-col sa-export-row'}>
          <span className={"sa-table-float-left"}>
            <DateRangePicker disabledDate={date => date > new Date()} disabled={false} onChange={(e) => onCheck(e)}
                             oneTap={false} onOk={(e) => onCheck(e)}
                             showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}
                             ranges={[{
                               label: 'Today',
                               value: [new Date(), new Date()]
                             }, {
                               label: 'Yesterday',
                               value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                             }, {
                               label: 'Last 7 days',
                               value: [dateFns.subDays(new Date(), 6), new Date()]
                             }, {
                               label: 'Last 30 days',
                               value: [dateFns.subDays(new Date(), 30), new Date()]
                             }]}
            />
          </span>
        {history.length > 0 &&
          <span className={'sa-table-icon-flex'}>
              <button className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right" onClick={exportData}>
                <FeatherIcon
                  icon={"upload"} className={"sa-table-icon-size"}/> <span
                className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
              </button>
            </span>
        }
      </div>
      <div id={"scroll"} className={"sa-table-container " + (isTable ? "" : "hide-table")}>
        <table className="table table-borderless sa-table-width">
          <thead>
          <tr>
            <th className={"sa-table-head-sticky"}>{t("title.DURATION")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.START_TIME")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.END_TIME")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.MODE")}</th>
          </tr>
          </thead>
          <tbody>
          {history.length > 0 && (history.map((irrigation, index) =>
            <tr key={index}>
              <td
                className={'sa-table-data'}>{timeDurationToReadableForm(irrigation.startTime, irrigation.stopTime)}</td>
              <td className={'sa-table-data'}>{toBrowserTimeZone(irrigation.startTime)}</td>
              <td className={'sa-table-data'}>{toBrowserTimeZone(irrigation.stopTime)}</td>
              <td className={'sa-table-data'}>{irrigation.mode}</td>
            </tr>
          ))}
          </tbody>
        </table>
        {history.length === 0 && (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_IRRIGATION_HISTORIES")}</div>
          </div>)
        }
      </div>
    </div>
  )
};

export default PlotIrrigationHistory
