import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import formHandler from "../../../shared/utils/FormHandler";
import {validateTruckSubmit} from "../../../utils/FormValidationRules";
import file from "../../../images/file-upload.png";
import fileIcon from "../../../images/document.png";
import axios from "axios";
import {isValidDate, uploadImage} from "../../../utils/Utils";
import {toggleLoader} from "../../../shared/actions/setting";
import imageCompression from "browser-image-compression";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {PropagateLoader} from "react-spinners";
import {css} from "@emotion/core";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {FileUploader} from "react-drag-drop-files";
import {dateFormat} from "../../../shared/utils/utils";

export default function AddTrucks(props) {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [profileImg, setProfileImg] = useState({});
  const [bookImg, setBookImg] = useState({});
  const [formErr, setFormErr] = useState(null);
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    width: fit-content;
  `;

  const {
    values,
    errors,
    initForm,
    handleChange,
    handleSubmit,
    handleOnBlur,
    deleteErrors,
    setValue,
  } = formHandler(addFarmer, validateTruckSubmit);

  function addFarmer() {
    setIsLoading(true);
  }

  function resetForm() {
    initForm({});
    deleteErrors(errors);
    setProfileImg({});
    setBookImg({});
  }

  useEffect(() => {
    if (!props.edit) {
      return;
    }
    initForm(props.data);
  }, [props]);

  useEffect(async () => {
    if (!isLoading) {
      return;
    }

    delete values.corporateId;

    if (profileImg.add || bookImg.add) {

      if (profileImg.add) {

        if (profileImg.start) {
          return;
        }

        var options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 96,
          useWebWorker: true,
        };
        dispatch(toggleLoader(true));
        imageCompression(profileImg.file, options)
          .then(async function (compressedFile) {
            // smaller than maxSizeMB
            setProfileImg({...profileImg, start: true});

            let response = await uploadImage(profileImg.imgName, compressedFile);
            values.attachmentOfLicenseThumbnail = response;

          })
          .catch(function (error) {

          });

        let response = await uploadImage(profileImg.imgName, profileImg.file);
        values.attachmentOfLicence = response;
        setProfileImg({...profileImg, add: false, start: true});
        dispatch(toggleLoader(false));
      }
      if (bookImg.add) {
        if (bookImg.start) {
          return;
        }

        dispatch(toggleLoader(true));
        let response = await uploadImage(bookImg.imgName, bookImg.file);
        values.registrationBookImage = response;
        setBookImg({...bookImg, add: false, start: true});
        dispatch(toggleLoader(false));
      }
    } else {
      if (props.edit) {
        dispatch(toggleLoader(true));
        axios.put(
          `${process.env.REACT_APP_HOST}/cco/user/${getUserId()}/corporate/${selectedCorporate}/truck/${props.data.id}`,
          {
            "agreedPrice": values.agreedPrice,
            "attachmentOfLicence": values.attachmentOfLicence,
            "driverName": values.driverName,
            "driverNic": values.driverNic,
            "helperName": values.helperName,
            "helperNic": values.helperNic,
            "loadableCratesCount": 0,
            "truckDimensions": {
              "LENGTH": values.length,
              "WIDTH": values.width,
              "HEIGHT": values.height
            },
            "truckNumber": values.truckNumber,
            "ownerName": values.ownerName,
            "agreementDate": values.agreementDate,
            "registrationBookImage": values.registrationBookImage,
            "quantity": {
              "S": values.quantityLength,
              "M": values.quantityWidth,
              "L": values.quantityHeight
            }
          }
        ).then((res) => {
          toast.success(t("successMsg.FARMER_UPDATED_SUCCESSFULLY"));
          props.onClose();
          resetForm();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.message);
          } else {

            toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
          }
        }).finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
          setIsLoader(false);
          props.reUpdate();
        });
      } else {
        dispatch(toggleLoader(true));
        axios
          .post(
            `${process.env.REACT_APP_HOST}/cco/user/${getUserId()}/corporate/${selectedCorporate}/truck`,
            {
              "agreedPrice": values.agreedPrice,
              "attachmentOfLicence": values.attachmentOfLicence,
              "driverName": values.driverName,
              "driverNic": values.driverNic,
              "helperName": values.helperName,
              "helperNic": values.helperNic,
              "loadableCratesCount": 0,
              "truckDimensions": {
                "LENGTH": values.length,
                "WIDTH": values.width,
                "HEIGHT": values.height
              },
              "truckNumber": values.truckNumber,
              "agreementDate": values.agreementDate,
              "registrationBookImage": values.registrationBookImage,
              "quantity": {
                "S": values.s,
                "M": values.m,
                "L": values.l
              }
            }
          ).then((res) => {
          props.onClose();
          toast.success("Truck added successfully");
          resetForm();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.data.message);
          } else {
            toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
          }
        }).finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
          setIsLoader(false);
          props.reUpdate();
        });
      }
    }
  }, [isLoading, profileImg.add, bookImg.add]);

  function handleOnchangeDate(dateValue, joinDate) {
    let event = {target: {}};
    event.target.name = joinDate;
    event.target.value = dateFormat(dateValue);
    handleChange(event);
  }

  const fileTypes = ["png", "gif", "jpeg", "jpg"];

  function onTypeError(e) {
    toast.error(e)
  }

  return (
    <div className="sa-popup-bg">
      <div className="sa-popup">
        <div className={"sa-modal-bg loader " + (isLoader ? " " : "hide")}>
          <div className="sa-modal-bg-inner">
            <div className="container">
              <div className="sweet-loading">
                <PropagateLoader
                  css={override}
                  size={20}
                  color={"#11bc4d"}
                  loading={true}
                />
              </div>
            </div>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          noValidate
          className={"sa-modal-box-style"}
        >
          <div className="sa-popup-header">
                        <span className={"sa-model-heading"}>
                            {props.edit ? t("title.EDIT_TRUCK") : t("title.ADD_TRUCK")}
                        </span>
            <div className="sa-popup-close-icon" onClick={props.onClose}>
              <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
            </div>
          </div>
          <div className=" ">

            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.TRUCK_NO")}</label>
                  <input
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.truckNumber || ""}
                    type="text"
                    className={`form-control ${errors.truckNumber && "warning-input"
                    }`}
                    name="truckNumber"
                    placeholder={t("placeHolder.ENTER_TRUCK_NO")}
                  /> {errors.truckNumber && (
                  <p className="warning-input-msg ">{errors.truckNumber}</p>
                )}
                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.TRUCK_DIMENSIONS")}</label>
                  <div style={{display: "flex"}}>
                    <input
                      style={{width: "50%", marginRight: "10px"}}
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      value={values.height || ""}
                      type="text"
                      className={`form-control ${errors.height && "warning-input"
                      }`}
                      name="height"
                      placeholder={t("placeHolder.ENTER_HEIGHT")}
                    />
                    <input
                      style={{width: "50%", marginRight: "10px"}}
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      value={values.width || ""}
                      type="text"
                      className={`form-control ${errors.width && "warning-input"
                      }`}
                      name="width"
                      placeholder={t("placeHolder.ENTER_WIDTH")}
                    />
                    {errors.width && (
                      <p className="warning-input-msg ">{errors.width}</p>
                    )}
                    <input
                      style={{width: "50%", marginRight: "10px"}}
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      value={values.length || ""}
                      type="text"
                      className={`form-control ${errors.length && "warning-input"
                      }`}
                      name="length"
                      placeholder={t("placeHolder.ENTER_LENGTH")}
                    />
                    {errors.length && (
                      <p className="warning-input-msg ">{errors.length}</p>
                    )}
                  </div>
                  {errors.truckDimensions && (
                    <p className="warning-input-msg ">{errors.truckDimensions}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.DRIVER_NAME")}</label>
                  <input
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.driverName || ""}
                    type="text"
                    className={`form-control`}
                    name="driverName"
                    placeholder={t("placeHolder.ENTER_DRIVER_NAME")}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.DRIVER_NIC_NUMBER")}</label>

                  <input
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values["driverNic"] || ""}
                    type="text"
                    className={`form-control`}
                    name="driverNic"
                    placeholder={t("placeHolder.ENTER_DRIVER_NIC_NUMBER")}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.HELPER_NAME")}</label>
                  <input
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.helperName || ""}
                    type="text"
                    className={`form-control`}
                    name="helperName"
                    placeholder={t("placeHolder.ENTER_HELPER_NAME")}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.HELPER_NIC_NUMBER")}</label>
                  <input
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.helperNic || ""}
                    type="text"
                    className={`form-control`}
                    name="helperNic"
                    placeholder={t("placeHolder.ENTER_HELPER_NIC_NUMBER")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="farmArea">{t("title.AGREED_PRICE")}</label>
                  <input
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.agreedPrice || ""}
                    type="number"
                    className={`form-control ${errors.farmArea && "warning-input"
                    }`}
                    name="agreedPrice"
                    placeholder={t("placeHolder.ENTER_AGREED_PRICE")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.QUANTITY")}</label>
                  <div style={{display: "flex"}}>
                    <input
                      style={{width: "50%", marginRight: "10px"}}
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      value={values.s || ""}
                      type="text"
                      className={`form-control ${errors.s && "warning-input"
                      }`}
                      name="s"
                      placeholder={t("placeHolder.NO_OF_S_CRATES")}
                    />
                    <input
                      style={{width: "50%", marginRight: "10px"}}
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      value={values.m || ""}
                      type="text"
                      className={`form-control ${errors.m && "warning-input"
                      }`}
                      name="m"
                      placeholder={t("placeHolder.NO_OF_M_CRATES")}
                    />
                    {errors.m && (
                      <p className="warning-input-msg ">{errors.m}</p>
                    )}
                    <input
                      style={{width: "50%", marginRight: "10px"}}
                      onBlur={handleOnBlur}
                      onChange={handleChange}
                      value={values.l || ""}
                      type="text"
                      className={`form-control ${errors.l && "warning-input"
                      }`}
                      name="l"
                      placeholder={t("placeHolder.NO_OF_L_CRATES")}
                    />
                    {errors.length && (
                      <p className="warning-input-msg ">{errors.l}</p>
                    )}
                  </div>
                  {errors.truckDimensions && (
                    <p className="warning-input-msg ">{errors.quantity}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="ownerName">{t("title.OWNERS_NAME")}</label>
                  <input
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.ownerName || ""}
                    type="text"
                    className={`form-control ${errors.ownerName && "warning-input"
                    }`}
                    name="ownerName"
                    placeholder={t("placeHolder.ENTER_OWNERS_NAME")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label>{t("title.AGREEMENT_DATE")}</label>
                  <DatePicker
                    className={`form-control date-picker-align ${errors.agreementDate && "warning-input"}`}
                    onChange={(agreementDate) => handleOnchangeDate(isValidDate(agreementDate) ? agreementDate : new Date(), "agreementDate")}
                    dateFormat={"yyyy-MM-dd"}
                    disabledDate={(date) => date > new Date()}
                    selected={values.agreementDate ? new Date(values.agreementDate) : ""}
                    name={"agreementDate"}
                    placeholderText={t("placeHolder.SELECT_YOUR_AGREEMENT_DATE")}
                    onBlur={handleOnBlur}
                    dropdownMode="select"
                  />
                  {errors.agreementDate && (
                    <p className="warning-input-msg ">{errors.agreementDate}</p>
                  )}
                </div>
              </div>

            </div>


            <div className="col-md-12">
              <div className="form-group m-b-16 p-h-8 d-flex flex-column">
                <label htmlFor="email">{t("title.LARGEST_REVENUE_LICENSE")}</label>
                <FileUploader handleChange={(data) => {
                  setProfileImg({
                    add: true,
                    imgName: data.name,
                    file: data,
                    start: false,
                    disabled: true
                  });
                }} disabled={profileImg.disabled} name="file" types={fileTypes} multiple={false}
                              onTypeError={onTypeError}>

                  <div className={"upload-img-farmer"}>
                    {profileImg.imgName && (
                      <div
                        className="sa-popup-close-icon "
                        style={{transform: "translate(600px,-10px)"}}
                        onClick={() => setProfileImg({})}
                      >
                        <FeatherIcon
                          className={"sa-modal-close-icon"}
                          icon={"x"}
                        />
                      </div>
                    )}

                    <div>
                      <label htmlFor="file-upload-pro">
                        {!profileImg.imgName && (
                          <div
                            style={{cursor: "pointer"}}
                            className={"text-center mt-2"}
                          >
                            <div className={"text-center"}>
                              <img
                                src={file}
                                className={"mr-3 upload-img"}
                                width={"50px"}
                              />
                            </div>
                            <div>
                              {t(
                                "instructions.DRAG_&_DROP_FILE_HERE_TO_UPLOAD_OR"
                              )}
                              <span className={"text-primary"}>
                                                            {" "}
                                {t("title.BROWSE")}
                                                        </span>
                            </div>
                          </div>
                        )}
                        {profileImg.imgName && (
                          <div
                            style={{cursor: "pointer"}}
                            className="table-actions"
                            data-toggle="tooltip"
                            title={profileImg.imgName}
                          >
                            <div
                              className={
                                "text-center mt-2 photo-upload-background"
                              }
                            >
                              <img
                                src={fileIcon}
                                className={"mr-3 upload-img photo-upload-img"}
                                width={"50px"}
                              />
                              <div className={"photo-upload"}>
                                <div className={"photo-upload-name"}>
                                  {" "}
                                  {profileImg.imgName.slice(0, 33) +
                                    (profileImg.imgName.length >= 33
                                      ? "...."
                                      : "")}
                                </div>
                                <div className={"photo-upload-size"}>
                                  {Math.round(profileImg.file.size / 1028) +
                                    "KB"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                </FileUploader>
                <input
                  id="file-upload-pro"
                  type="file"
                  className={"invisible"}
                  onChange={(e) => {

                    if (e.target.files.length > 0) {
                      // setIsLoading(true)
                      setProfileImg({
                        add: true,
                        imgName: e.target.files[0].name,
                        file: e.target.files[0],
                        start: false
                      });
                    }
                  }}
                  name={"file"}
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
            </div>


            <div className="col-md-12">
              <div className="form-group m-b-16 p-h-8 d-flex flex-column">
                <label htmlFor="email">{t("title.REGISTRATION_BOOK")}</label>
                <FileUploader handleChange={(data) => {
                  setBookImg({
                    add: true,
                    imgName: data.name,
                    file: data,
                    start: false,
                    disabled: true
                  });
                }} disabled={bookImg.disabled} name="file" types={fileTypes} multiple={false} onTypeError={onTypeError}>

                  <div className={"upload-img-farmer"}>
                    {bookImg.imgName && (
                      <div
                        className="sa-popup-close-icon "
                        style={{transform: "translate(600px,-10px)"}}
                        onClick={() => setBookImg({})}
                      >
                        <FeatherIcon
                          className={"sa-modal-close-icon"}
                          icon={"x"}
                        />
                      </div>
                    )}

                    <div>
                      <label htmlFor="file-upload-book">
                        {!bookImg.imgName && (
                          <div
                            style={{cursor: "pointer"}}
                            className={"text-center mt-2"}
                          >
                            <div className={"text-center"}>
                              <img
                                src={file}
                                className={"mr-3 upload-img"}
                                width={"50px"}
                              />
                            </div>
                            <div>
                              {t(
                                "instructions.DRAG_&_DROP_FILE_HERE_TO_UPLOAD_OR"
                              )}
                              <span className={"text-primary"}>
                                                            {" "}
                                {t("title.BROWSE")}
                                                        </span>
                            </div>
                          </div>
                        )}
                        {bookImg.imgName && (
                          <div
                            style={{cursor: "pointer"}}
                            className="table-actions"
                            data-toggle="tooltip"
                            title={bookImg.imgName}
                          >
                            <div
                              className={
                                "text-center mt-2 photo-upload-background"
                              }
                            >
                              <img
                                src={fileIcon}
                                className={"mr-3 upload-img photo-upload-img"}
                                width={"50px"}
                              />
                              <div className={"photo-upload"}>
                                <div className={"photo-upload-name"}>
                                  {" "}
                                  {bookImg.imgName.slice(0, 33) +
                                    (bookImg.imgName.length >= 33
                                      ? "...."
                                      : "")}
                                </div>
                                <div className={"photo-upload-size"}>
                                  {Math.round(bookImg.file.size / 1028) +
                                    "KB"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                </FileUploader>
                <input
                  id="file-upload-book"
                  type="file"
                  className={"invisible"}
                  onChange={(e) => {

                    if (e.target.files.length > 0) {
                      // setIsLoading(true)
                      setBookImg({
                        add: true,
                        imgName: e.target.files[0].name,
                        file: e.target.files[0],
                        start: false
                      });
                    }
                  }}
                  name={"file"}
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
            </div>


            <div className="sa-popup-btn">
              {formErr && <div className={"login-warning-msg"}>{formErr}</div>}
              <button
                type={"button"}
                className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                onClick={() =>
                  props.onClose()
                }
              >
                {t("button.CANCEL")}
              </button>
              <button

                id={"scoutingUpdate"}
                className="sa-popup-secondary-btn-style"
              >
                {props.edit ? t("button.UPDATE") : t("button.ADD")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
