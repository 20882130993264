import React, {useEffect, useState} from "react";
import farmImg from '../../../images/farm.jpg';
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";


export default function FarmItemShort(props) {
  const [farm, setFarm] = useState({});

  useEffect(() => {
    if (!props.id) {
      return;
    }

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.id)
      .then(function (response) {
        setFarm(response.data.content);
      })
      .catch(function (error) {

      })
      .finally(() => {
      });
  }, [props.id]);

  return (
    <div className="single-card ma-res-bot sa-cursor" key={props.id}>
      <div className={"single-card-header "}>
        <div className="card-img-view">
          <img src={farmImg} className="card-img" alt="farm"/>
        </div>

        <div hidden={isEmpty(farm)} className="card-header-text limit-charater-farmname" id={'farmText'}
             data-toggle="tooltip">
          <label title={""} className="heading">{farm.name}</label>
          <div className="sub-text">
            <FeatherIcon className={"map-icon"} icon="map-pin"/> {farm.location}
          </div>
        </div>

        <div hidden={!isEmpty(farm)} className="card-header-text limit-charater-farmname" id={'farmText'}
             data-toggle="tooltip">
          <label title={""} className="heading text-red">Farm Not Found</label>
        </div>
      </div>
    </div>
  )
}
