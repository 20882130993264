export default [
  {
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }

]