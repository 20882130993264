import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import {CCO_REPORT_TYPE} from "../../../utils/Enum";
import FeatherIcon from "feather-icons-react";
import dcSummery from "../../../images/dc-summary.svg";
import dailyPayment from "../../../images/daily-payment-summary.svg";
import grn from "../../../images/grn-summary.svg";
import transport from "../../../images/transport.svg";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import ReportGeneratedByCrop from "../../../components/xindicate/xindicate-report-generated-by-crop";
import ReportGeneratedByYield from "../../../components/xindicate/xindicate-report-generated-by-yield";
import ReportGeneratedByStock from "../../../components/xindicate/xindicate-report-generate-by-stock";
import CcoReportGenerateTransporterPaymentSummery from "./cco-report-generate-transporter-payment-summary";
import {useSelector} from "react-redux";

const CcoReportType = () => {
  const {t, i18n} = useTranslation();
  let history = useHistory();
  const [reportType, setReportType] = useState(null);
  const [clickedReport, setClickedReport] = useState(false);

  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  function onChangeReportType() {
    setReportType(null);
    setClickedReport(false);
  }

  function onChangeReportTypeURL(type) {
    if (type === "TRANSPORT_PAYMENT_SUMMARY") {
      history.push("/cco/report/transport-payment");
    } else if (type === "GRN_SUMMARY") {
      history.push("/cco/report/grn-summary");
    } else if (type === "DAILY_PAYMENT_SUMMARY") {
      history.push("/cco/report/daily-payment");
    } else if (type === "DC_RETURN_SUMMARY") {
      history.push("/cco/report/dc-return");
    }
  }

  return (
    <div hidden={!selectedCorporate}>
      <Container
        className={"cash-flow-report-type"}
        // hidden={reportType && clickedReport}
      >
        <div className="single-card m-rl-m-8 p-a-16 text-center">
          <div className="table-container overflowY-scroll">
            <div className="font-weight-bold">
              {t("title.SELECT_THE_REPORT_TYPE_YOU_WANT_TO_CREATE")}
            </div>
            <div className="cash-flow-repot-padding">
              <div className="row justify-content-md-center mt-5 mb-5">
                <div
                  className={
                    reportType === CCO_REPORT_TYPE.TRANSPORT_PAYMENT_SUMMARY
                      ? "col col-lg-2 cash-flow-repot-img-selected"
                      : "col col-lg-2 cash-flow-repot-img"
                  }
                  onClick={() => setReportType(CCO_REPORT_TYPE.TRANSPORT_PAYMENT_SUMMARY)}
                >
                  <a>
                    <img
                      className="cash-flow-repot-img-size transport-img-p"
                      src={transport}
                      height={"120px"}
                      alt="transport"
                    />
                  </a>
                  <p className="m-t-16">{t("title.TRANSPORT_PAYMENT_SUMMARY")}</p>
                </div>
                <div
                  className={
                    reportType === CCO_REPORT_TYPE.GRN_SUMMARY
                      ? "col col-lg-2 cash-flow-repot-img-selected"
                      : "col col-lg-2 cash-flow-repot-img"
                  }
                  onClick={() => setReportType(CCO_REPORT_TYPE.GRN_SUMMARY)}
                >
                  <a>
                    <img
                      className="cash-flow-repot-img-size"
                      src={grn}
                      height={"115px"}
                      alt="grn"
                    />
                  </a>
                  <p className="m-t-16">{t("title.GRN_SUMMARY")}</p>
                </div>
                <div
                  className={
                    reportType === CCO_REPORT_TYPE.DAILY_PAYMENT_SUMMARY
                      ? "col col-lg-2 cash-flow-repot-img-selected"
                      : "col col-lg-2 cash-flow-repot-img"
                  }
                  onClick={() => setReportType(CCO_REPORT_TYPE.DAILY_PAYMENT_SUMMARY)}
                >
                  <a>
                    <img
                      className="cash-flow-repot-img-size"
                      src={dailyPayment}
                      height={"120px"}
                      alt="dailyPayment"
                    />
                  </a>
                  <p className="m-t-16">{t("title.DAILY_PAYMENT_SUMMARY")}</p>
                </div>
                <div
                  className={
                    reportType === CCO_REPORT_TYPE.DC_RETURN_SUMMARY
                      ? "col col-lg-2 cash-flow-repot-img-selected"
                      : "col col-lg-2 cash-flow-repot-img"
                  }
                  onClick={() => setReportType(CCO_REPORT_TYPE.DC_RETURN_SUMMARY)}
                >
                  <a>
                    <img
                      className="cash-flow-repot-img-size"
                      src={dcSummery}
                      height={"120px"}
                      alt="dcSummery"
                    />
                  </a>
                  <p className="m-t-16">{t("title.DC_RETURN_SUMMARY")}</p>
                </div>
              </div>
            </div>
            <button
              className="btn btn-sa-success"
              onClick={() => onChangeReportTypeURL(reportType)}
            >
              {t("title.GENERATE_REPORT")}
            </button>
          </div>
        </div>
      </Container>
      <Container>
        {clickedReport && reportType === CCO_REPORT_TYPE.TRANSPORT_PAYMENT_SUMMARY &&
          <>
            <div className={"my-3 pdf-hide"}>
              <FeatherIcon icon={"arrow-left"} onClick={() => onChangeReportType()}
                           className={"sa-cursor"}/>
            </div>
            <CcoReportGenerateTransporterPaymentSummery onChange={() => onChangeReportType()}/>
          </>
        }
        {clickedReport && reportType === CCO_REPORT_TYPE.GRN_SUMMARY &&
          <>
            <div className={"my-3 pdf-hide"}>
              <FeatherIcon icon={"arrow-left"} onClick={() => onChangeReportType()}
                           className={"sa-cursor"}/>
            </div>
            <ReportGeneratedByCrop/>
          </>
        }
        {clickedReport && reportType === CCO_REPORT_TYPE.DAILY_PAYMENT_SUMMARY &&
          <>
            <div className={"my-3 pdf-hide"}>
              <FeatherIcon icon={"arrow-left"} onClick={() => onChangeReportType()}
                           className={"sa-cursor"}/>
            </div>
            <ReportGeneratedByYield onChange={() => onChangeReportType()}/>

          </>
        }
        {clickedReport && reportType === CCO_REPORT_TYPE.DC_RETURN_SUMMARY &&
          <>
            <div className={"my-3 pdf-hide"}>
              <FeatherIcon icon={"arrow-left"} onClick={() => onChangeReportType()}
                           className={"sa-cursor"}/>
            </div>
            <ReportGeneratedByStock onChange={() => onChangeReportType()}/>

          </>
        }
      </Container>

    </div>
  );
};

export default CcoReportType;
