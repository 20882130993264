export function getAddFarmerFieldsCSV(corporate) {
  if (corporate === "KEELLS") {
    return {
      farmerId: "farmer id",
      farmerName: "farmer name",
      phoneNumber: "phone number 1",
      phoneNumber2: "phone number 2",
      phoneNumber3: "phone number 3",
      nicNo: 'nic no',
      homeAddress: 'home address',
      modeOfDelivery: "mode of delivery",
      usageOfCrates: "usage of crates",
      distanceToCC: 'distance to cc',
      farmerCategory: 'farmer category',
      dateJoinedWithCorporate: 'date joined with corporate',
      gender: "gender",
      district: "district",
      mailAddress: "mail address",
      // certifications:"certifications",        list of objects
      passportOrSsn: "passport or ssn",
      landType: "land type",
      farmArea: "farm area",
      bankName: "bank name",
      bankBranchName: "bank branch name",
      bankAccountNumber: "bank account number",
      bankAccountName: "bank account name",
    };
  }
  return {
    farmerId: "farmer id",
    farmerName: "farmer name",
    firstName: "first name",
    middleName: "middle name",
    lastName: "last name",
    dateOfBirth: "date of birth",
    homeAddress: 'home address',
    gender: "gender",
    contactNo: "contact no",
    nicNo: 'nic no',
    passportOrSsn: "passport or ssn",
    bankName: "bank name",
    bankBranchName: "bank branch name",
    bankAccountNumber: "bank account number",
    bankAccountName: "bank account name",
  };
}

export function getAddHouseholdFieldsCSV(corporate) {
  if (corporate === "KEELLS") {
    return {};
  }
  return {
    addressLine1: "Address Line1",
    addressLine2: "Address Line2",
    city: "city",
    country: "country",
    postalCode: "postal code",
    state: "state",
    bankAccountName: "bank account name",
    bankAccountNo: "bank account no",
    bankName: "bank name",
    dateOfBirth: "date of birth",
    branchName: "branch name",
    certifications: "certifications",
    cropsCultivated: "",
    deceased: true,
    farmArea: "farm area",
    farms: "",
    firstName: "first name",
    gender: "gender",
    homeAddress: "home address",
    houseHoldHead: true,
    FarmerId: "farmer id",
    landType: "land type",
    lastName: "last name",
    mailAddress: "mail address",
    middleName: "middle name",
    NicNo: "nic no",
    passportNo: "passport no",
    contactNo: "contact number",
    profileImageThumbnail: "profile image thumbnail",
  };
}

export function specialCategoryOptionFarmer(corporate) {
  if (corporate === "OXFARM") {
    return [{
      label: "Physically Challenged",
      value: "PHYSICALLY_CHALLENGED"
    }, {label: "Female Headed Household", value: "FEMALE_HEADED_HOUSEHOLD"}];
  }
  return [{
    label: "Physically Challenged",
    value: "PHYSICALLY_CHALLENGED"
  }, {label: "Female Headed Household", value: "FEMALE_HEADED_HOUSEHOLD"}, {
    label: "Soilder",
    value: "SOLDIER"
  }, {label: "War Victim", value: "WAR_VICTIM"}];
}


export function farmType(corporate) {
  if (corporate === "KEELLS") {
    return [{name: "OPEN FIELD", value: "OPEN_FIELD"}, {
      name: "SEMI PROTECTED",
      value: "SEMI_PROTECTED"
    }, {name: "PROTECTED HOUSE", value: "PROTECTED_HOUSE"}, {
      name: "SOILLESS CULTIVATION",
      value: "SOIL_LESS_CULTIVATION"
    }]
  }
  return [{name: "LAND", value: "LAND"}, {name: "HYDROPONIC", value: "HYDROPONIC"}, {
    name: "OPEN FIELD",
    value: "OPEN_FIELD"
  }, {name: "POLYTUNNEL", value: "POLYTUNNEL"}]
}


