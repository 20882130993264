import React, {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import FeatherIcon from "feather-icons-react";
import {formatDisplayEnumValue, isMobileView, toCapsFirst} from "../../../utils/Utils";
import {toggleLoader} from "../../../shared/actions/setting";
import {DateRangePicker} from "rsuite";
import {useParams} from "react-router-dom";
import * as dateFns from "date-fns";
import {ExportToCsv} from "export-to-csv";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../../shared/utils/utils";

const FarmIrrigationModeChangeHistory = (props) => {
  const [dateRange, setDataRange] = useState(null);
  const [isLoadMore, setLoadMore] = useState(true);
  const [changeHistory, setChangeHistory] = useState([]);
  const dispatch = useDispatch();
  const {farmId} = useParams();
  const {t, i18n} = useTranslation();
  const farmSource = useSelector(state => {
    return state.farmList.farmSource;
  });

  function exportData() {
    const data = [];
    changeHistory.forEach(history => {
      data.push({
        "userName": history.user.username,
        "actionAt": history.actionAt,
        "action": toCapsFirst(history.action),
        "source": history.source
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'history',
      useBom: true,
      noDownload: false,
      headers: ["userName", "actionAt", "action", "source"],
      filename: "IrrigationHistory",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);
  }

  useEffect(() => {
    if (!isLoadMore || !farmSource || !farmSource.code) {
      return;
    }
    dispatch(toggleLoader(true));
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date(Date.now() - 604800000));
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/source/' + farmSource.code + '/irrigation/history' + '?from=' + fromDate + '&to=' + toDate + '&order=' + 'DESC')
      .then(function (response) {
        setChangeHistory(response.data.content);
        setLoadMore(false);
        setDataRange(null);
      })
      .catch(function (error) {
        setLoadMore(false);
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [farmSource, isLoadMore]);

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
    setLoadMore(true);
  }

  return (
    <div>
      <div id={"scroll"} className={"plot-report-filter-box h-auto mb-1"}>
        <div className={'sa-table-flex sa-export-row'}>
          <span className={"sa-table-float-left"}>
            <DateRangePicker disabledDate={date => date > new Date()} disabled={false} onChange={(e) => onCheck(e)}
                             oneTap={false} onOk={(e) => onCheck(e)}
                             showOneCalendar={isMobileView()}
                             showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}
                             ranges={[{
                               label: 'Today',
                               value: [new Date(), new Date()]
                             }, {
                               label: 'Yesterday',
                               value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                             }, {
                               label: 'Last 7 days',
                               value: [dateFns.subDays(new Date(), 6), new Date()]
                             }, {
                               label: 'Last 30 days',
                               value: [dateFns.subDays(new Date(), 30), new Date()]
                             }]}
            />
          </span>
          <span className={'sa-table-icon-flex'}>
            <button disabled={changeHistory.length === 0}
                    className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right">
              <FeatherIcon
                icon={"upload"} className={"sa-table-icon-size"}/> <span
              className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
            </button>
          </span>
        </div>
      </div>
      <div className={'sa-table-container'}>
        <table className="table table-borderless sa-table-width">
          <thead>
          <tr>
            <th className={"sa-table-head-sticky"}>{t("title.USERNAME")}</th>

            <th className={"sa-table-head-sticky"}>{t("title.ACTION_AT")}</th>

            <th className={"sa-table-head-sticky"}>{t("title.ACTION")}</th>

            <th className={"sa-table-head-sticky"}>{t("title.SOURCE")}</th>

          </tr>
          </thead>
          <tbody>
          {changeHistory.length > 0 && changeHistory.map((history, index) => (
            <tr key={index}>
              <td className={'sa-table-data'}>{history.user ? history.user.username : "Unknown"}</td>
              <td className={'sa-table-data'}>{history.actionAt}</td>
              <td className={'sa-table-data'}>{formatDisplayEnumValue(history.action)}</td>
              <td className={'sa-table-data'}>{history.source}</td>
            </tr>
          ))}
          </tbody>
        </table>
        {changeHistory.length === 0 && (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_IRRIGATION_MODE_CHANGE_HISTORIES")}</div>
          </div>)
        }
      </div>
    </div>
  );

};

export default FarmIrrigationModeChangeHistory;
