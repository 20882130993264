import React, {useEffect, useState} from "react";
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from "react-redux";
import Container from "react-bootstrap/Container";
import axios from "axios";
import {toggleLoader} from "../../../shared/actions/setting";
import {toast} from "react-toastify";
import {useHistory, useParams} from "react-router-dom";
import FarmerView from "./farmer-view";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const ParticipantQRView = () => {
  const {farmerId} = useParams();
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState({});
  const [print, setPrint] = useState(false);
  let history = useHistory();

  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });


  useEffect(() => {
    dispatch(toggleLoader(true))
    axios.get(process.env.REACT_APP_HOST + '/user/'
      + getUserId() + '/corporate/' + selectedCorporate + '/participant/' + farmerId)
      .then(res => {
        setSelectedData(res.data.content);
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }
    ).finally(() => {
      dispatch(toggleLoader(false))
    })
  }, []);


  function onPrint() {
    setPrint(true)

    setTimeout(function () {
      window.print();
    }, 500);
  }

  window.onafterprint = function () {
    setPrint(false);
  }

  return (
    <div>
      <Container>
        {!isEmpty(selectedData) &&
          <FarmerView onPrint={onPrint} data={selectedData}/>
        }
      </Container>
    </div>
  )

};
export default ParticipantQRView;
