import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {useDispatch, useSelector} from "react-redux";
import FormHandler from "../../../shared/utils/FormHandler";
import {resetConfirmationDialog, toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {toCapsFirst} from "../../../utils/Utils";
import {validatePlotInfo} from "../../../utils/FormValidationRules";
import FeatherIcon from 'feather-icons-react';
import imageCompression from 'browser-image-compression';
import axios from "axios";
import {getSelectedPlot} from "../../../actions/plot";
import {toast} from "react-toastify";
import farmImg from '../../../images/farm.jpg';
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";


const PlotInfo = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const {plotId} = useParams();
  const {farmId} = useParams();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    handleKeyDown,
  } = FormHandler(plotInfo, validatePlotInfo);

  function plotInfo() {
    setIsLoading(true);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId, values)
      .then(function (response) {
        setIsEdit(false);
        dispatch(getSelectedPlot(response.data.content));
        toast.success("                                                                                 formation updated successfully");
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          setError(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setIsLoading(false);
      });
  }, [isLoading]);


  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  useEffect(() => {
    if (!values.id)
      for (let key of Object.keys(selectedPlot)) {
        values[key] = selectedPlot[key];
      }
  });

  function deleteFarm() {
    dispatch(toggleConfirmationDialog({isVisible: true}));
  }

  function onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      imageCompression(imageFile, options)
        .then((compressedFile) => {
        })
        .catch((error) => {

        });
      const reader = new FileReader();
      reader.onload = (e) => {
        values.image = e.target.result;
      };
      reader.readAsDataURL(imageFile);
    }
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId)
      .then(function (response) {
        dispatch(resetConfirmationDialog());
        history.push(`/${farmId}/plot`)

      })
      .catch(function (error) {
        dispatch(resetConfirmationDialog());
      })
  }, [confirmationDialog]);


  function formCancel(e) {
    setIsEdit(false);
    e.preventDefault();
    for (let key of Object.keys(values)) {
      errors[key] = "";
      if (selectedPlot[key]) {
        values[key] = selectedPlot[key];
      } else {
        values[key] = "";
      }
    }
    setError(null);
  }

  // useEffect(() => {
  //     return () => {
  //         if (history.action === "POP")
  //
  //         history.push(`/${farmId}/plot`);
  //     }
  // },[history]);
  function scrollToInfo() {
    let elem = document.getElementById("scroll");
    elem.scrollIntoView();
  }

  return (
    <div className="">
      <Container>
        {!isEdit ?
          <div className="single-card m-rl-m-8 p-a-16">
            <div className="pull-right m-t-26 hide-768">
              <button className="btn btn-sa-icon m-r-40" onClick={deleteFarm}><FeatherIcon
                className={"text-red icon-in-btn"} icon={"trash-2"}/>{t("TITLE.DELETE_PLOT")}</button>
              <button className="btn edit-btn-color btn-sa-secondary w-140p" onClick={() => {
                setIsEdit(true);
                scrollToInfo()
              }}>{t("button.EDIT")}</button>
            </div>
            <div className="card-img-view img-center-768">
              <img className="card-img"
                   src={selectedPlot.imageThumbnail ? selectedPlot.imageThumbnail : selectedPlot.image ? selectedPlot.image : farmImg}
                   alt="farm"/>
            </div>
            <div id="scroll">
              <div className={"farm-info-heading"}>
                <div className={"farm-info-heading-set"}>{t("title.PLOT_INFORMATION")}</div>
              </div>
              <div className={"row farm-info-set"}>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.PLOT_NAME")}</div>
                  <div className={"farm-info-data"}>{selectedPlot.name ? toCapsFirst(selectedPlot.name) : "NA"}</div>
                </div>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.CROP")}</div>
                  <div className={"farm-info-data"}>{selectedPlot.crop ? selectedPlot.crop : "NA"}</div>
                </div>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.AREA")}</div>
                  <div className={"farm-info-data"}>{selectedPlot.area ? selectedPlot.area : "NA"}</div>
                </div>
              </div>
            </div>
            <div className="m-t-26 show-768">
              <button className="btn btn-sa-secondary w-100pe" onClick={() => {
                setIsEdit(true);
                scrollToInfo()
              }}>Edit
              </button>
            </div>
          </div>
          :
          <div className="single-card m-rl-m-8 p-a-16">
            <form onSubmit={handleSubmit} noValidate>
              <div className="pull-right m-t-26 hide-768">
                <button className="btn btn-sa-secondary w-140p m-r-8"
                        onClick={(e) => formCancel(e)}>{t("button.CANCEL")}</button>
                <button className="btn btn-sa-secondary w-140p">{t("button.SAVE")}</button>
              </div>
              <div className="card-img-view img-center-768">
                <img className="card-img"
                     src={selectedPlot.imageThumbnail ? selectedPlot.imageThumbnail : selectedPlot.image ? selectedPlot.image : farmImg}
                     alt="farm"/>
              </div>
              <div className={"farm-info-heading"}>
                <div className={"farm-info-heading-set"}>{t("title.PLOT_INFORMATION")}</div>
              </div>
              <div className={"row farm-info-set"}>
                <div className={"col-md-6 col-lg-3 m-b-30-info"}>
                  <div className={"farm-info-title"}>{t("title.PLOT_NAME")}</div>
                  <input type="text" className={errors.name ? "form-control warning-input" : "form-control"}
                         value={values.name} onChange={handleChange} name="name"
                         onBlur={handleOnBlur} placeholder={t("title.PLOT_NAME")}/>
                  {errors.name && (
                    <p className="warning-input-msg">{errors.name}</p>)}
                </div>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.CROP")}</div>
                  <input type="text" className={"form-control"} onChange={handleChange} value={values.crop} name="crop"
                         onBlur={handleOnBlur} placeholder={t("title.CROP")}/>
                </div>
                <div className={"col-md-6 col-lg-3"}>
                  <div className={"farm-info-title"}>{t("title.AREA")}</div>
                  <input type="number" className={errors.area ? "form-control warning-input" : "form-control"}
                         onChange={handleChange} value={values.area} name="area"
                         onBlur={handleOnBlur} onKeyDown={handleKeyDown} placeholder={t("title.AREA")}/>
                  {errors.area && (
                    <p className="warning-input-msg">{errors.area}</p>)}
                </div>
              </div>
              {error && (<p>{error}</p>)}
              <div className="m-t-26 show-768">
                <button className="btn btn-sa-secondary w-100pe m-b-16">{t("button.SAVE")}</button>
                <button className="btn btn-sa-secondary w-100pe"
                        onClick={(e) => formCancel(e)}>{t("button.CANCEL")}</button>
              </div>
            </form>
          </div>
        }
      </Container>
    </div>
  )
};


export default PlotInfo;

