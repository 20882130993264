import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {toCapsFirst} from "../../../utils/Utils";
import PlotTab from "./plot-tab";
import Container from "react-bootstrap/Container";
import {getPlotList, getSelectedPlot} from "../../../actions/plot";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {getSelectedFarm} from "../../../actions/farm";
import {changeView, toggleLoader} from "../../../shared/actions/setting";
import {VIEW} from "../../../utils/Enum";
import FeatherIcon from "feather-icons-react";
import {getSelectedSensor} from "../../../actions/sensor";
import {useTranslation} from "react-i18next";
import {Img} from "react-image";
import farmImg from "../../../images/farm.jpg"
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import PlotStatus from "../../plot-status";

const PlotHeader = (props) => {
  const {t, i18n} = useTranslation();
  const {plotId, farmId} = useParams();
  const dispatch = useDispatch();
  let history = useHistory();
  const [activeCount, setActiveCount] = useState(0);

  const plotList = useSelector(state => {
    return state.plotList.plotList
  });

  const plotIrrigationStatus = useSelector(state => {
    return state.plotList.plotIrrigationStatus
  });

  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });
  useEffect(() => {
    if (plotList.length !== 0) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/plot')
      .then(function (response) {
        dispatch(getPlotList(response.data.content));
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [plotList, farmId]);

  useEffect(() => {
    if (!selectedPlot.id) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/reference/` + selectedPlot.id + `/irrigation`)
      .then(function (response) {
        if (response.data.content) {

          let list = response.data.content;
          let count = list.filter(e => e.status === "ON").length;
          setActiveCount(count);
        }
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [selectedPlot.id]);

  useEffect(() => {
    if (!isEmpty(selectedFarm)) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId)
      .then(function (response) {
        dispatch(getSelectedFarm(response.data.content));
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [selectedFarm, farmId]);

  useEffect(() => {
    dispatch(changeView(VIEW.SENSOR_LIST));
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId)
      .then(function (response) {
        dispatch(getSelectedPlot(response.data.content));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [farmId, plotId]);

  // useEffect(() => {
  //   dispatch(toggleLoader(true));
  //   axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId + `/irrigation/status`)
  //     .then(function (response) {
  //       if (response.data.content) {
  //         dispatch(getPlotIrrigationStatus(response.data.content));
  //       }
  //     })
  //     .catch(function (error) {
  //     })
  //     .finally(() => {
  //       dispatch(toggleLoader(false));
  //     });
  //
  // }, [farmId, plotId]);

  useEffect(() => {
    if (isEmpty(selectedPlot)) {
      return;
    }
    if (plotId !== selectedPlot.id) {
      history.push('/' + farmId + '/plot/' + selectedPlot.id + '/sensor');
    }
    dispatch(getSelectedSensor(null));
  }, [selectedPlot]);

  // const client = new Paho.Client("mqtt.senzmate.com", 8000, 'qwerty12345'+new Date().getTime());
  // client.onConnectionLost = onConnectionLost;
  // client.onMessageArrived = onMessageArrived;
  //
  // useEffect(()=>{
  //   if(!client){
  //     return
  //   }
  //   client.connect({onSuccess:onConnect,mqttVersionExplicit:true,mqttVersion:3});
  // },[]);
  //
  // function onConnect() {
  //   if (client.isConnected()) {
  //     client.unsubscribe(`S2F/SA/V1/plot/#`);
  //     client.unsubscribe(`S2F/SA/V1/farm/#`);
  //     client.subscribe(`S2F/SA/V1/plot/` + plotId + "/#");
  //   }
  // }
  //
  // function onConnectionLost(responseObject) {
  //   if (responseObject.errorCode !== 0) {
  //     client.connect({onSuccess:onConnect,mqttVersionExplicit:true,mqttVersion:3});
  //   }
  // }
  //
  // function onMessageArrived(message) {
  //   if(message.payloadString){
  //         let mqttPlotIrrigation=JSON.parse(message.payloadString);
  //         dispatch(getPlotIrrigationStatus(mqttPlotIrrigation));
  //         mqttPlotIrrigation.plotId=plotId;
  //         dispatch(getMQTTStatusPlot(mqttPlotIrrigation));
  //   }
  // }

  return (
    <Container>
      <div className="single-card m-rl-m-8">
        <div className="single-card-items hide-768">
          <div className="card-img-view">
            <Img className="card-img"
                 src={[selectedPlot.imageThumbnail ? selectedPlot.imageThumbnail : selectedPlot.image, farmImg]}
                 alt="plot"/>
            {/*<img className="card-img" src={selectedPlot.imageThumbnail ? selectedPlot.imageThumbnail : selectedPlot.image} alt="plot" />*/}
          </div>
          <div className="card-header-text m-r-90">
            <div className="heading">{selectedPlot.name ? toCapsFirst(selectedPlot.name) : "NA"}</div>
            <div className="sub-text m-t-5">
              <FeatherIcon className={"map-icon"}
                           icon="map-pin"/> {selectedPlot.location ? selectedPlot.location : selectedFarm.location}
            </div>
          </div>
          <div className="dis-in-bl m-t-5 dis-in-bl text-center  mx-lg-auto">

            <div className="dis-in-bl m-rl-8-15">{t("title.IRRIGATION_STATUS")}</div>
            <div className="text-dark-gray w-124 text-center dis-in-bl card-content-status"
                 title={plotIrrigationStatus.current}>
                   <span className="font-weight-bold">
                          {activeCount} </span><span
              className="outOf">motor</span> ON
            </div>
            <div className="dis-in-bl">
              <div className=" dis-in-bl m-rl-8-15">{t("title.PLOT_STATUS")}</div>
              <div className=" text-gray w-124 text-center dis-in-bl">
                <PlotStatus kitId={selectedPlot.kitId}/>
              </div>
            </div>
          </div>
        </div>
        <PlotTab plotId={plotId} farmId={farmId}/>
      </div>
    </Container>
  )
};


export default PlotHeader
