import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import FeatherIcon from 'feather-icons-react';
import Container from "react-bootstrap/Container";
import {BeatLoader} from "react-spinners";
import {css} from "@emotion/core";
import axios from "axios";
import 'c3/c3.css';
import * as _ from "underscore";
import {toggleLoader} from "../../../shared/actions/setting";
import {DateRangePicker} from 'rsuite';
import C3Chart from 'react-c3js';
import 'rsuite/dist/styles/rsuite-default.css'
import * as dateFns from "date-fns";
import 'rsuite/lib/styles/index.less';
import {ExportToCsv} from 'export-to-csv';
import {batteryReading} from "../../../utils/Utils";

import * as d3 from "d3";
import {useTranslation} from "react-i18next";
import {dateFormat, toBrowserTimeZone} from "../../../shared/utils/utils";


const DeviceBatteryReport = (props) => {


  const [deviceBatteryNum, setDeviceBatteryNum] = useState();
  const [dataSet, setDataSet] = useState({});
  const [dateRange, setDataRange] = useState(null);
  const [loadGraph, setLoadGraph] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [valuesX, setValuesX] = useState([]);
  const [valuesY, setValuesY] = useState([]);
  const {t, i18n} = useTranslation();


  const override = css`
    display: block;
    margin: 0 auto ;
    padding-bottom: 210px;
    border-color: red;
    width:fit-content;
  `;

  const dispatch = useDispatch();

  const style1 = {borderBottom: "solid 2.5px #2877B4"};
  const style2 = {borderBottom: "solid 2.5px #F67F0F"};


  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);

  }


  function styleGraph() {
    if (window.innerWidth < 769) {

      d3.select(".c3-axis-x-label").attr("dy", "42px");
      d3.selectAll(".tick").style("font-size", "7px");
      d3.select(".c3-axis-y-label").attr("dy", "-13px");

    }
    d3.select(".c3-axis-y-label").attr("dy", "-25px");
    d3.selectAll(".c3-legend-item").attr("x", "400");

  }

  function exportData() {
    let data = [];
    for (let i = 0; i < valuesX.length; i++) {
      data.push({
        "time": valuesX[i],
        "value": valuesY[i]
      })
    }

    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: ['Date', "Battery%"],
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);

  }

  useEffect(() => {
    if (!props.device.kit.id) {
      return
    }
    setIsLoading(true);
    axios.get(process.env.REACT_APP_HOST + `/core/device/` + props.device.kit.devices[0])

      .then(function (response) {
        const batteryNumber = _.findWhere(response.data.content.sensors, {code: "B"}).number;
        setDeviceBatteryNum(batteryNumber);

        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        setIsLoading(false);
      })
      .finally(() => {
      });

  }, [props.device.kit.id]);


  useEffect(() => {

    if (!props.device.kit.id || !deviceBatteryNum) {
      return;
    }
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    setIsLoading(true);
    // if(deviceBatteryNum!==null)
    axios.get(process.env.REACT_APP_HOST + `/core` + `/device/` + props.device.kit.devices[0] + '/sensor/' + deviceBatteryNum + '?from=' + fromDate + '&to=' + toDate + '&order=DESC')
      .then(function (response) {


        if (response.data.content.length > 0) {
          valuesX.splice(0, valuesX.length);
          valuesY.splice(0, valuesY.length);
          response.data.content.forEach(element => {
            valuesY.push(batteryReading(element.value, props.device.kit.battery.low, props.device.kit.battery.high));
            valuesX.push(toBrowserTimeZone(element.time));
          });
          setValuesX([...valuesX]);
          setValuesY([...valuesY]);
        } else if (response.data.content.length === 0) {
          setValuesX([]);
          setValuesY([]);
        }
        drawGraph(valuesX, valuesY);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {


        }
        setIsLoading(false);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [dateRange, deviceBatteryNum]);

  async function addDataGraphDate(graphData) {
    await new Promise((resolve, reject) => {
      resolve(1);
      setDataSet(graphData)
    });

  }

  function drawGraph(valuesX, valuesY) {


    const graphData = {};
    if (valuesX || valuesY) {
      let noValue1 = false;
      if (!valuesX || !valuesY) {
        noValue1 = true;
      }
      const data = {
        x: 'x',
        xFormat: '%Y-%m-%d %H:%M:%S',
        columns: [
          ['x'].concat(noValue1 ? [] : valuesX),
          ["Battery%"].concat(noValue1 ? [] : valuesY)
        ]
      };

      const axis = {
        x: {
          show: true,
          type: 'timeseries',
          tick: {
            multiline: true,
            format: '%Y-%m-%d %H:%M',
            count: 8,
            fit: true,
            rotate: window.innerWidth > 576 ? 0 : 9,
            culling: {
              max: window.innerWidth > 480 ? 10 : 5,
            },
            height: 100,

          },
          label: {
            text: 'Date Time',
            position: 'outer-center',
          }
        },
        y: {
          label: {
            text: "Battery%",
            position: 'outer-middle'
          }
        },
      };
      graphData['data'] = data;
      graphData['axis'] = axis;
    }
    addDataGraphDate(graphData).then(() => {
      setLoadGraph(true);
      let elem = document.getElementById("scroll");
      if (elem) {
        elem.scrollIntoView();
      }
    });
  }

  return (
    <div className={"sa-popup-bg "}>
      <div className="sa-popup">
        <div className="sa-modal-box-style">
          <div className="sa-popup-header">
            <span className={"sa-model-heading"}>{"Battery " + props.device.kit.battery.reading + "%"}</span>
            <div className="sa-popup-close-icon" onClick={() => props.onClose()}>
              <FeatherIcon
                className={"sa-modal-close-icon"} icon={"x"}/>
            </div>
          </div>
          <div className="sa-popup-content">
            <Container>
              <div hidden={!isLoading} className="container m-t-200">
                <div className="sweet-loading">
                  <BeatLoader
                    css={override}
                    size={18}
                    color={"#11bc4d"}
                    loading={true}
                  />
                </div>
              </div>
              <div hidden={isLoading} className="single-card">
                {!props.device.kit.id &&
                  <div className={"empty-results"}>
                    <FeatherIcon icon="info"/>
                    <div className={"empty-results-text"}>{t("info.KIT_NOT_CONFIGURED")}</div>
                  </div>}
                {/* <div className={""}>
                                        {selectedKit.id && selectedKit.properties.map((property) => (
                                            <div
                                                style={(selectedIndexes.length >= 1 && selectedIndexes[0] === property.number) && style1 ||
                                                    (selectedIndexes.length >= 1 && selectedIndexes[1] === property.number) && style2
                                                    || null}
                                                key={property.number} className={"r-w-50 plot-report-tabs"}
                                                onClick={() => selectTab(property.number)}>
                                                <div className="plot-report-tabs-title limit-plot-report-N">{property.displayName === "Temp" ? "Temperature" : property.displayName}</div>
                                                <div className="plot-report-tabs-value limit-plot-report-V">{property.displayValue}</div>
                                            </div>
                                        ))
                                        }
                                    </div> */}
                <div>
                  <div hidden={!props.device.kit.id} className={"plot-report-filter-box"}>
                    <div className={"plot-report-filter"}>
                      <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                                       onChange={(e) => onCheck(e)}
                                       oneTap={false} onOk={(e) => onCheck(e)}
                                       showWeekNumbers={true} appearance={"default"} placeholder={t("title.TODAY")}
                                       ranges={[{
                                         label: 'Today',
                                         value: [new Date(), new Date()]
                                       }, {
                                         label: 'Yesterday',
                                         value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                                       }, {
                                         label: 'Last 7 days',
                                         value: [dateFns.subDays(new Date(), 6), new Date()]
                                       }, {
                                         label: 'Last 30 days',
                                         value: [dateFns.subDays(new Date(), 30), new Date()]
                                       }]}
                      />
                    </div>
                    <div className={"plot-report-filter-right"}>
                      <button className="btn btn-sa-report m-b-6 w-140p"
                              onClick={() => exportData()}> {t("button.EXPORT_CSV")}
                      </button>
                    </div>
                  </div>
                  <div id={"scroll"} style={{marginLeft: "-26px"}}>
                    {props.device.kit.id && loadGraph && valuesX.length > 0 && (
                      <C3Chart data={dataSet.data} subchart={{show: true}} axis={dataSet.axis}
                               onrendered={styleGraph}/>)}
                  </div>
                  {!valuesX.length && (<div className={"empty-results"}>
                    <FeatherIcon icon="alert-triangle"/>
                    <div className={"empty-results-text"}>{t("emptyMsg.NO_DATA_AVAILABLE")}</div>
                  </div>)}
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceBatteryReport;
