import React, {useEffect, useState} from "react";
import axios from "axios";
import * as _ from "underscore";
import {minsToDaysHoursMins} from "../../../utils/Utils";
import {toggleLoader} from "../../../shared/actions/setting";
import C3Chart from 'react-c3js';
import * as d3 from "d3";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {BeatLoader} from "react-spinners";
import {css} from "@emotion/core";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const FarmIrrigationGraph = (props) => {

  const dispatch = useDispatch();
  const {farmId} = useParams();
  const [aggregatedHistory, setAggregatedHistory] = useState([]);
  const [farmDetails, setFarmDetails] = useState([]);
  const [dataSet, setDataSet] = useState({});
  const [loadGraph, setLoadGraph] = useState(false);


  const override = css`
    display: block;
    margin: 0 auto;
    padding-bottom: 150px;
    padding-top: 150px;
    border-color: red;
    width: fit-content;
  `;


  async function addDataGraphDate(graphData) {
    await new Promise((resolve, reject) => {
      resolve(1);
      setDataSet(graphData)
    });
  }

  function styleGraph() {
    if (window.innerWidth < 769) {

      d3.select(".c3-axis-x-label").attr("dy", "42px");
      d3.selectAll(".tick").style("font-size", "7px");
      d3.select(".c3-axis-y-label").attr("dy", "-34px");
    } else {
      d3.select(".c3-axis-y-label").attr("dy", "-36px");
    }
    d3.selectAll(".c3-legend-item").attr("x", "400");

  }


  useEffect(() => {
    if (dataSet.length) {
      dataSet.slice(0, dataSet.length);
    }
    // if (farmDetails.length == 0) {
    //     return;
    // }

    setLoadGraph(false);
    drawGraph();
  }, [props.isGraph, props.isPeriod, aggregatedHistory]);

  useEffect(() => {
    if (!props.fromDate) {
      return
    }

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/irrigation/summary' + '?from=' + props.fromDate + '-01')
      .then(function (response) {
        setFarmDetails(response.data.content);
      })
      .catch(function (error) {

      })
      .finally(() => {
      })
  }, [props.fromDate]);


  useEffect(() => {
    if (!props.fromDate) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/irrigation/aggregated-history' + '?date=' + (props.isPeriod ? (props.annual + '-01-01') : (props.fromDate + '-01')) + '&type=' + (props.isPeriod ? 'MONTHLY' : 'DAILY'))
      .then(function (response) {

        setLoadGraph(false);
        setAggregatedHistory(response.data.content);
      })
      .catch(function (error) {

      })
      .finally(() => {
        dispatch(toggleLoader(false));
      })
  }, [props.fromDate, props.isPeriod]);

  function drawGraph() {

    const durationCurrentAggregated = _.pluck(aggregatedHistory, (props.isPeriod ? 'longDuration' : 'duration'));
    const date = _.pluck(aggregatedHistory, 'date');
    const dateSliced = [];
    date.map(d => {
      dateSliced.push(d.slice(0, 10));
    });
    const graphData = {};
    const tooltip = {
      format: {
        value: function (value, ratio, id, index) {

          return minsToDaysHoursMins(value);
        }
      },
    };
    const data = {
      xs: {
        'Duration': 'Date',
      },
      xFormat: '%Y-%m-%d',
      columns: [
        ['Duration'].concat(durationCurrentAggregated),
        ['Date'].concat(dateSliced),
      ],
      type: 'spline',
    };
    const axis = {
      x: {
        show: true,
        type: 'timeseries',
        tick: {
          multiline: true,
          format: props.isPeriod ? '%Y-%m' : '%Y-%m-%d',
          count: props.isPeriod ? 12 : 31,
          fit: true,
          rotate: window.innerWidth > 767 ? 0 : 9,
          culling: {
            max: window.innerWidth > 767 ? 6 : 5,
          },
          height: 100,


        },
        label: {
          text: 'Months',
          position: 'outer-center',
        },

      },
      y: {
        label: {
          text: 'Duration(min)',
          position: 'outer-middle',

        }
      },
    };
    const zoom = {
      rescale: true

    };

    graphData['data'] = data;
    graphData['axis'] = axis;
    graphData['tooltip'] = tooltip;
    graphData['zoom'] = zoom;
    addDataGraphDate(graphData).then(() => {
      setLoadGraph(true);

    });
  }

  return (
    <div>
      {/*<div className="table-container mt-0" hidden={!props.isGraph}>*/}
      <div hidden={loadGraph}>
        <div className="sweet-loading">
          <BeatLoader
            css={override}
            size={18}
            color={"#11bc4d"}
            loading={true}
          />
        </div>
      </div>
      {props.isGraph && loadGraph && dataSet.data && (
        <C3Chart area={{zerobased: false}} padding={{left: 45}} tooltip={dataSet.tooltip} zoom={dataSet.zoom}
                 data={dataSet.data} subchart={{show: props.farmReport}} onrendered={styleGraph} axis={dataSet.axis}/>
      )}
      {/*</div>*/}
    </div>
  )
}
export default FarmIrrigationGraph
