import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import {useParams} from "react-router-dom";
import {IRRIGATION_MODE} from "../../../utils/Enum";
import axios from "axios";
import PlotIrrigationHistory from "./plot-irrigation-history";
import {useDispatch, useSelector} from "react-redux";
import {toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import ToggleLayout from "../../toggle-layout";
import PlotIrrigationSetting from "./plot-irrigation-setting";
import {useTranslation} from 'react-i18next';
import {getUserId} from "../../../shared/utils/SharedAuthentication";


const PlotIrrigation = () => {
  const {plotId} = useParams();
  const {farmId} = useParams();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [irrigation, setIrrigation] = useState(null);
  const [isLoadMode, setIsLoadMode] = useState(true);
  const [buttonHide, setButtonHide] = useState(true);
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(0);


  const plotMqttStatus = useSelector(state => {
    return state.plotList.plotMqtt;
  });

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  useEffect(() => {
    if (!isLoadMode) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId + '/irrigation')
      .then(function (response) {
        setIrrigation(response.data.content ? response.data.content : {});
        if (!response.data.content || !response.data.content.mode || !response.data.content.valveCode || [IRRIGATION_MODE.DAILY, IRRIGATION_MODE.MANUAL].includes(response.data.content.mode)) {
          setButtonHide(true);
        } else {
          setButtonHide(false);
        }
        setIsLoadMode(false);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      })
  }, [isLoadMode]);


  useEffect(() => {
    if (!plotMqttStatus || !plotMqttStatus.mode || isLoadMode) {
      return;
    }
    if (irrigation && plotMqttStatus.mode !== irrigation.mode) {
      dispatch(toggleConfirmationDialog({
        isVisible: true,
        type: "request",
        confirmationHeading: t("confirmation_msg.IRRIGATION_MODE_CHANGED"),
        confirmationDescription: t("confirmation_msg.SOMEONE_HAS_CHANGED_IRRIGATION_MODE_OF_THIS_PLOT_PLEASE_REFRESH_THE_PAGE_TO_GET_THE_UPDATE")
      }));
    }
  }, [plotMqttStatus]);

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    setIsLoadMode(true);
  }, [confirmationDialog]);


  return (
    <Container>
      <ToggleLayout imgType={"feather"} feather={"droplet"} featherclass={"icon-color-blue"}
                    title={t("title.IRRIGATION_CONTROL")} visibleToggleIndex={visibleToggleIndex} toggleIndex={1}
                    onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
        <PlotIrrigationSetting isPlotSettings={true} plotId={plotId}/>
      </ToggleLayout>
      <ToggleLayout imgType={"feather"} feather={"file"} title={t("title.IRRIGATION_HISTORY")}
                    visibleToggleIndex={visibleToggleIndex} toggleIndex={2}
                    onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
        <PlotIrrigationHistory plotId={plotId} showHistory={true}/>
      </ToggleLayout>
    </Container>
  )
};

export default PlotIrrigation
