import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../../shared/actions/setting";
import axios from "axios";
import {useHistory} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {ExportToCsv} from "export-to-csv";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {DateRangePicker} from 'rsuite';
import * as dateFns from "date-fns";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../../shared/utils/utils";

const CcoReportGenerateTransporterPaymentSummary = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [paymentList, setPaymentList] = useState([]);
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date())
  });

  function exportData() {
    const data = [];
    paymentList.forEach(item => {
      data.push({
        "Truck No ": item.truckNumber,
        "Price ": item.agreedPrice,
        "Amount Payable ": item.agreedPrice,
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'TransportPayment',
      useBom: true,
      noDownload: false,
      headers: ["Truck No", "Price", "Amount Payable"],
      filename: "TransportPaymentSummery",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);

  }

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }


  useEffect(() => {
    if (!selectedCorporate) {
      return;
    }
    const todayDate = dateFormat(new Date());
    let fromDate = dateRange ? dateRange.fromDate : todayDate;
    let toDate = dateRange ? dateRange.toDate : todayDate
    dispatch(toggleLoader(true));
    axios
      .get(
        process.env.REACT_APP_HOST + `/cco/user/` + getUserId() + '/corporate/' + selectedCorporate + `/issueNote/summary?from=` + fromDate + '&granularity=DAY&to=' + toDate
      )
      .then((res) => {
        setPaymentList(res.data.content);
      })
      .catch(function (error) {

      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [dateRange, selectedCorporate]);

  function backToReport() {
    history.push("/cco/cco-report");
  }

  return (
    <div hidden={!selectedCorporate}>
      <Container>
        <div className={"my-3 pdf-hide"}>
          <FeatherIcon icon={"arrow-left"}
                       onClick={() => backToReport()}
                       className={"sa-cursor"}/>
        </div>
        <div className="single-card m-rl-m-8 p-a-16">
                <span>
                            <span className={"sa-table-float-left"}>
                                <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                                                 onChange={(e) => onCheck(e)}
                                                 oneTap={false} onOk={(e) => onCheck(e)}
                                                 showWeekNumbers={true} appearance={"default"}
                                                 placeholder={t("title.LAST_WEEK")}
                                                 ranges={[{
                                                   label: 'Today',
                                                   value: [new Date(), new Date()]
                                                 }, {
                                                   label: 'Yesterday',
                                                   value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                                                 }, {
                                                   label: 'Last 7 days',
                                                   value: [dateFns.subDays(new Date(), 6), new Date()]
                                                 }, {
                                                   label: 'Last 30 days',
                                                   value: [dateFns.subDays(new Date(), 30), new Date()]
                                                 }]}
                                />
                            </span>
                        <div className="sa-table-report-filter-right">
                            <button disabled={paymentList.length === 0}
                                    onClick={exportData}
                                    className="sa-table-btn-secondary sa-table-btn-mute"><FeatherIcon
                              className={"sa-table-icon-size"} icon={"upload"} onClick={exportData}/> <span
                              className={"sa-table-icon"}>{t("button.EXPORT_CSV")}
                                </span></button>
                        </div>
                        </span>
          <div className="sa-table-container xindicate-report-calc-height m-t-1">
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.TRUCK_NO")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.DATE")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.PRICE")}</th>
              </tr>
              </thead>
              <tbody>
              {paymentList.length > 0 && paymentList.map(item =>
                <tr key={item.id}>
                  <td className={'sa-table-data'}>{item.truckNo}</td>
                  <td className={'sa-table-data'}>{item.dateString}</td>
                  <td className={'sa-table-data'}>{item.amountTotal}</td>
                </tr>)}
              </tbody>
            </table>
            {paymentList.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.TRANSPORT_PAYMENT")}</div>
              </div>)
            }
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CcoReportGenerateTransporterPaymentSummary;
