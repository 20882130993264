import React, {useEffect, useState} from 'react';
import {DateRangePicker} from "rsuite";
import * as dateFns from "date-fns";

import {formatDisplayEnumValue} from "../../../utils/Utils";
import FeatherIcon from "feather-icons-react";
import {dateFormat, toBrowserTimeZone} from "../../../shared/utils/utils";
import {useTranslation} from "react-i18next";
import {toggleLoader} from "../../../shared/actions/setting";
import axios from "axios";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {useDispatch} from "react-redux";

function AutomationHistoryTable(props) {

  const [automationHistory, setAutomationHistory] = useState([]);
  const [isTable, setTable] = useState(false);
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date()),
  });

  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();


  useEffect(() => {
    if (!dateRange) {
      return
    }
    dispatch(toggleLoader(true));
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date(Date.now() - 604800000));
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/irrigation/` + props.irrigationId + '/automation/history?from=' + fromDate + '&to=' + toDate)
      .then(function (response) {
        setAutomationHistory(response.data.content);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [dateRange, props.plotId, props.updateData]);

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);

  }

  return (
    <div>
      <div>
        <div id={"scroll1"} className={"table-container-toggle " + (isTable ? "" : "table-toggle-hide")}>
          <hr className="table-toggle-line"/>
          <div className="table-toggle-heading">Automation Mode Change History</div>
          <div onClick={() => setTable(!isTable)} className="table-dropdown-icon"><i
            className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")}/></div>
        </div>
        <div className={"mt-5 pt-2 " + (isTable ? "" : "hide-table")}>
          <div className={"plot-report-filter-box h-auto"}>
            <div className={"plot-report-filter mb-3"}>
              <DateRangePicker disabledDate={date => date > new Date()} disabled={false} onChange={(e) => onCheck(e)}
                               oneTap={false} onOk={(e) => onCheck(e)}
                               showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}
                               ranges={[{
                                 label: 'Today',
                                 value: [new Date(), new Date()]
                               }, {
                                 label: 'Yesterday',
                                 value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                               }, {
                                 label: 'Last 7 days',
                                 value: [dateFns.subDays(new Date(), 6), new Date()]
                               }, {
                                 label: 'Last 30 days',
                                 value: [dateFns.subDays(new Date(), 30), new Date()]
                               }]}
              />
            </div>
          </div>
          <div className="sa-table-container mh-420">
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.USERS")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.ACTION")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.CHANGES")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.DATE_AND_TIME")}</th>
              </tr>
              </thead>
              <tbody>
              {automationHistory.length > 0 && (automationHistory.map((history, index) =>
                <tr key={index}>
                  <td className={"sa-table-data"}>{history.user ? history.user.username : 'Unknown'}</td>
                  <td className={"sa-table-data"}>
                    {formatDisplayEnumValue(history.action)}
                  </td>
                  <td className={"sa-table-data"}>{history.info}</td>
                  <td className={"sa-table-data"}>{toBrowserTimeZone(history.actionAt)}</td>
                </tr>
              ))}
              </tbody>
            </table>
            {automationHistory.length === 0 && (
              <div tyle={{marginLeft: '50%'}} className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_AUTOMATION_HISTORIES")}</div>
              </div>)
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutomationHistoryTable;