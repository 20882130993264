import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../shared/actions/setting";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {DateRangePicker} from "rsuite";
import {formatDisplayEnumValue} from "../../utils/Utils";
import * as dateFns from "date-fns";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import FilterHandler from "../../shared/utils/FilterHandler";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../shared/utils/utils";

export default function FarmAlertLog(props) {

  const [alertLog, setAlertLog] = useState([]);
  const {farmId} = useParams();
  const dispatch = useDispatch();
  const [alertListAll, setAlertListAll] = useState([]);
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date())
  });
  const {t, i18n} = useTranslation();
  const {
    handleFilter,
    filteredList,
    filters
  } = FilterHandler(setFilteredList, alertListAll)

  function setFilteredList() {
    setAlertLog(filteredList)
  }

  useEffect(() => {
    if (!dateRange) {
      return
    }

    dispatch(toggleLoader(true))
    axios.get(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/farm/' + farmId + '/alert-log?from=' + dateRange.fromDate + '&to=' + dateRange.toDate)
      .then(res => {
        const reversed = res.data.content.reverse();
        setAlertLog(reversed)
        setAlertListAll(reversed)
      }).catch(error => {
      toast.error("Something went wrong");
      console.error(error);
    }).finally(() => {
      dispatch(toggleLoader(false))
    })
  }, [dateRange])

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }


  return (
    <div>
      <div className={'sa-table-flex sa-table-col'}>
        <span className={'sa-table-position'}>
          <span className={"sa-table-float-left sa-table-filter"}>
            <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                             onChange={(e) => onCheck(e)}
                             oneTap={false} onOk={(e) => onCheck(e)}
                             showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}
                             ranges={[{
                               label: 'Today',
                               value: [new Date(), new Date()]
                             }, {
                               label: 'Yesterday',
                               value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                             }, {
                               label: 'Last 7 days',
                               value: [dateFns.subDays(new Date(), 6), new Date()]
                             }, {
                               label: 'Last 30 days',
                               value: [dateFns.subDays(new Date(), 30), new Date()]
                             }]}
            />
          </span>
          <span className={'sa-table-filter sa-filter-row'}>
            <select name="alertMedium" onChange={handleFilter}
                    className="sa-filter sa-table-h-float-l sa-table-float-left">
              <option value={""}>{filters && filters.alertMedium ? t("title.ALL") : t("title.ALERT_MEDIUM")}</option>
              <option>{t("title.SMS")}</option>
              <option>{t("title.EMAIL")}</option>
            </select>
            <select name="alertType" onChange={handleFilter}
                    className="sa-filter sa-table-h-float-l  sa-table-float-left sa-table-m-r-0">
              <option value={""}>{filters && filters.alertType ? t("title.ALL") : t("title.ALERT_TYPE")}</option>
              <option value={"TASK"}>{t("title.TASKS")}</option>
              <option value={"WEATHER_ALERT"}>{t("title.WEATHER_ALERT")}</option>
              <option value={"SCOUTING"}>{t("title.SCOUTING")}</option>
              <option value={"OFFLINE"}>{t("title.OFFLINE")}</option>
              <option value={"LIMIT_EXCEEDED"}>{t("title.LIMIT_EXCEEDED")}</option>
            </select>
          </span>
        </span>
      </div>
      <div className={'sa-table-container mt-3'}>
        <table className="table table-borderless sa-table-width">
          <thead>
          <tr>
            <th className={"sa-table-head-sticky"}>{t("title.ALERT_MEDIUM")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.ALERT_TYPE")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.DATE")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.MESSAGE")}</th>
          </tr>
          </thead>
          <tbody>
          {alertLog && alertLog.length > 0 && alertLog.map((alert, index) => (
            <tr key={index}>
              <td className={'sa-table-data'}>{alert.alertMedium}</td>
              <td className={'sa-table-data'}>{formatDisplayEnumValue(alert.alertType)}</td>
              <td className={'sa-table-data'}>{alert.creationDate}</td>
              <td className={'sa-table-data'}>{alert.message}</td>
            </tr>
          ))}
          </tbody>
        </table>
        {alertLog && alertLog.length === 0 && (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_ALERT_LOGS")}</div>
          </div>)
        }
      </div>

    </div>
  )
}
