export const modifyUrl = (url) => {
  const parsedUrl = new URL(url);

	console.log('modifying url: ' + url);
  if (parsedUrl.pathname.startsWith('/core')) {
    parsedUrl.pathname = parsedUrl.pathname.replace('/core', '/core/core');
	} else if (parsedUrl.pathname.startsWith('/oauth')) {
    parsedUrl.pathname = parsedUrl.pathname.replace('/oauth', '/ums/oauth');
  } else if (parsedUrl.pathname.startsWith('/user')) {
    parsedUrl.pathname = parsedUrl.pathname.replace('/user', '/senzagro/user');
  } else if (parsedUrl.pathname.startsWith('/portal')) {
    parsedUrl.pathname = parsedUrl.pathname.replace('/portal', '/portal/portal');
  }

  return parsedUrl.toString();
};
