import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../../shared/actions/setting";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {ExportToCsv} from "export-to-csv";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toCapsFirst} from "../../../utils/Utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const CcoReportGenerateDCReturnSummary = () => {
  let history = useHistory();
  const [returnList, setReturnList] = useState([]);
  const dispatch = useDispatch();
  const {farmId} = useParams();
  const {t, i18n} = useTranslation();
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  function exportData() {
    const data = [];
    returnList.forEach(item => {
      data.push({
        "Item ": item.product.name,
        "farmer ": item.farmer.firstName,
        "total weight ": item.totalWeight
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'DCReturn',
      useBom: true,
      noDownload: false,
      headers: ["Item", "farmer", "total weight"],
      filename: "DCReturnSummery",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);

  }

  useEffect(() => {
    if (!selectedCorporate) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/cco/user/` + getUserId() + '/corporate/' + selectedCorporate + `/goodsReturnNote/reports`)
      .then(function (response) {
        setReturnList(response.data.content);
      })
      .catch(function (error) {
        if (error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [selectedCorporate]);


  function backToReport() {
    history.push("/cco/cco-report");
  }

  return (
    <div hidden={!selectedCorporate}>
      <Container>
        <div className={"my-3 pdf-hide"}>
          <FeatherIcon icon={"arrow-left"}
                       onClick={() => backToReport()}
                       className={"sa-cursor"}/>
        </div>
        <div className="single-card m-rl-m-8 p-a-16">
          <div className="single-card-items-export-data p-0">
            <div className="sa-table-report-filter-right">
              <button disabled={returnList.length === 0}
                      onClick={exportData} className="sa-table-btn-secondary sa-table-btn-mute"><FeatherIcon
                className={"sa-table-icon-size"} icon={"upload"} onClick={exportData}/> <span
                className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span></button>
            </div>
          </div>
          <div className="sa-table-container xindicate-report-calc-height m-t-1">
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.ITEMS")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.FARMER")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.DATE")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.TOTAL_WEIGHT")}</th>
              </tr>
              </thead>
              <tbody>
              {returnList.length > 0 && returnList.map(item =>
                <tr key={item.id}>
                  <td className={'sa-table-data'}>{toCapsFirst(item.product.name)}</td>
                  <td className={'sa-table-data'}>{toCapsFirst(item.farmer.firstName)}</td>
                  <td className={'sa-table-data'}>{item.date}</td>
                  <td className={'sa-table-data'}>{item.totalWeight}</td>
                </tr>)}
              </tbody>
            </table>
            {returnList.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.DC_RETURN_SUMMARY")}</div>
              </div>)
            }
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CcoReportGenerateDCReturnSummary;
