import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {useDispatch, useSelector} from "react-redux";
import Switch from "react-switch";
import FormHandler from "../../shared/utils/FormHandler";
import {ValidateFarmAlertUser} from "../../utils/FormValidationRules";
import {toggleLoader} from "../../shared/actions/setting";
import axios from "axios";
import {toast} from "react-toastify";
import * as _ from "underscore";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";

export default function FarmAlertUser(props) {

  const {t, i18n} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [plotListLocal, setPlotListLocal] = useState([]);
  const [formErr, setFormErr] = useState(null);
  const dispatch = useDispatch();


  const {
    handleSubmit,
  } = FormHandler(alertFarmUser, ValidateFarmAlertUser);

  function alertFarmUser() {
    setIsLoading(true)
  }

  const plotList = useSelector(state => {
    return state.plotList.plotList
  });

  useEffect(() => {
    if (!props.userId || !plotList || !isModalVisible) {
      return
    }

    let getPromiseList = [];
    for (let i = 0; i < plotList.length; i++) {
      getPromiseList.push(axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/system-user/` + props.userId + `/plot/` + plotList[i].id + `/alert`));
    }

    dispatch(toggleLoader(true));
    Promise.all(getPromiseList).then(res => {
      for (let i = 0; i < res.length; i++) {
        plotList[i].alertData = res[i].data.content
      }
      setPlotListLocal(plotList);
      dispatch(toggleLoader(false));
    }).catch(error => {
      if (error.response && error.response.status === 422) {
        setFormErr(error.response.data.message);

      }
      dispatch(toggleLoader(false));

    })


  }, [props, plotList, isModalVisible])


  useEffect(() => {
    if (!isLoading) {
      return
    }
    let getPromiseList = [];
    for (let i = 0; i < plotList.length; i++) {
      const mode = {modes: _.pick(plotListLocal[i].alertData, 'EMAIL', 'SMS')};
      const alert = _.omit(plotListLocal[i].alertData, 'EMAIL', 'SMS');
      const data = _.extend(mode, alert);
      getPromiseList.push(axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/system-user/` + props.userId + `/plot/` + plotList[i].id + `/alert`, data));
    }

    dispatch(toggleLoader(true));
    Promise.all(getPromiseList).then(res => {
      toast.success("Alert Update successfully");
      setIsModalVisible(false);
      setIsLoading(false);

    }).catch(error => {
      if (error.response && error.response.status === 422) {
        setFormErr(error.response.data.message);
      }
      dispatch(toggleLoader(false));

    }).finally(() => {
      dispatch(toggleLoader(false));
    })

  }, [isLoading])

  useEffect(() => {
    if (plotListLocal.length === 0) {
      setFormErr("There are no plots, please add")
    } else {
      setFormErr(null)
    }

  }, [plotListLocal])


  function formClick(event) {
    let names = ["suggestion", "name"];
    if (!event.target.name || !names.includes(event.target.name)) {
      suggestionList.length > 0 && setSuggestionList([]);
    }
  }

  return (
    <div>
      <FeatherIcon
        onClick={() => setIsModalVisible(true)}
        icon={"bell"}
      />

      {isModalVisible && <div onClick={formClick} className="sa-popup-bg" style={{cursor: 'auto'}}>
        <div className="sa-popup">
          <div className="sa-modal-box-style">
            <div className="sa-popup-header">
              <span className={'sa-model-heading'}>{t("title.EDIT_ALERT_USER")}</span>
              <div className="sa-popup-close-icon" onClick={() => setIsModalVisible(false)}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="container">
              <form onSubmit={handleSubmit}>
                {plotListLocal.map((plot, index) => (<div key={index}>
                  {plot.alertData && <div>
                    <p className={'d-inline-block mr-3'}>{plot.name}</p>
                    <Switch
                      className="react-switch mt-1"
                      onChange={(checked) => {
                        // enableValue(checked,index)
                        plotListLocal[index].alertData['enable'] = checked
                        setPlotListLocal([...plotListLocal]);
                      }}
                      checked={plot.alertData ? plot.alertData['enable'] : false}
                      aria-labelledby="neat-label"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#D3D3D3"
                      onColor={"#23ad32"}
                      height={16}
                      width={30}
                    />
                    <div className=" sa-modal-content">
                      {plot.alertData['enable'] && <div className={'row'}>
                        <div className="col-md-4">
                          <div className="form-group m-b-16 farm-alert-box">
                            {t("title.EMAIL")}
                            <div className={'ml-auto'}>
                              <Switch
                                className="react-switch"
                                onChange={(checked) => {
                                  plotListLocal[index].alertData['modes'].EMAIL = checked;
                                  setPlotListLocal([...plotListLocal]);
                                }}
                                checked={plot.alertData ? plot.alertData['modes'].EMAIL : false}
                                aria-labelledby="neat-label"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                offColor="#D3D3D3"
                                onColor={"#23ad32"}
                                height={16}
                                width={30}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group m-b-16 farm-alert-box">
                            {t("title.SMS")}
                            <div className={'ml-auto'}>
                              <Switch
                                className="react-switch"
                                onChange={(checked) => {
                                  plotListLocal[index].alertData['modes'].SMS = checked
                                  setPlotListLocal([...plotListLocal]);
                                }}
                                checked={plot.alertData ? plot.alertData['modes'].SMS : false}
                                aria-labelledby="neat-label"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                offColor="#D3D3D3"
                                onColor={"#23ad32"}
                                height={16}
                                width={30}
                              />
                            </div>


                          </div>

                        </div>
                        <div className="col-md-4">
                          <div className="form-group m-b-16 farm-alert-box">
                            {t("title.MOBILE_PUSH_NOTIFICATION")}
                            <div className={'ml-auto'}>
                              <Switch
                                className="react-switch"
                                onChange={(checked) => {
                                  plotListLocal[index].alertData['modes'].PUSH_NOTIFICATION = checked
                                  setPlotListLocal([...plotListLocal]);
                                }}
                                checked={plot.alertData['modes'].PUSH_NOTIFICATION ? plot.alertData['modes'].PUSH_NOTIFICATION : false}
                                aria-labelledby="neat-label"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                offColor="#D3D3D3"
                                onColor={"#23ad32"}
                                height={16}
                                width={30}
                              />
                            </div>


                          </div>

                        </div>
                      </div>}
                    </div>
                    <div className="col-md-12">


                    </div>
                  </div>}
                </div>))}
                {formErr && <div className={"login-warning-msg"}>{formErr}</div>}
                <div className="sa-popup-btn">
                  <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style update-size"
                          onClick={() => {
                            setIsModalVisible(false);
                          }}>{t("button.CANCEL")}
                  </button>
                  {!formErr && <button className="sa-popup-secondary-btn-style update-size" type="submit">
                    {t("button.UPDATE")}
                  </button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>}

    </div>
  )
}
