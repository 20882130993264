import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {TOGGLE} from "../../utils/Enum";
import {useSelector} from "react-redux";
import FarmHeader from "./farm/farm-header";
import PlotList from "./farm/plot-list";
import FarmUser from "../settings/farm-user";
import FarmReport from "./farm/farm-report";
import FarmScouting from "../daily-update/farm-scouting";
import FarmIrrigationSetting from "./farm/farm-irrigation-setting";
import FarmSetting from "../settings/farm-setting";

const SmartFarmLayout = () => {
  const [showFadeEffect, setShowFadeEffect] = useState(false);

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);


  function handleScroll() {
    setShowFadeEffect(window.pageYOffset >= 5);
  }

  return (
    <div id="listView" className={"list-view " + (toggle === TOGGLE.LIST ? 'full-list-view' : 'half-list-view')}>
      <div className={toggle === TOGGLE.LIST ? "" : showFadeEffect ? "empty-scroll-list bg-img-none" : ""}/>
      <FarmHeader/>
      <div className={"half-list-container"}>
        <Switch>
          {/*<Route path="/:farmId/dashboard" component={FarmDashboard}/>*/}
          <Route path="/:farmId/plot" component={PlotList}/>
          <Route path="/:farmId/user" component={FarmUser}/>
          <Route path="/:farmId/config" component={FarmSetting}/>
          <Route path="/:farmId/report" component={FarmReport}/>
          <Route path="/:farmId/scouting" component={FarmScouting}/>
          {/*<Route path="/:farmId/settings" component={FarmSetting}/>*/}
          <Route path="/:farmId/irrigation-setting" component={FarmIrrigationSetting}/>
        </Switch>
      </div>
    </div>

  );
};

export default SmartFarmLayout;
