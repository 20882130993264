import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import Form from "react-bootstrap/Form";
import InputRange from "react-input-range";
import {useDispatch, useSelector} from "react-redux";
import * as _ from "underscore";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateIrrigationAutomation} from "../../../utils/FormValidationRules";
import axios from "axios";
import {toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {toast} from "react-toastify";
import {clusterSensorsByDeviceId, getSensorLimits} from "../../../utils/Utils";
import {getClusters} from "../../../actions/sensor";
import {getSelectedPlot} from "../../../actions/plot";
import {useTranslation} from "react-i18next";
import {dateFormat, isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const PlotIrrigationAutomation = (props) => {
  const initialRange = {min: 0, max: 100};
  const clusterMarginSquared = 0.00000000001;
  const [threshold, setThreshold] = useState([]);
  const [kit, setKit] = useState({});
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [range, setRange] = useState(initialRange);
  const [automationHistory, setAutomationHistory] = useState([]);
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date()),
  });
  const [isTable, setTable] = useState(false);
  const {t, i18n} = useTranslation();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
    setValue
  } = FormHandler(saveUpdateSchedule, validateIrrigationAutomation);

  const clusters = useSelector(state => {
    return state.sensor.clusters;
  });

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  useEffect(() => {
    if (!dateRange) {
      return
    }
    dispatch(toggleLoader(true));
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date(Date.now() - 604800000));
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/irrigation/` + props.irrigationId + '/automation/history?from=' + fromDate + '&to=' + toDate)
      .then(function (response) {
        setAutomationHistory(response.data.content);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [dateRange, props.plotId]);


  useEffect(() => {
    setThreshold(props.irrigation ? props.irrigation.automation ? props.irrigation.automation.thresholds : {} : {});
  }, [props.irrigation]);

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  useEffect(() => {
    initForm({min: 10, low: range.low ? range.low : 20, max: 30, high: range.high ? range.high : 30, inverse: false})
    setRange(initialRange)
  }, [props.isAddIrrigation]);

  useEffect(() => {
    if (!selectedPlot.kitId) {
      dispatch(getClusters([]));
      // props.hide(true);
      return;
    }

    props.hide(false);
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedPlot.kitId)
      .then(res => {
        setKit(res.data.content);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [selectedPlot]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId)
      .then(function (response) {
        dispatch(getSelectedPlot(response.data.content));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [props.plotId]);

  useEffect(() => {
    if (!kit) {
      return;
    }
    if (hasCluster()) { //new method

    } else { //old method

      dispatch(getClusters(clusterSensorsByDeviceId(kit, selectedPlot)));
    }
  }, [kit]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/irrigation/' + props.irrigationId, props.irrigation)
      .then(res => {
        // props.updateIrrigation(res.data.content);
        props.update()
        if (props.isAddIrrigation) {
          toast.success("Schedule created successfully");
        } else if (selectedIndex && !isUpdate) {
          toast.success("Schedule deleted successfully");
        } else {
          toast.success("Schedule updated successfully");
        }
        setSelectedIndex(null);
        props.hide(false);
        props.changeAdd();
        let date = dateRange;
        setDataRange(null);
        setDataRange(date);
        if (props.irrigation && props.irrigation.automation) {
          setThreshold(props.irrigation.automation.thresholds)
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          setFormErr(error.response.data.message);
        }
        if (props.irrigation && props.irrigation.automation) {
          props.irrigation.automation.thresholds = threshold;
        }
      })
      .finally(() => {
        setSelectedIndex(null);
        setIsLoading(false);
        dispatch(toggleLoader(false));
        setIsUpdate(false);
      });
  }, [isLoading]);

  function saveUpdateSchedule() {
    if (selectedIndex) {
      delete props.irrigation.automation.thresholds[selectedIndex];
    }
    if (!props.irrigation.automation) {
      props.irrigation.automation = {thresholds: {}};
    }
    console.log(values);
    console.log(kit);

    const transitionValues = {
      high: values.high,
      low: values.low
    };

    if (kit.properties[+values.number].code === "CN") {
      transitionValues.high = transitionValues.high / 1000;
      transitionValues.low = transitionValues.low / 1000;
    }

    props.irrigation.automation.thresholds[values.number] = {
      ...values,
      high: transitionValues.high,
      low: transitionValues.low
    };
    setIsLoading(true);
  }

  function hasCluster() {
    if (isEmpty(selectedPlot.propertyDetails)) {
      return false;
    }

    for (let key in selectedPlot.propertyDetails) {
      let value = selectedPlot.propertyDetails[key];
      if (!value.clusterClass) {
        return false;
      }
    }

    return true;
  }

  function clusterSensors() {
    const isNodesWithInMargin = (a, b, d) => {
      return Math.pow(a.lat - b.lat, 2) + Math.pow(a.lng - b.lng, 2) < d;
    };
    const propertiesCopy = Object.assign([], kit.properties);
    const clusteredArray = [[]];
    for (let assigned = 0; propertiesCopy.length > 0; assigned++) {
      const propertyA = propertiesCopy.shift();
      propertyA.deviceId = selectedPlot.propertyDetails[propertyA.number] ? selectedPlot.propertyDetails[propertyA.number].cluster : "";
      propertyA.clusterName = selectedPlot.propertyDetails[propertyA.number] ? selectedPlot.propertyDetails[propertyA.number].clusterName : "";

      if (!propertyA || !Object.keys(selectedPlot.propertyDetails).includes('' + propertyA.number)) {
        clusteredArray[0].push(propertyA);
        continue;
      }
      const coordinateA = selectedPlot.propertyDetails[propertyA.number].coordinate;
      if (!coordinateA) {
        clusteredArray[0].push(propertyA);
        continue;
      }
      propertyA.coordinate = coordinateA;
      if (assigned === 0) {
        clusteredArray.push([propertyA]);
        continue;
      }
      clusteredArray.forEach((p, i) => {
          if (i !== 0) {
            const coordinateB = selectedPlot.propertyDetails[p[0].number].coordinate;
            propertyA.deviceId = selectedPlot.propertyDetails[p[0].number] ? selectedPlot.propertyDetails[p[0].number].cluster : "";
            propertyA.clusterName = selectedPlot.propertyDetails[p[0].number] ? selectedPlot.propertyDetails[p[0].number].clusterName : "";
            if (isNodesWithInMargin(coordinateA, coordinateB, clusterMarginSquared)) {
              clusteredArray[i].push(propertyA);
            } else if (i + 1 === clusteredArray.length) {
              clusteredArray.push([propertyA]);
            }
          }
        }
      );
    }

    dispatch(getClusters(clusteredArray.map(cluster => _.sortBy(cluster, 'number')).splice(1)));
  }

  // function clusterSensorsByDeviceId() {
  //   const clusteredArray = [[]];
  //
  //   for (const [key, value] of Object.entries(selectedPlot.propertyDetails)) {
  //
  //     let clusterIndex = -1;
  //     for (let i = 0; i < clusteredArray.length; i++) {
  //       let cluster = clusteredArray[i];
  //
  //       if (cluster.length > 0 && cluster[0].deviceId === value.cluster) {
  //         clusterIndex = i;
  //         break;
  //       }
  //     }
  //
  //     if (kit.properties || kit.properties.length <= +key|| +key < 0) {
  //       continue;
  //     }
  //     let sensorItem = _.find(kit.properties, {number: +key});
  //     sensorItem.deviceId = value.cluster;
  //     sensorItem.clusterName = value.clusterName;
  //     if (clusterIndex === -1) {
  //       clusteredArray.push([sensorItem]);
  //     }else {
  //       clusteredArray[clusterIndex].push(sensorItem);
  //     }
  //   }
  //   dispatch(getClusters(clusteredArray.map(cluster => _.sortBy(cluster, 'number')).splice(1)));
  // }


  function handleRangeChange(value) {
    setValue({low: value.min, high: value.max});
  }

  const handleSwitch = (event) => {
    setValue({inverse: !values.inverse})
  };

  const getSensorName = (number) => {
    let result = "";
    clusters.forEach((cluster, index) => {
      cluster.forEach((sensor, indexS) => {
        if (number == sensor.number) {
          if (sensor.displayName === "Temp") {
            result = "Sensor-" + index + " : Temperature [℃]";
          } else {
            result = "Sensor-" + index + " : " + sensor.displayName;
          }
        }
      })
    });
    return result;
  };

  function deleteSchedule(index) {
    setSelectedIndex(index);
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOR_SURE_YOU_WANT_DELETE_THIS_SCHEDULE"),
      confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_SCHEDULE_FROM_AUUTOMATION")
    }));
  }

  function getRangeActive(sensor, index) {
    let range = getSensorLimits(sensor);
    setRange(range);

    let edited = {...threshold[index], number: index};

    edited.high = edited.high ? edited.high : range.defaultHigh;
    edited.low = edited.low ? edited.low : range.defaultLow;


    if (kit.properties[index].code === "CN") {
      edited.high = edited.high * 1000;
      edited.low = edited.low * 1000;
    }

    edited.number = index;

    console.log("Edited", edited);
    setValue({
      min: range.min ? range.min : 200,
      max: range.max ? range.max : 500,
      ...edited,
    });
  }

  function getRangeActiveEdit(e) {
    handleChange(e);
    const kitCode = _.findWhere(kit.properties, {number: +(e.target.value)});
    getRangeActive(kitCode.code, (e.target.value))
  }

  function getRange(sensorIndex) {
    for (let i = 0; i < clusters.length; i++) {
      for (let j = 0; j < clusters[i].length; j++) {
        if (clusters[i][j].number == sensorIndex) {
          return getSensorLimits(clusters[i][j].code)
        }
      }
    }
  }

  function getRangeValue(threshold, item) {
    if (kit.properties[+item].code === "CN") {
      return (threshold * 1000).toFixed(0);
    }

    return threshold;
  }

  const changeRange = (event) => {
    handleChange(event);
    const codeNumber = event.target.value;
    for (let i = 0; i < clusters.length; i++) {
      for (let j = 0; j < clusters[i].length; j++) {
        if (clusters[i][j].number == codeNumber) {
          getRangeActive(clusters[i][j].code, codeNumber)
        }
      }
    }
  };


  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      confirmationDialog && !confirmationDialog.isVisible && setSelectedIndex(null);
      return;
    }
    if (props.irrigation && props.irrigation.automation && props.irrigation.automation.thresholds && selectedIndex !== null) {
      delete props.irrigation.automation.thresholds[selectedIndex];
      setIsLoading(true);
    }

  }, [confirmationDialog]);

  function editSchedule(index) {
    //setValue({...threshold[index], number: index});
    setIsUpdate(true);
    setSelectedIndex(index);

    const kitCode = _.findWhere(kit.properties, {number: +index});
    getRangeActive(kitCode.code, index)
    props.hide(true);
  }


  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);

  }

  useEffect(() => {
    if (!isTable) {
      return
    }
    let elem = document.getElementById("scroll1");
    if (elem) {
      elem.scrollIntoView();
    }
  }, [isTable]);


  return (
    <div>
      {props.isAddIrrigation && (
        <div className={"threshold-card w-121"}>
          <div className={"threshold-edit-select"}>
            <select onBlur={handleOnBlur} onChange={changeRange}
                    value={values.number || ''}
                    className={`form-control ${errors.number && "warning-input"}`}
                    id="exampleFormControlSelect1" name="number">
              <option hidden value={""}>{t("title.SELECT_A_SENSOR")}</option>
              {clusters.map((cluster, index) => {
                return cluster.map((sensor, indexS) => {
                  return !props.irrigation || !props.irrigation.automation || !props.irrigation.automation.thresholds || !(Object.keys(props.irrigation.automation.thresholds)).includes(sensor.number.toString())
                    ? (
                      <option value={sensor.number} key={index + "" + indexS}>
                        {"Sensor-" + index + " : " + (sensor.displayName !== "Temp" ? sensor.displayName : "Temperature [℃]")}
                      </option>
                    )
                    : null;

                });
              })}
            </select>
            {errors.number && (
              <p className="warning-input-msg">{errors.number}</p>)}
          </div>


          <Form className={"m-t-32"}>
            <InputRange
              maxValue={range.max}
              minValue={range.min}
              step={range.step}
              value={{
                min: typeof values.low !== 'undefined' ? values.low : (range.low ? range.low : 20),
                max: values.high ? values.high : (range.high ? range.high : 30)
              }}
              onChange={value => {
                const min = Math.max(range.min, value.min); // Ensures selected min value is within range
                const max = Math.min(range.max, value.max); // Ensures selected max value is within range
                handleRangeChange({min, max})
              }}
              formatLabel={value => {
                const numericValue = Number(value);
                const roundedValue = isNaN(numericValue) ? 0 : Number(numericValue.toFixed(1));
                if (value === values.low) {
                  return (
                    <div className="input-range-label input-range-label-low">{roundedValue}</div>
                  );
                } else if (value === values.high) {
                  return (
                    <div className="input-range-label input-range-label-high">{roundedValue}</div>
                  );
                }
                return roundedValue.toString();
              }}
            />

            <div className={"text-center"}>
              <div className={"d-inline-block m-t-40 custom-switch-align"}>
                <Form.Check type="switch" id="custom-switch1" checked={!values.inverse} name="inverseFalse"
                            label="Lower bound valve status"
                            onChange={handleSwitch}/>
              </div>

              <div className={"d-inline-block m-t-40"}>
                <Form.Check type="switch" id="custom-switch2" checked={values.inverse} label="Upper bound valve status"
                            name="inverseTrue"
                            onChange={handleSwitch}/>
              </div>
            </div>

          </Form>
          {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}

          <div className={"plot-th-H"}>
            <div className={"auto-pull-right"}>
              <button type={"button"} className="btn btn-sa-text w-140p" onClick={props.changeAdd}>
                {t("button.CANCEL")}
              </button>
              <button type={"button"} className="btn btn-sa-secondary w-140p" onClick={event => handleSubmit(event)}>
                {t("button.SAVE")}
              </button>
            </div>
          </div>
        </div>
      )}

      {clusters.length > 0 && Object.keys(threshold).map((item, index) => {
          if (!isUpdate || selectedIndex !== item)
            return <div className={"threshold-card w-121"} key={index}>
              <div className={"threshold-title"}>{getSensorName(item)}</div>

              <Form className={"m-t-32"}>
                <InputRange disabled={true} maxValue={getRange(item).max} step={getRange(item).step}
                            minValue={getRange(item).min}
                            value={{
                              min: getRangeValue(threshold[item].low, item),
                              max: getRangeValue(threshold[item].high, item)
                            }}
                            onChange={value => this.setState({value})}
                            formatLabel={value => {
                              const numericValue = Number(value);
                              const roundedValue = isNaN(numericValue) ? 0 : Number(numericValue.toFixed(1));
                              if (value === values.low) {
                                return (
                                  <div className="input-range-label input-range-label-low">{roundedValue}</div>
                                );
                              } else if (value === values.high) {
                                return (
                                  <div className="input-range-label input-range-label-high">{roundedValue}</div>
                                );
                              }
                              return roundedValue.toString();
                            }}/>

                <div className={"text-center"}>
                  <div className="d-inline-block m-t-40 custom-switch-align">
                    <Form.Check type="switch" disabled id="custom-switch1" readOnly checked={!threshold[item].inverse}
                                label="Lower bound valve status"/>
                  </div>

                  <div className={"d-inline-block m-t-40"}>
                    <Form.Check type="switch" disabled id="custom-switch2" readOnly checked={threshold[item].inverse}
                                label="Upper bound valve status"/>
                  </div>
                </div>

              </Form>

              <div className={"h-16"}>
                <div hidden={props.isAddIrrigation || selectedIndex && !isUpdate} className={"auto-pull-right"}>
                  <FeatherIcon className="h-16 btn-icon" icon="edit" onClick={() => {
                    editSchedule(item)
                  }}/>
                  <FeatherIcon className="h-16 btn-icon text-red" onClick={() => {
                    deleteSchedule(item)
                  }} icon="trash-2"/>
                </div>
              </div>
            </div>;

          return <div className={"threshold-card w-121"} key={index}>
            <div className={"threshold-edit-select"}>
              <select onBlur={handleOnBlur} onChange={getRangeActiveEdit}
                      value={values.number || ''}
                      className={`form-control ${errors.number && "warning-input"}`}
                      id="exampleFormControlSelect1" name="number">
                <option hidden value={""}>{t("title.SELECT_A_SECTOR")}</option>
                {clusters.map((cluster, index) => {
                    return cluster.map((sensor, indexS) => (
                      <option value={sensor.number}
                              key={index + "" + indexS}>{"Sensor-" + index + " : " + (sensor.displayName !== "Temp" ? sensor.displayName : "Temperature [℃]")}</option>
                    ))
                  }
                )}
              </select>
              {errors.number && (
                <p className="warning-input-msg">{errors.number}</p>)}
            </div>


            <Form className={"m-t-32"}>
              <InputRange maxValue={range.max} minValue={range.min} step={range.step}
                          value={{
                            min: values.low ? values.low : 20,
                            max: values.high ? values.high : 30
                          }}
                          onChange={value => {
                            const min = Math.max(range.min, value.min); // Ensures selected min value is within range
                            const max = Math.min(range.max, value.max); // Ensures selected max value is within range
                            handleRangeChange({min, max});
                          }}
                          formatLabel={value => {
                            const numericValue = Number(value);
                            const roundedValue = isNaN(numericValue) ? 0 : Number(numericValue.toFixed(1));
                            if (value === values.low) {
                              return (
                                <div className="input-range-label input-range-label-low">{roundedValue}</div>
                              );
                            } else if (value === values.high) {
                              return (
                                <div className="input-range-label input-range-label-high">{roundedValue}</div>
                              );
                            }
                            return roundedValue.toString();
                          }}/>

              <div className={"text-center"}>
                <div className={"d-inline-block m-t-40 custom-switch-align"}>
                  <Form.Check type="switch" id="custom-switch1" checked={!values.inverse} name="inverseFalse"
                              label="Lower bound valve status"
                              onChange={handleSwitch}/>
                </div>

                <div className={"d-inline-block m-t-40"}>
                  <Form.Check type="switch" id="custom-switch2" checked={values.inverse} label="Upper bound valve status"
                              name="inverseTrue"
                              onChange={handleSwitch}/>
                </div>
              </div>

            </Form>
            {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}
            <div className="plot-th-H">
              <div className="plot-th-H auto-pull-right">
                <button type="button" className="btn btn-sa-text w-140p" onClick={event => {
                  setSelectedIndex(null);
                  setIsUpdate(false);
                  props.hide(false)
                }}>
                  {t("button.CANCEL")}
                </button>
                <button type="button" className="btn btn-sa-secondary w-140p" onClick={event => handleSubmit(event)}>
                  {t("button.UPDATE")}
                </button>
              </div>
            </div>
          </div>
        }
      )}
      {!selectedPlot.kitId && (
        <div style={{marginLeft: 140}} className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("info.KIT_NOT_CONFIGURED")}</div>
        </div>)
      }
      {selectedPlot.kitId && clusters.length === 0 && (
        <div style={{marginLeft: 100}} className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("info.NO_READING_FROM_SENZKITS_PLEASE_CONTACT_ADMIN")}</div>
        </div>)
      }
      {selectedPlot.kitId && !props.isAddIrrigation && clusters.length !== 0 && Object.keys(threshold).length === 0 && (
        <div style={{marginLeft: isTable ? 10 : 140}} className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("info.THERE_ARE_NO_SCHEDULES_PLEASE_ADD")}</div>
        </div>)
      }
      {/* <div style={{marginLeft:isTable? "20%": "40%",  width:isTable ?"80%":'120%'}}> */}
      {/*<div style={{*/}
      {/*  marginLeft: "0%",*/}
      {/*  width: Object.keys(threshold).length === 0 ? isTable ? "35%" : "70%" : isTable ? '35%' : "50%"*/}
      {/*}}>*/}
      {/*  <div style={{marginTop: "50%", marginLeft: "-175%", width: "410%"}}>*/}
      {/*    <div id={"scroll1"} className={"table-container-toggle " + (isTable ? "" : "table-toggle-hide")}>*/}
      {/*      <hr className="table-toggle-line"/>*/}
      {/*      <div className="table-toggle-heading">Automation Mode Change History</div>*/}
      {/*      <div onClick={() => setTable(!isTable)} className="table-dropdown-icon"><i*/}
      {/*        className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")}/></div>*/}
      {/*    </div>*/}
      {/*    <div className={"table-container " + (isTable ? "" : "hide-table")}>*/}
      {/*      <div className={"plot-report-filter-box"}>*/}
      {/*        <div className={"plot-report-filter"}>*/}
      {/*          <DateRangePicker disabledDate={date => date > new Date()} disabled={false} onChange={(e) => onCheck(e)}*/}
      {/*                           oneTap={false} onOk={(e) => onCheck(e)}*/}
      {/*                           showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}*/}
      {/*                           ranges={[{*/}
      {/*                             label: 'Today',*/}
      {/*                             value: [new Date(), new Date()]*/}
      {/*                           }, {*/}
      {/*                             label: 'Yesterday',*/}
      {/*                             value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]*/}
      {/*                           }, {*/}
      {/*                             label: 'Last 7 days',*/}
      {/*                             value: [dateFns.subDays(new Date(), 6), new Date()]*/}
      {/*                           }, {*/}
      {/*                             label: 'Last 30 days',*/}
      {/*                             value: [dateFns.subDays(new Date(), 30), new Date()]*/}
      {/*                           }]}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="sa-table-container">*/}
      {/*        <table className="table table-borderless sa-table-width">*/}
      {/*          <thead>*/}
      {/*          <tr>*/}
      {/*            <th className={"sa-table-head-sticky"}>{t("title.USERS")}</th>*/}
      {/*            <th className={"sa-table-head-sticky"}>{t("title.ACTION")}</th>*/}
      {/*            <th className={"sa-table-head-sticky"}>{t("title.CHANGES")}</th>*/}
      {/*            <th className={"sa-table-head-sticky"}>{t("title.DATE_AND_TIME")}</th>*/}
      {/*          </tr>*/}
      {/*          </thead>*/}
      {/*          <tbody>*/}
      {/*          {automationHistory.length > 0 && (automationHistory.map((history, index) =>*/}
      {/*            <tr key={index}>*/}
      {/*              <td className={"sa-table-data"}>{history.user ? history.user.username : 'Unknown'}</td>*/}
      {/*              <td className={"sa-table-data"}>*/}
      {/*                {toCapsFirst(history.action)}*/}
      {/*              </td>*/}
      {/*              <td className={"sa-table-data"}>{history.info}</td>*/}
      {/*              <td className={"sa-table-data"}>{history.actionAt}</td>*/}
      {/*            </tr>*/}
      {/*          ))}*/}
      {/*          </tbody>*/}
      {/*        </table>*/}
      {/*        {automationHistory.length === 0 && (*/}
      {/*          <div tyle={{marginLeft: '50%'}} className={"empty-results"}>*/}
      {/*            <FeatherIcon icon="info"/>*/}
      {/*            <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_AUTOMATION_HISTORIES")}</div>*/}
      {/*          </div>)*/}
      {/*        }*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );

};

export default PlotIrrigationAutomation;
