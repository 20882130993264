import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import 'c3/c3.css';
import {toggleLoader} from "../../../shared/actions/setting";
import {DatePicker} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'
import 'rsuite/lib/styles/index.less';
import FeatherIcon from "feather-icons-react";
import {Chart} from "react-google-charts";
import {PropagateLoader} from "react-spinners";
import {css} from "@emotion/core";
import {ExportToCsv} from "export-to-csv";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const PlotReportHandler = (props) => {

  const [selectedKit, setSelectedKit] = useState({});
  const [selectedIndexes, setSelectedIndexes] = useState([0]);
  const [isLoading, setIsLoading] = useState(false);
  const [candleData, setCandleData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const {t, i18n} = useTranslation();


  const override = css`
          display: block;
          margin: 0 auto;
          padding-bottom: 150px;   
          padding-right: 60px;
          border-color: red;
          width:fit-content;
        `;

  const style1 = {borderBottom: "solid 2.5px #2877B4"};
  const style2 = {borderBottom: "solid 2.5px #F67F0F"};

  const dispatch = useDispatch();

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  useEffect(() => {
    var date = new Date(),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    setSelectedMonth([date.getFullYear(), month].join("-"));
  }, [])

  useEffect(() => {
    if (!selectedPlot.kitId) {
      return
    }
    setIsLoading(true);
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedPlot.kitId)
      .then(function (response) {
        setSelectedKit(response.data.content);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 422) {
        }
        setIsLoading(false);
      })
      .finally(() => {
      });

  }, [selectedPlot]);

  useEffect(() => {
    if (!selectedPlot.kitId || !selectedKit.id) {
      return
    }
    setCandleData([]);
    setIsLoading(true);
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedKit.id + `/stat/` + selectedIndexes[0] + `?date=` + selectedMonth + '-01')
      .then(function (response) {
        let data = response.data.content;
        let arraySet = [];
        let downloadSet = [];
        data.map((d, index) => {
          let array = [] = [(index + 1), d.lowestValue, d.firstQuartile, d.thirdQuartile, d.highestValue];
          arraySet.push(array);

          if (d.date) {
            let arrayD = [] = [d.date, d.lowestValue, d.firstQuartile, d.thirdQuartile, d.highestValue, d.averageValue];
            downloadSet.push(arrayD);
          }

        });
        setCandleData([['Day', 'Reading', 'Open', 'Close', 'High']].concat(arraySet));
        setDownloadData([['Day', 'Lowest', 'First Quartile', 'Third Quartile', 'Highest', 'Average']].concat(downloadSet));
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        setIsLoading(false);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [selectedKit, selectedMonth, selectedIndexes]);

  function selectTab(number) {
    let array = [];
    array.push(number);
    setSelectedIndexes(array);
  }

  function onCheck(e) {
    var date = new Date(e),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    setSelectedMonth([date.getFullYear(), month].join("-"));
  }

  function exportData() {
    // const propertyName = _.findWhere(selectedKit.properties, { number: selectedIndexes[0] }).displayName;
    // const graphData = _.findWhere(kitGraphData, { propertyNumber: selectedIndexes[0] });
    //
    // let data = [];
    // for (let i = 0; i < graphData.valueX.length; i++) {
    //     data.push({
    //         "time": graphData.valueX[i],
    //         "value": graphData.valueY[i]
    //     })
    // }

    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      // headers: ['Date', "Average"],
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(downloadData);

  }


  const chartEvents = [
    {
      eventName: "select",
      callback({chartWrapper}) {


      }
    }
  ];

  return (
    <div className="">
      <Container>
        <div className="single-card m-rl-m-8 plot-report-container">
          {!selectedPlot.kitId &&
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.KIT_NOT_CONFIGURED")}</div>
            </div>}
          <div className={""}>
            {selectedKit.id && selectedKit.properties.map((property) => (
              <div
                style={(selectedIndexes.length >= 1 && selectedIndexes[0] === property.number) && style1 ||
                  (selectedIndexes.length >= 1 && selectedIndexes[1] === property.number) && style2
                  || null}
                key={property.number} className={"r-w-50 plot-report-tabs"}
                onClick={() => selectTab(property.number)}>
                <div
                  className="plot-report-tabs-title limit-plot-report-N plot-title-size">{property.displayName === "Temp" ? "Temperature" : property.displayName}</div>
                <div className="plot-report-tabs-value limit-plot-report-V">{property.displayValue}</div>
              </div>
            ))
            }
          </div>
          <div>
            <div hidden={selectedIndexes.length === 0 || !selectedPlot.kitId} className={"plot-report-filter-box"}>
              <div className={"plot-report-filter"}>
                <DatePicker format="YYYY-MM" disabledDate={date => date > new Date()} disabled={false}
                            onChange={(e) => onCheck(e)}
                            onOk={(e) => onCheck(e)} ranges={[]}
                            showWeekNumbers={true} appearance={"default"} placeholder={"Current Month"}

                />

              </div>

              <div className={"plot-report-filter-right"}>
                <div hidden={selectedIndexes.length !== 1}>
                  <button className="btn btn-sa-report m-b-6 w-140p"
                          onClick={() => exportData()}> {t("button.EXPORT_CSV")}
                  </button>
                </div>


              </div>
            </div>
            <div id={"scroll"} style={{marginLeft: "50px"}}>
              <div hidden={!isLoading} className="container m-t-200">
                <div className="sweet-loading">
                  <PropagateLoader
                    css={override}
                    size={20}
                    color={"#11bc4d"}
                    loading={true}
                  />
                </div>
              </div>
              {candleData && candleData.length > 0 && !isLoading && <div>
                <Chart
                  width={'100%'}
                  height={350}
                  chartType="CandlestickChart"
                  loader={<div>{t("title.LOADING_CHART")}</div>}
                  data={candleData}
                  chartEvents={chartEvents}
                  options={{
                    legend: 'none',
                    bar: {groupWidth: '60%'}, // Remove space between bars.
                    candlestick: {
                      fallingColor: {strokeWidth: 0, fill: '#a52714'}, // red
                      risingColor: {strokeWidth: 0, fill: '#0f9d58', stroke: '#000000'}, // green
                    },
                    colors: ['red'],
                    hAxis: {title: "Days in Month"},
                    vAxis: {title: "Reading", viewWindowMode: 'maximized'},
                  }}
                  rootProps={{'data-testid': '1'}}
                />
              </div>}
            </div>
            <div hidden={candleData.length > 0 || isLoading} className={"empty-results"}>
              <div className={"empty-results-text"}>{t("emptyMsg.NO_DATA_AVAILABLE_PLEASE_SELECT_THE_TABS")}</div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};


export default PlotReportHandler
