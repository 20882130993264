import React, {useEffect, useState} from "react";
import FarmAlertLevelSetting from "./farm-alert-level-setting";
import FeatherIcon from "feather-icons-react";
import FormHandler from "../../shared/utils/FormHandler";
import {farmAlertvalidatepop} from "../../utils/FormValidationRules";
import {useParams} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {toggleLoader} from "../../shared/actions/setting";
import {getSelectedFarm} from "../../actions/farm";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";

function FarmAlertLevelPop(props) {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const {farmId} = useParams();
  const [isModelVisible, setIsModelVisible] = useState(false);
  const [isModelAlertVisible, setIsModelAlertVisible] = useState(false);
  const [isTable, setTable] = useState(true);
  const [suggestionList, setSuggestionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [formErr, setFormErr] = useState(null);

  const selectedFarm = useSelector((state) => {
    return state.farmList.selectedFarm;
  });
  const {values, handleChange, handleSubmit, handleOnBlur} = FormHandler(
    farmAlertPopLevel,
    farmAlertvalidatepop
  );

  function farmAlertPopLevel() {
    setIsLoading(true);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    dispatch(toggleLoader(true));
    axios
      .put(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId,
        values
      )
      .then((res) => {
        setIsModelVisible(false);
        setIsModelAlertVisible(false);
        toast.success("Level Update successfully");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setFormErr(error.response.data.message);
        }

      })
      .finally(() => {
        setUpdateData(true);
        setIsLoading(false);
        dispatch(toggleLoader(false));
      });
  }, [isLoading]);

  useEffect(() => {
    if (!updateData) {
      return;
    }

    dispatch(toggleLoader(true));

    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId
      )
      .then((res) => {
        dispatch(getSelectedFarm(res.data.content));
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setUpdateData(false);
      });
  }, [updateData]);

  function formClick(event) {
    let names = ["suggestion", "name"];
    if (!event.target.name || !names.includes(event.target.name)) {
      suggestionList.length > 0 && setSuggestionList([]);
    }
  }

  return (
    <div>
      {/*<div className={"table-container-toggle " + (isTable ? "" : "table-toggle-hide")}>*/}
      {/*    <hr className="table-toggle-line" />*/}
      {/*    <div className="table-toggle-heading">Farm Alert Level Setting</div>*/}
      {/*    <div onClick={() => setTable(!isTable)} className="table-dropdown-icon"><i*/}
      {/*        className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")} /></div>*/}
      {/*</div>*/}

      <div className="d-flex alert-level-box mb-0 bg-ededed-grey  border-bottom m-0">
        {/*<h5 className="col-md-6 farm-alert-heading plot-alert-heading">Maintenance  <span className={'hide-768'}>User</span>  Levels - {selectedFarm.maintainLevel} <FeatherIcon*/}
        {/*    className="table-action sa-cursor text-white" icon={"edit"} onClick={() => {*/}

        {/*    values.maintainLevel = selectedFarm.maintainLevel;*/}
        {/*        setIsModelVisible(true);*/}

        {/*}} /></h5>*/}
        <h5 className="text-dark col-md-6 farm-alert-heading plot-alert-heading">
          {t("title.ALERT_LEVELS")} - {selectedFarm.alertLevel}{" "}
          <FeatherIcon
            className="table-action sa-cursor text-dark"
            icon={"edit"}
            onClick={() => {
              setIsModelVisible(true);
              setIsModelAlertVisible(true);
              values.alertLevel = selectedFarm.alertLevel;
            }}
          />
        </h5>
      </div>
      <FarmAlertLevelSetting levels={selectedFarm}/>

      {isModelVisible && (
        <div onClick={formClick} className="sa-popup-bg ">
          {isModelVisible && (
            <div onClick={formClick} className="sa-popup">
              <form className={'sa-modal-box-style'} onSubmit={handleSubmit}>
                <div className="sa-modal-header">
                          <span className={'sa-model-heading'}>
                          {isModelAlertVisible ? t("title.NUMBER_OF_ALERT_LEVEL") : t("title.NUMBER_OF_MAINTENANCE_LEVEL")}
                          </span>
                  <div
                    className="sa-popup-close-icon"
                    onClick={() => {
                      setIsModelVisible(false);
                      setIsModelAlertVisible(false);
                      setFormErr(null);
                    }}>
                    <FeatherIcon
                      className={"sa-modal-close-icon"}
                      icon={"x"}
                    />
                  </div>
                </div>

                <div className="sa-popup-content form-group mt-5 mb-5">
                  <select
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={
                      isModelAlertVisible
                        ? values.alertLevel
                        : values.maintainLevel || ""
                    }
                    type="text"
                    className={`form-control`}
                    name={
                      isModelAlertVisible
                        ? "alertLevel"
                        : "maintainLevel"
                    }
                    placeholder="Enter Level"
                  >
                    <option value="" hidden>
                      {isModelAlertVisible
                        ? "Select the AlertLevel"
                        : "Select the MaintainLevel"}
                    </option>
                    ,
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    {/*<option value={4}>4</option>*/}
                    {/*<option value={5}>5</option>*/}
                  </select>
                  {formErr && (
                    <div className={"login-warning-msg mt-2"}>
                      {formErr}
                    </div>
                  )}
                </div>

                <div className="sa-popup-btn">
                  <button
                    type={"button"}
                    className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                    onClick={() => {
                      setIsModelVisible(false);
                      setFormErr(null);
                    }}
                  >
                    {t("button.CANCEL")}
                  </button>

                  <button
                    className="sa-popup-secondary-btn-style"
                    type="submit"
                  >
                    {t("button.SAVE")}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FarmAlertLevelPop;
