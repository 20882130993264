export const getPlotList = (plotList) => {
  return {
    type: "PLOT_LIST",
    payload: plotList
  };
};
export const getSelectedPlot = (plot) => {
  return {
    type: "SELECTED_PLOT",
    payload: plot
  };
};

export const getSelectedKit = (kit) => {
  return {
    type: "SELECTED_KIT",
    payload: kit
  };
};


export const getBottomReached = (bottom) => {

  return {
    type: "BOTTOM_REACHED",
    payload: bottom
  };
};
export const getMQTTStatusPlot = (mqtt) => {

  return {
    type: "PLOT_MQTT_STATUS",
    payload: mqtt
  };
};

export const getPlotIrrigationStatus = (payload) => {

  return {
    type: "PLOT_IRRIGATION_STATUS",
    payload: payload
  };
};
