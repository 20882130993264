import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../../shared/actions/setting";
import {formatDisplayEnumValue} from "../../../utils/Utils";
import axios from "axios";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {DateRangePicker} from "rsuite";
import {dateFormat} from "../../../shared/utils/utils";
import * as dateFns from "date-fns";

export default function FarmAlertHistory(props) {
  const dispatch = useDispatch();
  const {farmId} = useParams();
  const [isAlertHistory, setIsAlertHistory] = useState([]);
  const {t, i18n} = useTranslation();
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date())
  });

  useEffect(() => {
    dispatch(toggleLoader(true));
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date(Date.now() - 604800000));
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());

    axios.get(`${process.env.REACT_APP_HOST}/user/${getUserId()}/farm/${farmId}/notification/size/${100}/index/0?from=${fromDate}&to=${toDate}`)
      .then(res => {
        setIsAlertHistory(res.data.content)
      }).catch((error) => {

    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [dateRange]);

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }

  return (

    <div className="sa-table-container">
      <div className={"plot-report-filter"}>
                            <span className={"sa-table-daterange-hide sa-table-float-left mt-3"}>
                                <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                                                 onChange={(e) => onCheck(e)}
                                                 oneTap={false} onOk={(e) => onCheck(e)}
                                                 showWeekNumbers={true} appearance={"default"}
                                                 placeholder={t("title.LAST_WEEK")}
                                                 ranges={[{
                                                   label: 'Today',
                                                   value: [new Date(), new Date()]
                                                 }, {
                                                   label: 'Yesterday',
                                                   value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                                                 }, {
                                                   label: 'Last 7 days',
                                                   value: [dateFns.subDays(new Date(), 6), new Date()]
                                                 }, {
                                                   label: 'Last 30 days',
                                                   value: [dateFns.subDays(new Date(), 30), new Date()]
                                                 }]}
                                />
                            </span></div>
      <table className="table table-borderless sa-table-width">
        <thead>
        <tr>
          <th className={"sa-table-head-sticky"}>{t("title.NOTIFICATION")}</th>
          <th className={"sa-table-head-sticky"}>{t("title.TYPE")}</th>
          <th className={"sa-table-head-sticky"}>{t("title.FARM_NAME")}</th>
          <th className={"sa-table-head-sticky"}>{t("title.DATE")}</th>
        </tr>
        </thead>
        <tbody>
        {isAlertHistory && isAlertHistory.map((data, index) => (
          <tr key={index}>
            <td className={'sa-table-data'}>{data.message}</td>
            <td className={'sa-table-data'}>{formatDisplayEnumValue(data.type)}</td>
            <td className={'sa-table-data'}>{data.farmName}</td>
            <td className={'sa-table-data'}>{data.startTime.slice(0, 10)}</td>
          </tr>
        ))}
        </tbody>
      </table>
      {isAlertHistory && isAlertHistory.length === 0 &&
        <div className={"empty-results mt-5"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_ALERT_HISTORY")}</div>
        </div>
      }
    </div>

  )
}
