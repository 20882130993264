import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FormHandler from "../utils/FormHandler";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import Dlogo from "../../images/dunlogo.png";
import {validateSignUp} from "../utils/SharedFormValidationRules";
import Logo from "../../images/logo.png";


const SignUp = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showText, setShowText] = useState(false);


  const {
    values,
    errors,
    handleChange,
    handleOnBlur,
    handleSubmit,
  } = FormHandler(signUp, validateSignUp);

  function signUp() {
    setIsLoading(true);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    const queryParams = new URLSearchParams(props.location.search);
    values.key = queryParams.get("hash");
    values.email = queryParams.get("email");

    axios.post(process.env.REACT_APP_HOST + '/user-name-password', values)
      .then(function (response) {
        setIsLoading(false);
        if (response.data.content.userRoleId === 100000) {
          props.history.push('/info/Login successful, please use your mobile to login.');
        } else {
          props.history.push('/login');
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          errors.message = error.response.data.message;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

  }, [isLoading]);


  return (
    <div className="login-background" id="login">
      <div className="login-inner">
        <div className="login-box">
          <div className="login-logo">
            {process.env.REACT_APP_DOMAIN === "DUNYA" ? (
              <img className="dunya-logo-image" src={Dlogo} alt="logo"/>
            ) : (
              <img className="login-logo-image" src={Logo} alt="logo"/>
            )}
          </div>

          <form onSubmit={handleSubmit} noValidate>
            <div className="form-group m-b-16">
              <label htmlFor="email">Username</label>
              <input type="text" value={values.username || ''} onChange={handleChange}
                     onBlur={handleOnBlur}
                     className={errors.username ? "form-control warning-input" : "form-control active-input"}
                     name="username" placeholder="Enter your username"/>
              {errors.username && (<p className="warning-input-msg">{errors.username}</p>)}
            </div>
            <div className="form-group m-b-6">
              <label htmlFor="pwd">Password</label>
              <input type={!showText ? "password" : "text"} value={values.password || ''}
                     onChange={handleChange}
                     onBlur={handleOnBlur}
                     className={errors.password ? "form-control warning-input" : "form-control"}
                     name="password" placeholder="Enter your password"/>
              <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>
              {errors.password && (<p className="warning-input-msg">{errors.password}</p>)}

            </div>
            <div className="form-group m-b-6">
              <label htmlFor="pwd">Confirm Password</label>
              <input type={!showText ? "password" : "text"} value={values.confirmPassword || ''}
                     onBlur={handleOnBlur}
                     onChange={handleChange}
                     className={errors.confirmPassword ? "form-control warning-input" : "form-control"}
                     name="confirmPassword" placeholder="Enter your password"/>
              <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>
              {errors.confirmPassword && (<p className="warning-input-msg">{errors.confirmPassword}</p>)}
              {errors.message && (<div className={"login-warning-msg"}>{errors.message}</div>)}
            </div>
            <button className="btn btn-sa-primary w-100 m-b-14">SIGN UP</button>
            <div className="login-text-btn m-b-9">
              Already have an account? <Link to={"/login"}>Sign In</Link>
            </div>
          </form>
        </div>
      </div>
    </div>);
};


export default SignUp
