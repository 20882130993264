import FeatherIcon from "feather-icons-react";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {formatDisplayValue, getIcon, isDeviceOnline} from "../../../utils/Utils";
import {toggleLoader} from "../../../shared/actions/setting";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const PlotSensorsSummary = (props) => {

  const [plotSensorsSummary, setPlotSensorSummary] = useState({});
  const [plotSensorsSummaryTmp, setPlotSensorSummaryTmp] = useState({});
  const [kitError, setKitError] = useState(null);
  const dispatch = useDispatch();
  const [kit, setKit] = useState({});
  const {t, i18n} = useTranslation();


  useEffect(() => {
    if (!props.plot.kitId) {
      return;
    }
    setKitError(null);
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + `/kit-summary`)
      .then(function (response) {
        setPlotSensorSummaryTmp(response.data.content);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          setKitError(error.response.data.message);
        }
      })
      .finally(() => {
      });

  }, []);

  useEffect(() => {
    if (!props.plot.kitId) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + props.plot.kitId).then(res => {
      setKit(res.data.content);
    }).catch(error => {
    }).finally(() => {
      dispatch(toggleLoader(false))
    })
  }, []);

  useEffect(() => {
    if (isEmpty(plotSensorsSummaryTmp) || isEmpty(kit)) {
      return;
    }

    setPlotSensorSummary(filterOnline(plotSensorsSummaryTmp, kit.interval))
  }, [plotSensorsSummaryTmp, kit]);

  function filterOnline(summary, interval) {
    Object.keys(summary).map(item => {
      if (!isDeviceOnline(summary[item].time, interval)) {
        delete summary[item];
      }
    })

    return summary;
  }

  function isOnline(summary) {
    if (isEmpty(summary))
      return false;

    return isDeviceOnline(summary[Object.keys(summary)[0]].time, kit.interval);
  }


  return (
    <div className="row p-1">
      {plotSensorsSummary && !kitError && isOnline(plotSensorsSummary) && Object.keys(plotSensorsSummary).map((item, index) => (
        <div className="col-6" key={index}>
          <div className="sensor-details">
            {getIcon(plotSensorsSummary[item].code)}
            {/*<div className="sensor-icon text-red"><FeatherIcon className={""} icon="thermometer"/></div>*/}
            <div className="sensor-data">
              <div className="sensor-value limit-sensorValue-sa"
                   title={plotSensorsSummary[item] ? formatDisplayValue(plotSensorsSummary[item].displayValue) : "NA"}>{plotSensorsSummary[item] ? formatDisplayValue(plotSensorsSummary[item].displayValue) : "NA"}</div>
              <div className="sensor-Name limit-sensorName-sa"
                   title={plotSensorsSummary[item] && plotSensorsSummary[item].displayName}>{(plotSensorsSummary[item] && plotSensorsSummary[item].displayName) === "Temp" ? "Temperature" : (plotSensorsSummary[item] && plotSensorsSummary[item].displayName)}</div>
            </div>
          </div>
        </div>
      ))}

      {props.plot.kitId && plotSensorsSummary && !kitError && !isOnline(plotSensorsSummary) && (
        <div className={"summary-offline-sensor"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("emptyMsg.DEVICE_ARE_OFFLINE")}</div>
        </div>
      )}

      {!props.plot.kitId && (
        <div className={"summary-offline-sensor"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("emptyMsg.KIT_NOT_CONFIGURED")}</div>
        </div>
      )}

      {props.plot.kitId && kitError && (
        <div className={"summary-offline-sensor"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{kitError}</div>
        </div>
      )}
    </div>

  );
};
export default PlotSensorsSummary;

