import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../shared/actions/setting";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {ExportToCsv} from "export-to-csv";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getUserId} from "../../shared/utils/SharedAuthentication";

const ReportGeneratedByStock = () => {
  let history = useHistory();
  const [stockList, setStockList] = useState([]);
  const dispatch = useDispatch();
  const {farmId} = useParams();
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  useEffect(() => {
    if (!selectedCorporate) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/cash/user/` + getUserId() + '/corporate/' + selectedCorporate + `/stock`)
      .then(function (response) {
        setStockList(response.data.content);
      })
      .catch(function (error) {
        if (error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [selectedCorporate]);

  function exportData() {
    const data = [];
    stockList.forEach(stock => {
      data.push({
        "Item ": stock.name,
        "Balance ": stock.balance + " " + (stock.unit === "None" ? '' : stock.unit)
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Stock',
      useBom: true,
      noDownload: false,
      headers: ["Item", "Balance"],
      filename: "CashFlowStock",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);

  }

  const {t, i18n} = useTranslation();

  function backToReport() {
    history.push("/xindicate/xindicate-report");

  }

  return (
    <div hidden={!selectedCorporate}>
      <Container>
        <div className={"my-3 pdf-hide"}>
          <FeatherIcon icon={"arrow-left"}
                       onClick={() => backToReport()}
                       className={"sa-cursor"}/>
        </div>
        <div className="single-card m-rl-m-8 p-a-16">
          <div className="single-card-items-export-data p-0">
            <div className="sa-table-report-filter-right">
              <button disabled={stockList.length === 0}
                      onClick={exportData} className="sa-table-btn-secondary sa-table-btn-mute"><FeatherIcon
                className={"sa-table-icon-size"} icon={"upload"} onClick={exportData}/> <span
                className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span></button>
            </div>
            {/*<div className="pull-right m-t-25">*/}
            {/*    <button disabled={stockList.length===0}*/}
            {/*            onClick={exportData}*/}
            {/*            className="btn btn-success ">*/}
            {/*        <span>Export CSV</span>*/}
            {/*    </button>*/}
            {/*</div>*/}
          </div>
          <div className="sa-table-container xindicate-report-calc-height m-t-1">
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.YIELD")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.BALANCE")}</th>
              </tr>
              </thead>
              <tbody>
              {stockList.length > 0 && stockList.map(stock =>
                <tr key={stock.id}>
                  <td className={'sa-table-data'}>{stock.name}</td>
                  <td
                    className={'sa-table-data'}>{stock.balanceTotal + " " + ((stock.unit === "None" || !stock.unit) ? '' : stock.unit)}</td>
                </tr>)}
              </tbody>
            </table>
            {stockList.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.STOCK")}</div>
              </div>)
            }
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ReportGeneratedByStock;
