import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleTimePicker} from "../shared/actions/setting";
import Timekeeper from "react-timekeeper";
import {convert12Hto24H, convert24to12, is24HRSTime} from "../utils/Utils";

const TimePicker = () => {
  const dispatch = useDispatch();
  const [time, setTime] = useState('12:34 pm');
  const timePicker = useSelector(state => {
    return state.setting.timePicker
  });

  function hideDialog() {
    document.querySelector(".react-timekeeper__tb-hour").click();
    dispatch(toggleTimePicker({isVisible: false, isDone: timePicker.isDone}));
    setTime(null);
  }

  useEffect(() => {
    if (!timePicker || !timePicker.isVisible) {
      return
    }
    if (timePicker.time) {
      let timeLocal = is24HRSTime(timePicker.time) ? convert24to12(timePicker.time) : timePicker.time;
      setTime(timeLocal);
    }
  }, [timePicker]);


  function setTimeAndHide() {
    let timeObj = {};
    timeObj[timePicker.fieldName] = is24HRSTime(time) ? time : convert12Hto24H(time);
    timePicker.isVisible = false;
    timePicker.isDone = true;
    dispatch(toggleTimePicker({...timeObj, ...timePicker}));
    document.querySelector(".react-timekeeper__tb-hour").click();
    setTime(null);
  }


  return (
    <div className={"sa-modal-bg " + (!timePicker.isVisible && 'hide')}>
      <div className="sa-modal-bg-inner">
        {time && <div className="date-picker-centered">
          <Timekeeper
            closeOnMinuteSelect={true}
            time={time}
            onChange={(newTime) => setTime(newTime.formatted12)}
            doneButton={(newTime) => (
              <div>
                <div
                  style={{textAlign: 'center', padding: '10px 0', cursor: 'pointer'}}
                  onClick={setTimeAndHide}
                >
                  Done
                </div>
                <div
                  style={{textAlign: 'center', padding: '10px 0', cursor: 'pointer'}}
                  onClick={hideDialog}
                >
                  Close
                </div>
              </div>
            )}
            switchToMinuteOnHourSelect
          />
        </div>}
      </div>
    </div>
  )
};


export default TimePicker
