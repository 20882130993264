import {combineReducers} from 'redux';
import farmListReducer from "./farm"
import plotListReducer from "./plot"
import isLoaderCountReducer from "../shared/reducers/setting"
import settingReducer from "../shared/reducers/setting"
import sensorReducer from "./sensor"
import moduleReducer from './modules'
// import {routerReducer} from 'react-router-redux';


const reducers = combineReducers({
  // routing: routerReducer,
  farmList: farmListReducer,
  selectedPlot: plotListReducer,
  bottomReached: plotListReducer,
  plotList: plotListReducer,
  plotMqtt: plotListReducer,
  sensor: sensorReducer,
  setting: settingReducer,
  isLoaderCount: isLoaderCountReducer,
  modules: moduleReducer
});

export default reducers;
