import React, {useState} from "react";
import {Container} from "react-bootstrap";
import ToggleLayout from "../../toggle-layout";
import users from "../../../images/icons/users-green.svg";
import PlotUser from "./plot-user";
import {useTranslation} from "react-i18next";

export default function PlotUserDetails() {
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(0);
  const {t, i18n} = useTranslation();
  return (
    <Container className={'pb-3'}>
      <ToggleLayout title={t("title.PLOT_USERS")} image={users} visibleToggleIndex={visibleToggleIndex} toggleIndex={1}
                    onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
        <PlotUser/>
      </ToggleLayout>
    </Container>
  )
}