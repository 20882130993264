import React from "react";
import {useParams} from "react-router-dom";
import logo from '../images/logo.png';


const Info = (props) => {

  const {message} = useParams();

  return (
    <div className="login-background" id="login">
      <div className="login-inner">
        <div className="login-box">
          <div className="login-logo">
            <img className="login-logo-image" src={logo} alt="logo"/>
          </div>

          <div className="heading m-t-32 text-center">{message}</div>
          {/*<form onSubmit={handleSubmit} noValidate>*/}
          {/*  <div className="form-group m-b-16">*/}
          {/*    <label htmlFor="email">Username</label>*/}
          {/*    <input type="text" value={values.username || ''} onChange={handleChange} onBlur={handleOnBlur}*/}
          {/*           className={errors.username ? "form-control warning-input" : "form-control"}*/}
          {/*           name="username" placeholder="Enter your username"/>*/}
          {/*    {errors.username && (*/}
          {/*      <p className="warning-input-msg">{errors.username}</p>)}*/}
          {/*  </div>*/}
          {/*  <div className="form-group m-b-6">*/}
          {/*    <label htmlFor="pwd">Password</label>*/}
          {/*    <input type={!showText ? "password" : "text"} value={values.password || ''} onChange={handleChange}*/}
          {/*           onBlur={handleOnBlur}*/}
          {/*           className={errors.password ? "form-control warning-input" : "form-control"}*/}
          {/*           name="password"*/}
          {/*           placeholder="Enter your password"/>*/}
          {/*    <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon*/}
          {/*      className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>*/}
          {/*    {errors.password && (*/}
          {/*      <p className="warning-input-msg">{errors.password}</p>)}*/}
          {/*  </div>*/}
          {/*  {errors.message && (<div className={"login-warning-msg"}>{errors.message}</div>)}*/}
          {/*  <div className="login-text-btn m-b-9">*/}
          {/*    Forgot password?<Link to={"/forgot-password"}> RESET</Link>*/}
          {/*  </div>*/}
          {/*  <button className="btn btn-sa-primary w-100 m-b-14">LOGIN</button>*/}
          {/*</form>*/}
        </div>
      </div>
    </div>);
};


export default Info
