import React from "react";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { formatDisplayEnumValue } from "../../utils/Utils";
import "react-toastify/dist/ReactToastify.css";

const SupplyCreateOrderDetail = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={"sa-popup-bg " + ((props.isInterestOrderVisible ? !props.isInterestOrderVisible : !props.isMatchOrderVisible) && 'hide')}>
        <div className="sa-popup">
          <form className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.DEALS_CONFIRMATION")}</span>
              <div className="sa-popup-close-icon" onClick={props.handleClose}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>

            <div className="order-details w-50">
              <div className="order-details-header">
                <h1>{t("title.SUPPLIER_DETAILS")}:</h1>
                <ul>
                <li>{t("title.FARM")}: {props.selectedSupplierData ? props.selectedSupplierData.name : "NA"}</li>
                <li>{t("title.LOCATION")}: {props.selectedSupplierData ? props.selectedSupplierData.location : "NA"}</li>
                </ul>

                <h1>{t("title.BUYER_DETAILS")}:</h1>
                <ul>
                  <li>{t("title.COMPANY_NAME")}: {props.buyerData ? props.buyerData.companyName : "NA"}</li>
                  <li>{t("title.CONTACT_PERSON_NAME")}: {props.buyerData ? props.buyerData.contactName : "NA"}</li>
                  <li>Location: {props.buyerData ? formatDisplayEnumValue(props.buyerData.district) : "NA"}</li>
                </ul>

                <h1>{t("title.ORDER_DETAILS")}:</h1>
                <ul>
                  <li>{t("title.PRODUCT")}: {props.selectedData.item}</li>
                  {props.isInterestOrderVisible && <li>{t("title.QUANTITY")}: {(props.selectedData.unit && props.selectedData.unit !== "None") ? props.selectedData.quantity + ` ` + props.selectedData.unit : props.selectedData.quantity}</li>}
                  {props.isMatchOrderVisible && <li>{t("title.QUANTITY")}: {(props.selectedData.unit && props.selectedData.unit !== "None") ? props.selectedData.availableStock + ` ` + props.selectedData.unit : props.selectedData.availableStock}</li>}
                  <li>{t("title.PRICE_PER_UNIT")}: Rs. {props.selectedData.price}</li>
                  {props.isInterestOrderVisible && <li>{t("title.TOTAL_PRICE")}: Rs. {props.selectedData.quantity * props.selectedData.price}</li>}
                  {props.isMatchOrderVisible && <li>{t("title.TOTAL_PRICE")}: Rs. {props.selectedData.availableStock * props.selectedData.price}</li>}
                  <li>{t("title.QUALITY_PARAMETERS")}: {props.selectedData.qualityParameters}</li>
                  <li>{t("title.EXPECTED_HARVESTING_DATE")}: {props.viewData ? props.viewData.expectedHarvestingDate : "NA"}</li>
                  {props.isInterestOrderVisible && <li>{t("title.NEEDED_DEADLINE")}: {props.selectedData.interestDeadline}</li>}
                  {props.isMatchOrderVisible && <li>{t("title.NEEDED_DEADLINE")}: {props.selectedData.neededDeadline}</li>}     
                  <li>{t("title.DELIVERY_MODE")}: {formatDisplayEnumValue(props.selectedData.deliveryPreferences)}</li>
                </ul>
              </div>
            </div>
            <div className="sa-popup-btn d-flex ml-3 justify-content-end">
              <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      style={{border: '1px solid #aaa', marginRight: '10px'}}
                      onClick={props.handleClose}>{t("button.CANCEL")}
              </button>
              <button
               className={`sa-popup-secondary-btn-style`}
               type="button"
               onClick={() => {
                {props.isInterestOrderVisible ? props.handleCreateOrder(props.selectedData.id) : props.handleCreateOrder(props.selectedData)}
                props.handleClose();
               }}
               >{`CONFIRM`}</button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default SupplyCreateOrderDetail;