import React from "react"
import Container from "react-bootstrap/Container";
import {useSelector} from "react-redux";

function Dashboard() {
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  return (
    <div hidden={!selectedCorporate}>
      <Container>
        <div className="single-card m-rl-m-8  p-a-16">
          <div className={'sa-table-flex sa-table-position'} style={{marginBottom: 8}}>
            <div>dashboard</div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Dashboard