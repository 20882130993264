import React from "react";
import {useTranslation} from "react-i18next";
import FeatherIcon from "feather-icons-react";


export default function IssueNoteView(props) {
  const {t, i18n} = useTranslation();
  const issueNoteItems = props.data.issueNoteItems


  return (
    <div className={"single-card p-a-16"}>

      <div className={"sa-model-heading p-b-16"} style={{textAlign: "center"}}>
        {t("title.GOOD_ISSUE_NOTE")}
      </div>
      <div style={{display: "flex"}}>
        <div className={" col-md-6 gap-mb-24"}>
          <div style={{display: "flex"}} className={"view-label m-b-8"}>
            <span className={" col-md-2"}>{t("title.STO_NO")}</span>
            <span className={" col-md-4"} style={{marginLeft: "18px"}}>{props.data.date ? props.data.date : "NA"}</span>
          </div>
          <div style={{display: "flex"}} className={"view-label m-b-8"}>
            <span className={" col-md-2"}>{t("title.OBD_NO")}</span>
            <span className={" col-md-4"}
                  style={{marginLeft: "18px"}}>{"-"}</span>
          </div>
          <div style={{display: "flex"}} className={"view-label m-b-8"}>
            <span className={" col-md-2"}>{t("title.CC_CODE")}</span>
            <span className={" col-md-4"}
                  style={{marginLeft: "18px"}}>{"-"}</span>
          </div>
          {/* <div className={"col-md-auto w-150"}>
                          <div className={"view-label"}>
                              {t("title.TRUCK_NO")}
                          </div>
                          <div className={"view-value"}>
                              {props.data.truckNo ? props.data.truckNo : "NA"}
                          </div>
                      </div> */}
        </div>
        <div className={"col-md-6 gap-mb-24"}>
          <div style={{display: "flex"}} className={"view-label m-b-8"}>
            <span className={" col-md-3"}>{t("title.ORDER_DATE")}</span>
            <span className={" col-md-4"} style={{marginLeft: "18px"}}>{props.data.date ? props.data.date : "NA"}</span>
          </div>
          <div style={{display: "flex"}} className={"view-label m-b-8"}>
            <span className={" col-md-3"}>{t("title.DELIVERY_DATE")}</span>
            <span className={" col-md-4"}
                  style={{marginLeft: "18px"}}>{props.data.date ? props.data.date : "NA"}</span>
          </div>
          <div style={{display: "flex"}} className={"view-label m-b-8"}>
            <span className={" col-md-3"}>{t("title.TRUCK_NO")}</span>
            <span className={" col-md-4"}
                  style={{marginLeft: "18px"}}>{props.data.truckNo ? props.data.truckNo : "NA"}</span>
          </div>
          <div style={{display: "flex"}} className={"view-label m-b-8"}>
            <span className={" col-md-3"}>{t("title.ISSUED_BY")}</span>
            <span className={" col-md-4"}
                  style={{marginLeft: "18px"}}>{"-"}</span>
          </div>
          {/* <div className={"col-md-auto w-150"}>
                          <div className={"view-label"}>
                              {t("title.TRUCK_NO")}
                          </div>
                          <div className={"view-value"}>
                              {props.data.truckNo ? props.data.truckNo : "NA"}
                          </div>
                      </div> */}
        </div>
      </div>

      <div className="sa-table-container m-t-40">
        <table className="table table-borderless sa-table-width">
          <thead>
          <tr>
            <th className={"sa-table-head-sticky"}>{t("title.NO")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.ITEM_CODE")}</th>
            <th className={"sa-table-head-sticky"}>{t("title.DESCRIPTION")}</th>
            {/* <th className={"sa-table-head-sticky"}>{t("title.CW")}</th> */}
            <th className={"sa-table-head-sticky"}>{t("title.ORDER_QUANTITY")}</th>
            {/* <th className={"sa-table-head-sticky"}>{t("title.SHIP_QUANTITY__IN_UMO")}</th> */}
            {/* <th className={"sa-table-head-sticky"}>UOM</th> */}
            {/* <th className={"sa-table-head-sticky"}>{t("title.SHIP_QUANTITY_IN_BUM")}</th> */}
            {/* <th className={"sa-table-head-sticky"}>{t("title.BUM")}</th> */}
            {/* <th className={"sa-table-head-sticky"}>{t("title.ISSUE_QTY")}</th> */}
            {/* <th className={"sa-table-head-sticky"}>{t("title.ISSUE_UNIT")}</th> */}
            {/* <th className={"sa-table-head-sticky"}>{t("title.REASON_MVT")}</th> */}
            {/* <th className={"sa-table-head-sticky"}>CRATE TYPE</th> */}
            {/* <th className={"sa-table-head-sticky"}>NO OF CRATES</th> */}
          </tr>
          </thead>
          <tbody>
          {issueNoteItems && issueNoteItems.map((item, index) =>
            <tr key={index}>
              <td className={'sa-table-data'}>{parseInt(index + 1)}</td>
              <td className={'sa-table-data'}>{item.product ? item.product.code : ""}</td>
              <td className={'sa-table-data'}>{item.product ? item.product.name : ""}</td>
              {/* <td className={'sa-table-data'}>{item.cw}</td> */}
              <td className={'sa-table-data'}>{item.noOfCrates + " Crates"}</td>
              {/* <td className={'sa-table-data'}>{item.shipQuantityUnitOfMeasurement}</td> */}
              {/* <td className={'sa-table-data'}>{item.unitOfMeasurement}</td> */}
              {/* <td className={'sa-table-data'}>{item.shipQuantitybum}</td> */}
              {/* <td className={'sa-table-data'}>{item.bum}</td> */}
              {/* <td className={'sa-table-data'}>{item.issueQTY}</td> */}
              {/* <td className={'sa-table-data'}>{item.issueUnit}</td> */}
              {/* <td className={'sa-table-data'}>{item.reasonMVT}</td> */}
            </tr>
          )}
          </tbody>
        </table>
        {issueNoteItems.length === 0 && (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>{t("emptyMsg.THERE_NO_ISSUE_NOTE")}</div>
          </div>)
        }
      </div>

      <div className={"dotted-line-for-sign"} style={{marginTop: "80px"}}>
        {t("title.AUTHORIZED_BY")}
      </div>
    </div>
  )
}
