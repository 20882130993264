import React from "react";
import {Route, Switch} from "react-router-dom";
import CCRHeader from "./ccr-header";
import SupplyRequests from "./ccr-supply";
import DemandRequests from "./ccr-demand";
import Deals from "./ccr-deals";

const CCR = () => {
    return (
      <div className="xindicate">
          <div id="listView" className={"list-view print-visible xindicate-full-list-view"}>
            <CCRHeader/>

            <Switch>
                <Route path="/ccr/ccr-supply" component={SupplyRequests}/>
                <Route path="/ccr/ccr-demand" component={DemandRequests}/>
                <Route path="/ccr/deals" component={Deals}/>
            </Switch>
        </div>
      </div>
    );
}

export default CCR;
