import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {TOGGLE} from "../../utils/Enum";
import {useSelector} from "react-redux";
import OperationsHeader from "./operations-header";
import Trucks from "./trucks/trucks";
import DashBoard from "./dashboard";
import IssueNote from "./issue-note/issue-note";
import CcoReportType from "./report/cco-report-type";
import CcoReportGenerateTransporterPaymentSummary from "./report/cco-report-generate-transporter-payment-summary";
import CcoReportGenerateGRNSummary from "./report/cco-report-generate-grn-summary";
import CcoReportGenerateDailyPaymentSummary from "./report/cco-report-generate-daily-payment-summary";
import CcoReportGenerateDCReturnSummary from "./report/cco-report-generate-dc-return-summary";


const CCOperations = () => {
  const [showFadeEffect, setShowFadeEffect] = useState(false);

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);


  function handleScroll() {
    setShowFadeEffect(window.pageYOffset >= 5);
  }

  return (
    <div className="xindicate">
      <div id="listView"
           className={"list-view print-visible " + (toggle === TOGGLE.LIST ? 'xindicate-full-list-view' : 'xindicate-half-list-view')}>
        <div className={toggle === TOGGLE.LIST ? "" : showFadeEffect ? "empty-scroll-list" : ""}/>
        <OperationsHeader/>
        <Switch>
          <Route path="/cco/trucks" component={Trucks}/>
          <Route path="/cco/dashboard" component={DashBoard}/>
          <Route path="/cco/issue" component={IssueNote}/>
          <Route path="/cco/cco-report" component={CcoReportType}/>
          <Route path="/cco/report/transport-payment" component={CcoReportGenerateTransporterPaymentSummary}/>
          <Route path="/cco/report/grn-summary" component={CcoReportGenerateGRNSummary}/>
          <Route path="/cco/report/daily-payment" component={CcoReportGenerateDailyPaymentSummary}/>
          <Route path="/cco/report/dc-return" component={CcoReportGenerateDCReturnSummary}/>
        </Switch>
      </div>
    </div>
  );
};

export default CCOperations;
