import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {toggleLoader} from "../../../shared/actions/setting";
import {useDispatch} from "react-redux";
import FormHandler from "../../../shared/utils/FormHandler";
import {ValidateAlertUser} from "../../../utils/FormValidationRules";
import Switch from "react-switch";
import * as _ from "underscore";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";


const AlertUserSetting = (props) => {
  const {t, i18n} = useTranslation();
  const [alertInfo, setAlertInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [formErr, setFormErr] = useState(null);
  const {plotId} = useParams();
  const dispatch = useDispatch();

  const {
    values,
    setValue,
    handleSubmit,
  } = FormHandler(alertUser, ValidateAlertUser);

  function alertUser() {
    setIsLoading(true)
  }

  useEffect(() => {

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/system-user/` + props.userId + `/plot/` + plotId + `/alert`)
      .then(res => {
        setAlertInfo(res.data.content);
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [])

  useEffect(() => {
    if (!isLoading) {
      return
    }

    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/system-user/` + props.userId + `/plot/` + plotId + `/alert`, data)
      .then(res => {
        toast.success("Alert Update successfully");
        setIsModalVisible(false);
        setIsLoading(false);
      }).catch(error => {
      if (error.response && error.response.status === 422) {
        setFormErr(error.response.data.message);
      }
    })
      .finally(() => {
        dispatch(toggleLoader(false));
      })
  }, [isLoading])

  useEffect(() => {
    values.enable = !alertInfo.enable ? false : alertInfo.enable
    values.EMAIL = _.result(alertInfo.modes, 'EMAIL')
    values.SMS = _.result(alertInfo.modes, 'SMS')
  }, [alertInfo])


  function formClick(event) {
    let names = ["suggestion", "name"];
    if (!event.target.name || !names.includes(event.target.name)) {
      suggestionList.length > 0 && setSuggestionList([]);
    }
  }

  function enableValue(checked) {
    setValue({enable: checked});
  }

  function modeEmail(checked) {
    setValue({EMAIL: checked});
  }

  function modeSms(checked) {
    setValue({SMS: checked});
  }

  const mode = {modes: _.pick(values, 'EMAIL', 'SMS')};
  const alert = _.omit(values, 'EMAIL', 'SMS');
  const data = _.extend(mode, alert);


  return (
    <div>
      <FeatherIcon
        onClick={() => setIsModalVisible(true)}
        icon={"bell"}
      />


      {isModalVisible && (
        <div onClick={formClick} className="sa-popup-bg" style={{cursor: 'auto'}}>
          <div className="sa-popup">
            <div className="sa-modal-box-style">
              <div className="sa-popup-header">
                <span className={'sa-model-heading'}>{t("title.EDIT_ALERT_USER")}</span>
                <div className="sa-popup-close-icon" onClick={() => setIsModalVisible(false)}>
                  <FeatherIcon
                    className={"sa-modal-close-icon"} icon={"x"}/>
                </div>
              </div>
              <div className="container">
                <form onSubmit={handleSubmit}>
                  <div className={'farm-alert-user'}>
                    <h4 className={'mr-3 mb-0'}>{t("title.ALERT")}</h4>

                    <Switch
                      className="react-switch"
                      onChange={enableValue}
                      checked={values.enable ? values.enable : false}
                      aria-labelledby="neat-label"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#D3D3D3"
                      onColor={"#23ad32"}
                      height={16}
                      width={30}
                    />
                  </div>
                  <div className="sa-modal-content">
                    {values.enable && <div className={"row"}>
                      <div className="col-md-4">
                        <div className="form-group m-b-16 farm-alert-box">
                          {t("title.EMAIL")}
                          <div className={'ml-auto'}>
                            <Switch
                              className="react-switch"
                              onChange={modeEmail}
                              checked={values.EMAIL ? values.EMAIL : false}
                              aria-labelledby="neat-label"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              offColor="#D3D3D3"
                              onColor={"#23ad32"}
                              height={16}
                              width={30}
                            />
                          </div>

                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group m-b-16 farm-alert-box">
                          {t("title.SMS")}
                          <div className={'ml-auto'}>
                            <Switch
                              className="react-switch"
                              onChange={modeSms}
                              checked={values.SMS ? values.SMS : false}
                              aria-labelledby="neat-label"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              offColor="#D3D3D3"
                              onColor={"#23ad32"}
                              height={16}
                              width={30}
                            />
                          </div>


                        </div>

                      </div>
                    </div>}
                    <div className="col-md-12">

                      {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}

                    </div>

                  </div>
                  <div className="sa-popup-btn">
                    <button type={"button"}
                            className="update-size sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                            onClick={() => {
                              setIsModalVisible(false);
                            }}>{t("button.CANCEL")}
                    </button>
                    <button className="update-size sa-popup-secondary-btn-style" type="submit">
                      {t("button.UPDATE")}
                    </button>
                  </div>
                </form>


              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  )

}
export default AlertUserSetting;
