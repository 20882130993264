const INIT_STATE = {
  plotList: [],
  selectedPlot: {},
  selectedKit: null,
  plotIrrigationStatus: {},
  bottomReached: false,
  plotMqtt: {}
};


export default (state = INIT_STATE, action) => {

  switch (action.type) {

    case "PLOT_LIST": {
      return {
        ...state,
        plotList: action.payload
      };
    }
    case "SELECTED_PLOT": {
      return {
        ...state,
        selectedPlot: action.payload
      };
    }

    case "BOTTOM_REACHED": {
      return {
        ...state,
        bottomReached: action.payload
      };
    }

    case "PLOT_MQTT_STATUS": {
      return {
        ...state,
        plotMqtt: action.payload
      };
    }

    case "SELECTED_KIT": {
      return {
        ...state,
        selectedKit: action.payload
      };
    }

    case "PLOT_IRRIGATION_STATUS": {
      return {
        ...state,
        plotIrrigationStatus: action.payload
      };
    }
    default :
      return state;

  }
}
