import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import {hasPermission,} from "../../../utils/Authentication";
import FormHandler from "../../../shared/utils/FormHandler";
import {plotCultivationErrors} from "../../../utils/FormValidationRules";
import axios from "axios";
import * as _ from "underscore";
import {useDispatch, useSelector} from "react-redux";
import {resetConfirmationDialog, toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import PlotCyclePop from "./plot-cultivation-form";
import PlotCultivationView from "./plot-cultivation-view";
import actuatorIcon from "../../../images/icons/fi-rr-smartphone.svg";
import ToggleLayout from "../../toggle-layout";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const PlotCultivation = (props) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const {plotId} = useParams();
  const [isTable, setTable] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [box, setBox] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [selectedCultivation, setSelectedCultivation] = useState(null);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cycleIndex, setCycleIndex] = useState([]);
  const [cultivationList, setCultivationList] = useState([]);
  const [cycleId, setCycleId] = useState([]);
  const [propsCycleId, setPropsCycleId] = useState([]);
  const [formErr, setFormErr] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(0);
  const [optionsArray, setOptionsArray] = useState([]);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
    deleteErrors,
  } = FormHandler(plotCultivations, plotCultivationErrors)


  function plotCultivations() {
    setIsLoading(true)
  }

  function formClick(event) {
    let names = ['suggestion', 'name'];
    if (!event.target.name || !names.includes(event.target.name)) {
      suggestionList.length > 0 && setSuggestionList([]);
    }
  }

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });

  function resetForm() {
    const values = {};
    initForm({...values});
    Object.keys(values).forEach(key => delete values[key]);
    deleteErrors(errors);
    //values.cropName = props.cropName;
    setIsUpdateAvailable(false);
    setFormErr("");
  }


  useEffect(() => {

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId + `/cultivation-cycle`)
      .then(res => {
        setCultivationList(res.data.content);

      })
      .finally(() => {
        setBox(true)
      })

  }, [box])
  //  values.cropName = (values.cropName ? values.cropName : props.cropName);


  useEffect(() => {
    if (!isLoading) {
      return
    }

    values.id === "None" && delete values.id;
    if (!isUpdateAvailable) {
      if (!values.name) {
        values.name = "None";
      }

      axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId + `/cultivation-cycle/`, values)
        .then(res => {
          setCultivationList(oldArray => [...oldArray, res.data.content]);
          toast.success(t('successMsg.CULTIVATION_ADD_SUCCESSFULLY'));
          resetForm();
          setIsVisible(false);
          setIsLoading(false)
        })

    } else {

      axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId + `/cultivation-cycle/` + cycleId, values)
        .then(res => {
          cultivationList[cycleIndex] = res.data.content;
          setCultivationList(cultivationList);
          toast.success(t("successMsg.UPDATE_SUCCESSFULLY"));
          setIsVisible(false);
          setIsLoading(false)
          resetForm();
        })
    }

  }, [isLoading]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(
      process.env.REACT_APP_HOST + `/user/` + getUserId() + `/cropAgronomy`
    )
      .then((res) => {
        let cropNames = _.pluck(res.data.content, 'cropName')
        setOptionsArray(cropNames);

      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  function onClickExpand() {
    setTable(!isTable);
    let elem = document.getElementById("scroll");
    if (elem) {
      elem.scrollIntoView();
    }
  }

  function handleDeleteTask(id) {
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CYCLE?")
      ,
      confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_CYCLE?")
    }));
    setDeleteId(id);
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId + `/cultivation-cycle/` + deleteId)
      .then(res => {
        setCultivationList(cultivationList.filter(item => item.id !== deleteId));
        toast.success(t("successMsg.CYCLE_DELETE_SUCCESSFULLY"));
        dispatch(toggleLoader(false));
      })
  }, [confirmationDialog]);

  useEffect(() => {
    if (!isTable) {
      return
    }
    let elem = document.getElementById("scroll");
    if (elem) {
      elem.scrollIntoView();
    }
  }, [isTable]);


  return (
    <Container>

      <ToggleLayout image={actuatorIcon} title={t("title.PLOT_CULTIVATION")} dropDown={false}
                    visibleToggleIndex={visibleToggleIndex} toggleIndex={1}
                    onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
        <div id={"scroll"} className={"table-container pt-0"}>
          <div className="pull-right m-t-26">
                        <span className={'sa-table-icon-flex'}>
                            <button onClick={() => {
                              values.cropName = props.cropName;
                              setIsVisible(true);
                            }} className="sa-table-btn-secondary sa-table-float-right">
                                <FeatherIcon
                                  icon={"plus"} className={"sa-table-icon-size"}/> <span
                              className={"sa-table-icon"}>{t("button.ADD")}</span>
                            </button>
                        </span>
          </div>


          <div className={'sa-table-container'}>
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.NAME")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.CROP_NAME")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.CURRENT_STAGE")}</th>
                <th className="sa-table-item-align sa-table-head-sticky"></th>

              </tr>
              </thead>
              <tbody>
              {cultivationList.map((data, index) => (
                <tr key={index}>
                  <td className={'sa-table-data'}>{data.name || "NA"}</td>
                  <td className={'sa-table-data'}>{data.cropName || "NA"}</td>
                  <td className={'sa-table-data'}>{data.currentStage ? data.currentStage.replace("_", " ") : "NA"}</td>
                  
                    <td className="sa-table-item-align">
                      <div className={'sa-table-action-flex'}>
                      {hasPermission("SETTINGS.FARM.PLOTS.PLOT_INFO.PLOT_CULTIVATION") &&
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                             title={t("title.VIEW")}>
                          <FeatherIcon
                            onClick={() => {
                              setSelectedCultivation(data)
                            }}
                            icon={"eye"}/></div>
                      }
                      {hasPermission("SETTINGS.FARM.PLOTS.PLOT_INFO.PLOT_CULTIVATION") &&
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                             onClick={() => setPropsCycleId(data.id)}
                             title={t("title.CHANGE_CYCLE")}>

                          <PlotCyclePop cycle={propsCycleId} onClose={() => setBox(!box)}/></div>
                      }
                      {hasPermission("SETTINGS.FARM.PLOTS.PLOT_INFO.PLOT_CULTIVATION", "EDIT") &&
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                             title={t("title.EDIT")}>
                          <FeatherIcon
                            onClick={() => {
                              setIsUpdateAvailable(true);
                              setIsVisible(true);
                              Object.keys(errors).forEach(k => errors[k] = "");
                              values.name = data.name;
                              values.cropName = data.cropName;
                              setCycleId(data.id);
                              setCycleIndex(index);
                              setFormErr("");
                            }} className="table-action" icon={"edit"}/></div>
                      }
                      {hasPermission("SETTINGS.FARM.PLOTS.PLOT_INFO.PLOT_CULTIVATION", "DELETE") &&
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.DELETE")}>
                          <FeatherIcon
                            onClick={() => handleDeleteTask(data.id)}
                            className="text-red" icon={"trash-2"}/>
                        </div>
                      }
                      </div>
                    </td>
                </tr>

              ))}
              </tbody>
            </table>
            {cultivationList.length === 0 &&
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.THERE_NO_CULTIVATION,PLEASE_ADD")}</div>
              </div>
            }
          </div>
        </div>
        {selectedCultivation &&
          <PlotCultivationView cycle={selectedCultivation} onClose={() => setSelectedCultivation(null)}/>
        }
      </ToggleLayout>
      {isVisible && <div onClick={formClick} className="sa-popup-bg">
        <div className="sa-popup">
          <form onSubmit={handleSubmit} noValidate className={"sa-modal-box-style"}>
            <div className="sa-popup-header">
              <span
                className={"sa-model-heading"}>{!isUpdateAvailable ? t("title.ADD_CULTIVATION") : t("title.EDIT_CULTIVATION")}</span>
              <div className="sa-popup-close-icon" onClick={() => {
                setIsVisible(false);
                resetForm();
              }}><FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label>{t("title.CROP_NAME")}</label>
                  <select
                    onBlur={handleOnBlur} onChange={handleChange}
                    value={values.cropName || ''} type="text"
                    className={`form-control px-1 ${errors.crop && "warning-input"}`}
                    name="cropName"
                    placeholder={t("placeHolder.SELECT_A_CROP")}
                  >
                    {optionsArray.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>
                  {errors.cropName && (
                    <p className="warning-input-msg ">{errors.cropName}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label>{t("title.CULTIVATION_NAME")}</label>
                  <input type="text"
                         className={`form-control ${errors.name && "warning-input"}`}
                         name="name"
                         onBlur={handleOnBlur} onChange={handleChange}
                         value={values.name || ""}
                         placeholder={t("placeHolder.ENTER_CULTIVATION_NAME")}/>
                  {errors.name && (
                    <p className="warning-input-msg ">{errors.name}</p>)}
                </div>
              </div>
            </div>
            <div className="sa-popup-btn ml-auto">
              <button id="btnCancel" type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      onClick={() => {
                        setIsVisible(false);
                        resetForm();
                      }}>{t("button.CANCEL")}
              </button>
              <button id="btnAdd" className="sa-popup-secondary-btn-style">
                {!isUpdateAvailable ? t("button.ADD") : t("button.UPDATE")}</button>
            </div>
          </form>
        </div>
      </div>}
    </Container>
  )
}
export default PlotCultivation;
