import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {toCapsFirst} from "../../../utils/Utils";
import PlotTabSettings from "./plot-tab-settings";
import Container from "react-bootstrap/Container";
import {getPlotList, getSelectedPlot} from "../../../actions/plot";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {getSelectedFarm} from "../../../actions/farm";
import {changeView, toggleLoader} from "../../../shared/actions/setting";
import {VIEW} from "../../../utils/Enum";
import FeatherIcon from "feather-icons-react";
import {getSelectedSensor} from "../../../actions/sensor";
import farmImg from '../../../images/farm.jpg'
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const PlotHeaderSettings = (props) => {
  const {plotId, farmId} = useParams();
  const dispatch = useDispatch();
  let history = useHistory();

  const plotList = useSelector(state => {
    return state.plotList.plotList
  });

  const plotIrrigationStatus = useSelector(state => {
    return state.plotList.plotIrrigationStatus
  });

  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });


  useEffect(() => {
    if (plotList.length !== 0) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/plot')
      .then(function (response) {
        dispatch(getPlotList(response.data.content));
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [plotList]);

  useEffect(() => {
    if (!isEmpty(selectedFarm)) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId)
      .then(function (response) {
        dispatch(getSelectedFarm(response.data.content));
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [selectedFarm]);

  useEffect(() => {
    dispatch(changeView(VIEW.SENSOR_LIST));
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + plotId)
      .then(function (response) {
        dispatch(getSelectedPlot(response.data.content));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    if (isEmpty(selectedPlot)) {
      return;
    }
    if (plotId !== selectedPlot.id) {
      history.push('/' + farmId + '/plot/' + selectedPlot.id + '/sensor');
    }
    dispatch(getSelectedSensor(null));
  }, [selectedPlot]);


  // const client = new Paho.Client("mqtt.senzmate.com", 8000, 'qwerty12345'+new Date().getTime());
  //
  // client.onConnectionLost = onConnectionLost;
  // client.onMessageArrived = onMessageArrived;
  //
  // useEffect(()=>{
  //   if(!client){
  //     return
  //   }
  //   client.connect({onSuccess:onConnect,mqttVersionExplicit:true,mqttVersion:3});
  // },[]);
  //
  // function onConnect() {
  //   if (client.isConnected()) {
  //     client.unsubscribe(`S2F/SA/V1/plot/#`);
  //     client.unsubscribe(`S2F/SA/V1/farm/#`);
  //     client.subscribe(`S2F/SA/V1/plot/` + plotId + "/#");
  //   }
  // }
  //
  // function onConnectionLost(responseObject) {
  //   if (responseObject.errorCode !== 0) {
  //     client.connect({onSuccess:onConnect,mqttVersionExplicit:true,mqttVersion:3});
  //   }
  // }
  //
  // function onMessageArrived(message) {
  //   if(message.payloadString){
  //         let mqttPlotIrrigation=JSON.parse(message.payloadString);
  //         dispatch(getPlotIrrigationStatus(mqttPlotIrrigation));
  //         mqttPlotIrrigation.plotId=plotId;
  //         dispatch(getMQTTStatusPlot(mqttPlotIrrigation));
  //   }
  // }

  return (
    <Container>
      <div className="single-card m-rl-m-8">
        <div className="single-card-items hide-768 bg-light-grey">
          <div className="card-img-view">
            <img className="card-img"
                 src={selectedPlot.imageThumbnail ? selectedPlot.imageThumbnail : selectedPlot.image ? selectedPlot.image : farmImg}
                 alt="farm"/>
          </div>
          <div className="card-header-text-top m-r-90">
            <div className="heading">{selectedPlot.name ? toCapsFirst(selectedPlot.name) : "NA"}</div>
            <div className="sub-text m-t-5">
              <FeatherIcon className={"map-icon"}
                           icon="map-pin"/> {selectedPlot.location ? selectedPlot.location : selectedFarm.location}
            </div>
          </div>
          {/*<div className="dis-in-bl m-t-5">*/}
          {/*  <div className="dis-in-bl">*/}
          {/*    <div className="card-content-heading dis-in-bl m-rl-8-15">Irrigation Status</div>*/}
          {/*    <div className="card-content-status text-green w-124 text-center dis-in-bl">{plotIrrigationStatus.status ? plotIrrigationStatus.status : "NA"}</div>*/}
          {/*  </div>*/}
          {/*  <div className="dis-in-bl">*/}
          {/*    <div className="card-content-heading dis-in-bl m-rl-8-15">Plot Status</div>*/}
          {/*    <div className="card-content-status text-yellow w-124 text-center dis-in-bl">NA</div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<ModulesTab farmId={farmId}/>*/}

        </div>
        <PlotTabSettings plotId={plotId} farmId={farmId}/>
      </div>
    </Container>
  )
};


export default PlotHeaderSettings
