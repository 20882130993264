import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { toggleLoader } from "../../../shared/actions/setting";
import { useDispatch } from "react-redux";

function Debugger(props){
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const [debugData ,setDebugData]=useState([])
   
    useEffect(()=>{
        dispatch(toggleLoader(true));
       
        axios.get(process.env.REACT_APP_IRRIGATION_TEST_AUTOMATION+'/device/'+props.debugData.kitId+'/actuator/'+props.debugData.actuatorNumber).then(res => {
      setDebugData(res.data)
      dispatch(toggleLoader(false));
    }
    ).catch(error => {
      console.log(error, "error")
    })
    },[props.debugData])


    return(
        <div  className={"sa-popup-bg sa-modal-actuator " + (false && 'hide')}>
        <div  className="sa-popup">

        
          <form  noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{ t("title.DEBUGGER") }</span>
              <div className="sa-popup-close-icon" onClick={()=>props.setIsDebug(false)}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content">
              
          
                <div className="sa-table-container">
                  
          <table className="table table-borderless sa-table-width">
            <thead>
            <tr>
             
              <th className={"sa-table-head-sticky"}>{t("title.TIME")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.TOPIC")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.MESSAGE")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.REMARK")}</th>

              {
                <th className="sa-table-item-align sa-table-head-sticky"></th>
              }
            </tr>
            </thead>
            <tbody>
            {debugData.length > 0 && debugData.map((actuator, index) =>
              <tr key={actuator.id}>
                
                <td className={"sa-table-data"}>{actuator.time}</td>
                <td className={"sa-table-data"}>{actuator.topic}</td>
                <td className={"sa-table-data"}>{actuator.message}</td>
                <td className={"sa-table-data"}>{actuator.remark}</td>
                {
                  <td className={"sa-table-item-align"}>
                    <div className={'sa-table-action-flex'}>
                    
                    </div>
                  </td>
                }

              </tr>)}

            </tbody>
          </table>
          {debugData.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("info.THERE_ARE_NO_LOGS")}</div>
            </div>)
          }
        </div>
                 
             
            
            </div>
          
          </form>
        </div>
      </div>
    )
}

export default Debugger