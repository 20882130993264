import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {css} from "@emotion/core";
import * as d3 from "d3";
import * as _ from "underscore";
import {ExportToCsv} from "export-to-csv";
import {toggleLoader} from "../../shared/actions/setting";
import axios from "axios";
import {DateRangePicker} from "rsuite";
import * as dateFns from "date-fns";
import FeatherIcon from "feather-icons-react";
import {useTranslation} from "react-i18next";
import FilterHandler from '../../shared/utils/FilterHandler';
import {Container} from 'react-bootstrap';
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../shared/utils/utils";


const ReportGeneratedByYield = (props) => {
  const {t, i18n} = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const [yieldReport, setYieldReport] = useState([]);
  const {farmId} = useParams();
  const [loadGraph, setLoadGraph] = useState(false);
  const [dataSet, setDataSet] = useState({});
  const [cropListAll, setCropListAll] = useState([]);
  const [cropListUnFiltered, setCropListUnFiltered] = useState([]);
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date())
  });
  const [isGraph, setIsGraph] = useState(false);
  const [cropNameList, setCropNameList] = useState([]);
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  const override = css`
      display: block;
      margin: 0 auto;
      padding-bottom: 150px;
      padding-top: 150px;
      border-color: red;
      width: fit-content;
    `;
  const {
    handleFilter,
    filteredList,
    filters
  } = FilterHandler(setFilteredList, cropListAll);

  function setFilteredList() {
    setCropListUnFiltered(filteredList);
    setYieldReport(filteredList)
  }

  async function addDataGraphDate(graphData) {
    await new Promise((resolve, reject) => {
      resolve(1);
      setDataSet(graphData)
    });
  }

  function styleGraph() {
    if (window.innerWidth < 769) {

      d3.select(".c3-axis-x-label").attr("dy", "42px");
      d3.selectAll(".tick").style("font-size", "7px");
      d3.select(".c3-axis-y-label").attr("dy", "-34px");
    } else {
      d3.select(".c3-axis-y-label").attr("dy", "-36px");
    }
    d3.selectAll(".c3-legend-item").attr("x", "400");
  }

  function drawGraph() {

    const incomeData = _.pluck(yieldReport, 'income');
    const yields = _.pluck(yieldReport, 'yield');

    const graphData = {};
    const tooltip = {
      // format: {
      //     value: function (value, ratio, id, index) {
      //         return value;
      //     }
      // },
    };
    const data = {
      xs: {
        'income': 'yields',
      },
      xFormat: '%Y-%m-%d',
      columns: [
        ['income'].concat(incomeData),
        ['yields'].concat(yields),
      ],
      type: 'bar',
    };
    const axis = {
      x: {
        show: true,
        type: "category",
        tick: {
          multiline: true,
          fit: true,
          rotate: window.innerWidth > 767 ? 0 : 9,
          culling: {
            max: window.innerWidth > 767 ? 6 : 5,
          },
          height: 100,


        },
        label: {
          text: 'Yields',
          position: 'outer-center',
        },

      },
      y: {
        label: {
          text: 'Income',
          position: 'outer-middle',

        }
      },
    };
    const zoom = {
      rescale: true

    };

    graphData['data'] = data;
    graphData['axis'] = axis;
    graphData['tooltip'] = tooltip;
    graphData['zoom'] = zoom;
    addDataGraphDate(graphData).then(() => {
      setLoadGraph(true);
    });
  }


  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }


  function exportData() {
    const data = [];
    yieldReport.forEach(yields => {
      data.push({
        "Crop Name ": yields.cropName,
        "Harvest ": yields.totalHarvest,
        "Income ": yields.totalIncome

      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'YIELD',
      useBom: true,
      noDownload: false,
      headers: ["Crop Name", "Harvest", "Income"],
      filename: "FarmerReport",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);

  }


  useEffect(() => {
    if (!selectedCorporate) {
      return
    }
    const todayDate = dateFormat(new Date());
    let fromDate = dateRange ? dateRange.fromDate : todayDate;
    let toDate = dateRange ? dateRange.toDate : todayDate;

    dispatch(toggleLoader(true))
    axios.get(process.env.REACT_APP_HOST + '/cash/user/' + getUserId() + '/corporate/' + selectedCorporate + '/yield/report?from=' + fromDate + '&to=' + toDate)
      .then(function (response) {
        setYieldReport(response.data.content);
        setLoadGraph(false);
        setCropListUnFiltered(response.data.content)
      })
      .catch(function (error) {

      })
      .finally(() => {
        dispatch(toggleLoader(false));
      })
  }, [dateRange, farmId, selectedCorporate]);

  function backToReport() {
    history.push("/xindicate/xindicate-report");

  }

  return (
    <Container hidden={!selectedCorporate}>
      <div className={"my-3 pdf-hide"}>
        <FeatherIcon icon={"arrow-left"}
                     onClick={() => backToReport()}
                     className={"sa-cursor"}/>
      </div>
      <div className="single-card m-rl-m-8 p-a-16">
        <div className={"monthly-report mb-3"}>
          <div className={' monthly-report-absolute'}>
            <span className="text-size-color-report">{t("title.REPORT_GENERATED_BY_YIELD")}</span>
          </div>

          <span className={"sa-table-float-left"}>
            <DateRangePicker onChange={(e) => onCheck(e)}
                             oneTap={false} onOk={(e) => onCheck(e)}
                             showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}
                             ranges={[{
                               label: 'Today',
                               value: [new Date(), new Date()]
                             }, {
                               label: 'Yesterday',
                               value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                             }, {
                               label: 'Last 7 days',
                               value: [dateFns.subDays(new Date(), 6), new Date()]
                             }, {
                               label: 'Last 30 days',
                               value: [dateFns.subDays(new Date(), 30), new Date()]
                             }]}
            />
            <select className="sa-filter sa-filter-report" onChange={handleFilter} name={"cropAgronomyId"}>
                                            <option
                                              value={""}>{filters && filters.cropAgronomyId ? t("title.ALL") : t("title.CROP")}</option>
              {cropNameList.map((item) => (
                <option key={item.id} value={item.id}>{item.cropName}</option>
              ))}
                                        </select>
            </span>

          <div className={'cashflow-report-flex'}>
            <div
              className={!isGraph ? "sa-table-report-filter-right" : "sa-table-report-filter-right space-between"}
              style={{maxWidth: '280px'}}>
              <button disabled={yieldReport.length === 0}
                      onClick={exportData}
                      className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right"
                      style={{marginRight: 6}}
              >
                <FeatherIcon
                  icon={"upload"} className={"sa-table-icon-size"}/> <span
                className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
              </button>
            </div>
            {/* <div>
                            <button type="submit" className="graph-report-btn pull-right m-l-0"
                                    onClick={() => setIsGraph(!isGraph)}><span><FeatherIcon className="trend-icon"
                                                                                            size="24"
                                                                                            icon={isGraph ? 'credit-card' : 'trending-up'}/></span><label
                                className="toggle-table hide-425">{isGraph ? t("title.TABLE"): t("title.GRAPH")} {t("button.VIEW")}</label>
                            </button>
                        </div> */}
          </div>

        </div>

        <div hidden={isGraph} className="sa-table-container xindicate-report-calc-height m-t-1">
          <table className="table table-borderless table-width">
            <thead>
            <tr>
              <th className={"sa-table-head-sticky"}>{t("title.YIELD_NAME")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.QUANTITY")}</th>
            </tr>
            </thead>
            <tbody>

            {yieldReport.length > 0 && yieldReport.map((data, index) => (
              <tr key={index}>
                <td className={'sa-table-data'}>{data.yieldName}</td>
                <td className={'sa-table-data'}>{data.quantity}</td>
              </tr>
            ))}

            </tbody>
          </table>
          {yieldReport.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_NO_REPORTS")}</div>
            </div>)
          }
        </div>
      </div>
    </Container>
  )
};

export default ReportGeneratedByYield;
