import roleInvertedJson from "./role/roleInvertedPermissions.json"
import {getPrivilege, getRole, isSuper} from "../shared/utils/SharedAuthentication";

const {BlobServiceClient} = require("@azure/storage-blob");
const account = "storagetestsabruntha";
const sas = "sp=racw&st=2023-04-12T03:35:36Z&se=2024-07-18T11:35:36Z&sv=2021-12-02&sr=c&sig=LYWslfoudlsDoExWQ%2Fssgybqdca0t%2FpHsfG2JGy8lvo%3D";
const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/?${sas}`);

export const containerClient = blobServiceClient.getContainerClient('images');

// export function hasSettingsPermission() {
//   return isSuper() || getRole() !== 3000 || getRole() !== 500;
// }
//
// export function hasCashFlowPermission() {
//   return isSuper() || !([3000,500,510,410,210,55,54,53,52,51].includes(getRole()));
// }

export function isKeellsAccount(accountId) {
  if (process.env.REACT_APP_DOMAIN === "KEELLS") {
    return true;
  }

  return ["5f0ed52a2d3b872199d53040", "62d7fefb6f3c941599959548"].includes(accountId);
}

// export function hasDailyUpdatePermission() {
//   return isSuper() || !([3000,500,510,410,210,55,54,53,52,51].includes(getRole()));
// }
//
// export function hasWritePermission() {
//   return getRole() !== 2000;
// }

function hasPermissionStatic(feature) {
  switch (feature) {
    case "PLOT_ALERT_ADVANCED_SETTING":
    case "FARM_ADVANCED_SETTING":
      if (getRole() < 10) {
        return true;
      }
      break;
    case "SETTINGS":
      if ([500, 510].includes(getRole())) {
        return false;
      }
      if (getRole() >= 3000 && getRole() <= 10000) {
        return true;
      }
      break;
    case "CORPORATE_CREATE":
      if (getRole() < 100) {
        return true;
      }
      break;
    case "CORPORATE_SETTING":
      if (getRole() < 100 || getRole() === 100 || getRole() === 200) {
        return true;
      }
      break;
    case "SYSTEM_SETTING":
    case "BUYER_PROFILE":
      return getRole() === 100000;
    case "CORPORATE_ACCESS":
      return getRole() === 100 || getRole() === 200 || getRole() === 300 || getRole() === 500 || getRole() === 510;
    case "VALVE_CARD":
      if (getRole() < 10) {
        return true;
      }
    case "CCO":
    case "FMS":
      return isSuper() || getRole() === 500 || getRole() === 200 || getRole() === 100 || getRole() === 10;
    case "CCR":
      return getRole() === 9;
  }
  return false;
}

function hasPermissionInverted(feature, access) {      // returns false if the feature.access is not in roleInvertedPermissions.json
  if (access && access !== "VIEW") {                  // or includes roleId. Used for disabling feature which doesn't have full access
    feature = feature + "." + access;
  }

  let featureAccess = roleInvertedJson[feature];

  return featureAccess && !featureAccess.includes(getRole());
}

function hasPermissionLimited(feature, access) {        // returns true only if the feature.access is in roleInvertedPermissions.json
  if (access && access !== "VIEW") {                   // and includes roleId. Used for disabling feature which has full access
    feature = feature + "." + access;
  }

  let featureAccess = roleInvertedJson[feature];

  return featureAccess && featureAccess.includes(getRole());
}


export function hasPermission(feature, access = "READ") {
  const privileges = getPrivilege();

  if (isSuper()) {
    return true;
  }

  if (!privileges || privileges.length === 0) {
    return false;
  }

  const [module, submodule] = feature.split(".");

  const modulePermission = privileges.find((p) => p.name.toUpperCase() === module.toUpperCase());

  if (!modulePermission || !modulePermission.child || !Array.isArray(modulePermission.child)) {
    return false;
  }

  if (submodule) {
    const submodulePermission = modulePermission.child.find((p) => p.name.toUpperCase() === submodule.toUpperCase());
    if (!submodulePermission || !submodulePermission.actions || !Array.isArray(submodulePermission.actions)) {
      return false;
    }
    const submoduleAction = submodulePermission.actions.find((action) => action.action_name.toUpperCase() === access.toUpperCase());
    return submoduleAction && submoduleAction.selected;
  }

  if (!modulePermission.actions || !Array.isArray(modulePermission.actions)) {
    return false;
  }

  const moduleAction = modulePermission.actions.find((action) => action.action_name.toUpperCase() === access.toUpperCase());
  return moduleAction && moduleAction.selected;
}