const INIT_STATE = {
  clusters: [],
  findLocation: false,
  selectedCoordinate: {},
};


export default (state = INIT_STATE, action) => {

  switch (action.type) {

    case "CLUSTER_LIST": {
      return {
        ...state,
        clusters: action.payload
      };
    }
    case "SELECTED_SENSOR": {
      return {
        ...state,
        selectedSensor: action.payload
      };
    }
    case "FIND_LOCATION": {
      return {
        ...state,
        findLocation: action.payload
      };
    }
    case "SELECTED_COORDINATES": {
      return {
        ...state,
        selectedCoordinate: action.payload
      };
    }
    default :
      return state;

  }
}
