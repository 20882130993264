import React, {useEffect, useState} from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import FeatherIcon from "feather-icons-react";
import PlotDeviceView from './plot-device-view'
import {toggleLoader} from "../../../shared/actions/setting";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

function PlotDevice() {
  const {t, i18n} = useTranslation();

  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [kit, setKit] = useState({});
  const [requestPlotKitId, setRequestPlotKitId] = useState(null);
  const dispatch = useDispatch();


  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });


  useEffect(() => {
    if (!selectedPlot.kitId) {
      setKit({});
      return;
    }

    if (!isEmpty(kit) && selectedPlot.kitId !== kit.id) {
      setKit({});
    }

    if (selectedPlot.kitId === requestPlotKitId) {
      return;
    }

    dispatch(toggleLoader(true));
    setRequestPlotKitId(selectedPlot.kitId);
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + selectedPlot.kitId)
      .then(res => {
        setKit(res.data.content);
        setDeviceList(res.data.content);

      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }
    ).finally(() => {
      dispatch(toggleLoader(false))
      setRequestPlotKitId(null);
    })
  }, [selectedPlot]);


  return (
    <div>
      <Container>
        <div className="single-card m-rl-m-8 p-a-16">
          <div className="sa-table-container">
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.DEVICE_ID")}</th>
                <th className="sa-table-item-align sa-table-head-sticky"></th>

              </tr>
              </thead>
              <tbody>

              {deviceList && deviceList.devices && deviceList.devices.map((device, index) => (
                <tr key={index}>
                  <td className={'sa-table-data'}>{device}</td>
                  <td className="sa-table-item-align">
                    <div className={'sa-table-action-flex'}>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                           title="View">
                        <FeatherIcon
                          onClick={() => {
                            setSelectedDevice(device)
                          }}
                          icon={"eye"}/></div>
                    </div>

                  </td>

                </tr>
              ))
              }
              </tbody>
            </table>
            {deviceList.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_DEVICES")}</div>
              </div>)
            }
          </div>


        </div>
        {selectedDevice &&
          <PlotDeviceView device={selectedDevice} properties={deviceList.properties}
                          onClose={() => setSelectedDevice(null)}/>
        }
      </Container>
    </div>
  )
}

export default PlotDevice
