export const getClusters = (payload) => {
  return {
    type: "CLUSTER_LIST",
    payload: payload
  };
};

export const getSelectedSensor = (payload) => {
  return {
    type: "SELECTED_SENSOR",
    payload: payload
  };
};
export const getFindLocation = (payload) => {
  return {
    type: "FIND_LOCATION",
    payload: payload
  };
};
export const getSelectedCoordinate = (payload) => {
  return {
    type: "SELECTED_COORDINATES",
    payload: payload
  };
};
