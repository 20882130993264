import {useEffect, useState} from 'react';
import * as _ from "underscore";
import {isEmpty} from "./utils";

const FilterHandler = (callback, listAll) => {
  const [filters, setFilters] = useState(null);
  const [filteredList, setFilteredList] = useState([]);

  //for ranges use - eg: 5-10
  //for objects use :  eg: user:name
  //for arrays use [certifications]

  const handleFilter = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    let filterLocal = filters;

    if (!filterLocal) {
      filterLocal = {};
    }
    if (value !== "") {
      filterLocal[key] = value;
    } else {
      delete filterLocal[key];
    }

    setFilters(e => ({...e, ...filterLocal}));
  };

  const reFilter = () => {
    let filterLocal = filters;

    if (!filterLocal) {
      filterLocal = {};
    }

    setFilters(e => ({...e, ...filterLocal}));
  };

  const setFilter = (key, value) => {
    let filterLocal = filters;

    if (!filterLocal) {
      filterLocal = {};
    }
    if (value !== "") {
      filterLocal[key] = value;
    } else {
      delete filterLocal[key];
    }

    setFilters(e => ({...e, ...filterLocal}));
  };

  useEffect(() => {
    if (!filters) {
      return;
    }

    let filtersTmp = JSON.parse(JSON.stringify(filters));
    let filtersRange = {};
    let filtersObj = {};


    for (let k in filtersTmp) {
      if (filtersTmp.hasOwnProperty(k)) {
        if (filtersTmp[k].includes("-")) {
          filtersRange[k] = filtersTmp[k];
          delete filtersTmp[k];
        }
        if (k.includes(":")) {
          filtersObj[k.split(":")[0]] = {}
          filtersObj[k.split(":")[0]][k.split(":")[1]] = filtersTmp[k];
          delete filtersTmp[k];
        }
      }
    }


    let filteredListLocal = listAll;
    for (let k in filtersTmp) {
      if (k === "certifications") {
        filteredListLocal = filteredListLocal.filter(item => {
            return item.certifications && _.pluck(item.certifications, "name").includes(filtersTmp[k])
          }
        );
        continue;
      }

      if (filtersTmp.hasOwnProperty(k)) {
        filteredListLocal = filteredListLocal.filter(item => {
            if (Array.isArray(item[k])) {
              return item[k].includes(filtersTmp[k]);
            }
            return item[k] && item[k].toLowerCase() === filtersTmp[k].toLowerCase();
          }
        );
      }
    }


    for (let k in filtersRange) {
      if (filtersRange.hasOwnProperty(k)) {
        filteredListLocal = filterRange(filteredListLocal, k, filtersRange[k]);
      }
    }

    if (!isEmpty(filtersObj)) {
      for (let k in filtersObj) {
        if (filtersObj.hasOwnProperty(k)) {

          for (let j in filtersObj[k]) {
            if (filtersObj[k].hasOwnProperty(j)) {
              filteredListLocal = _.filter(filteredListLocal, function (a) {
                if (!a[k]) {
                  return false;
                }
                return a[k][j] === filtersObj[k][j];
              });
            }
          }
        }
      }
    }


    setFilteredList(filteredListLocal);
  }, [filters]);

  useEffect(() => {
    callback();
  }, [filteredList]);

  function filterRange(data, filterKey, value) {
    let ranges = value.split("-");

    return _.filter(data, function (obj) {
      return +obj[filterKey] >= +ranges[0] && +obj[filterKey] <= +ranges[1];
    });
  }


  return {
    handleFilter,
    reFilter,
    setFilter,
    filteredList,
    filters
  }
};

export default FilterHandler;
