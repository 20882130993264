import axios from 'axios';
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {DatePicker} from 'rsuite';
import {toggleLoader} from '../../../shared/actions/setting';
import FeatherIcon from "feather-icons-react";
import {useDispatch, useSelector} from 'react-redux';
import pdfImg from '../../../images/PDF.svg'
import {useHistory} from 'react-router';
import IssueNoteView from './issue-note-view';
import {getUserId} from "../../../shared/utils/SharedAuthentication";

function IssueNote() {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const [isVisibleIssueNote, setIsVisibleIssueNote] = useState(false)
  const [selectedData, setSelectedData] = useState(null);
  const [isIssueNoteList, setIssueNoteList] = useState([]);
  const [print, setPrint] = useState(false);
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });
  const [fromDate, setFromDate] = useState();


  useEffect(() => {
    let date = new Date(),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    setFromDate([date.getFullYear(), month, day].join("-"));
  }, []);

  useEffect(() => {
    if (!fromDate || !selectedCorporate) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `cco/user/` + getUserId() + `/corporate/` + selectedCorporate + '/issueNote?filters=date EQ ' + fromDate)
      .then(res => {
        setIssueNoteList(res.data.content.content);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [fromDate, selectedCorporate]);


  function onCheck(e) {
    var date = new Date(e),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    setFromDate([date.getFullYear(), month, day].join("-"));
  }

  function onPrint() {
    setPrint(true)

    setTimeout(function () {
      window.print();
    }, 500);
  }

  function onViewClick(issueNote, index) {
    setIsVisibleIssueNote(true);
    setSelectedData(issueNote)
    history.push('/cco/issue/' + issueNote.id);
  }

  return (
    <div className={"container"} hidden={!selectedCorporate}>
      <div className={"single-card m-rl-m-8 p-a-16"} hidden={isVisibleIssueNote}>
        <div className={'sa-table-flex sa-table-col'}>
                        <span>
                            <span className={"sa-table-float-left"}>
                            <DatePicker
                              format="YYYY-MM-DD"
                              appearance={"default"}
                              placeholder={" Select Month "}
                              defaultValue={new Date()}
                              onChange={(e) => onCheck(e)}
                              onOk={(e) => onCheck(e)}
                            />
                            </span>
                        </span>
        </div>

        <div className="sa-table-container xindicate-calc-height">
          <table className="table table-borderless sa-table-width">
            <thead>
            <tr>
              <th className={"sa-table-head-sticky"}>{t("title.TRUCK_NO")}</th>
              <th className={"sa-table-head-sticky"}>{t("title.NO_OF_ITEMS")}</th>
              {/* <th className={"sa-table-head-sticky"}>{t("title.DISPATCHED_AMOUNT")}</th> */}
              <th className="sa-table-item-align sa-table-head-sticky"></th>
            </tr>
            </thead>
            <tbody>

            {isIssueNoteList.length > 0 && isIssueNoteList.map((issueNote, index) =>
              <tr key={index}>
                <td className={'sa-table-data'}>{issueNote.truckNo}</td>
                <td className={'sa-table-data'}>{issueNote.issueNoteItems.length}</td>
                {/* <td className={'sa-table-data'}>{issueNote.dispatchedAmount}</td> */}

                <td className="table-actions-box sa-table-item-align">
                  <div className={'sa-table-action-flex'}>
                    <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title="View">
                      <FeatherIcon
                        onClick={() => {
                          setIsVisibleIssueNote(true);
                          onViewClick(issueNote, index)
                        }}
                        className="table-action" icon={"eye"}/></div>
                  </div>

                </td>

              </tr>)}
            </tbody>
          </table>
          {isIssueNoteList.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_NO_ISSUE_NOTE")}</div>
            </div>)
          }

        </div>
      </div>
      {isVisibleIssueNote && selectedData && <div>
        <div className={'d-flex align-items-center pdf-hide'}>
          <div className={"mb-3 pdf-hide"}>
            <FeatherIcon icon={"arrow-left"} onClick={() => {
              setIsVisibleIssueNote(false)
              setSelectedData(null)
              history.push('/cco/issue');
            }}
                         className={"sa-cursor"}/> {selectedData.truckNo}
          </div>
          <div className={'pdf-hide pdf-download-btn ml-auto '} onClick={onPrint}>
            <img src={pdfImg} className={'mr-1'} alt="Download"/>
            {t("title.DOWNLOAD")}
          </div>
        </div>
        <IssueNoteView print={print} onPrint={onPrint} data={selectedData}/>
      </div>}
    </div>
  )
}

export default IssueNote
